import axios from 'axios';
import { toast } from 'react-toastify';
import { getToken } from './Token';
import { useStateValue } from './StateProvider';

const apiHost = 'https://api.mindfulsms.com/api';
// const apiHost = 'https://localhost:7094/api';
//console.log('apiHost: ' + apiHost);

export const instance = axios.create({ baseURL: apiHost });

//const [ { user }, dispatch ] = useStateValue();

// setup axios to inject the authorization header automatically
// if token is present in store
instance.interceptors.request.use((config) => {
	let token = getToken();

	if (token && token.trim() !== '') {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

instance.interceptors.response.use(
	async (response) => {
		return response;
	},
	async (error) => {
		let errorMessage = '';

		if (error.response) {
			const { data, status } = error.response;

			switch (status) {
				case 400:
					if (data.errors) {
						for (const key in data.errors) {
							if (data.errors[key]) {
								errorMessage = data.errors[key];
								//toast.error(errorMessage);
							}
						}
					} else {
						errorMessage = data;
						//toast.error(errorMessage);
					}
					break;
				case 401:
					errorMessage = 'Unauthorized';
					//toast.error(errorMessage);
					break;
				case 404:
					errorMessage = 'Not found';
					//toast.error(errorMessage);
					break;
				case 500:
					errorMessage = 'An unknown error has occurred';
					//toast.error(errorMessage);
					break;
				default:
					errorMessage = 'An unknown error has occurred';
					//toast.error(errorMessage);
					break;
			}
		} else {
			errorMessage = error;
			//toast.error(errorMessage);
		}

		if (errorMessage !== '' && typeof errorMessage === 'string') {
			//toast.error(errorMessage);
		}

		return Promise.reject(error);
	}
);

export const HandleAxiosException = async (err) => {
	var customErrorMessage = 'Failed while trying to process data';

	let fullErrorMessage =
		err && err.response && err.response.data && err.response.data.Message
			? err.response.data.Message
			: err.response.data && err.response.data.title ? err.response.data.title : customErrorMessage;
	console.log(fullErrorMessage);

	if (fullErrorMessage !== '' && typeof fullErrorMessage === 'string') {
	}

	var response = {};
	response['error'] = fullErrorMessage;
	return response;
};

export const UserLogin = async (login) => {
	try {
		const response = await instance
			.post(apiHost + '/account/login', login, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const GetAccount = async () => {
	const response = await instance
		.get(apiHost + '/account/details', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const RegisterUser = async (login) => {
	try {
		const response = await instance
			.post(apiHost + '/account/register', login, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const UpdateAccountSettings = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/account/update/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const UpdateAccountPersonalInfo = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/account/update/personal/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const UpdateAccountCompanyInfo = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/account/update/company/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const UpdateAccountPassword = async (passwordData) => {
	const response = await instance
		.post(apiHost + '/account/password', passwordData, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const ResetPasswordSendEmail = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/account/password/forgot', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const ResetPasswordProcess = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/account/password/reset', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//campaigns
export const GetCampaigns = async (data) => {
	const response = await instance
		.post(apiHost + '/campaigns', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetCampaign = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/campaigns/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const AddCampaign = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/campaigns/schedule', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditCampaign = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/campaigns/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const CancelCampaign = async (id) => {
	try {
		const response = await instance
			.post(apiHost + '/campaigns/cancel/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteCampaign = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/campaigns/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//autoresponders
export const GetAutoresponders = async (data) => {
	const response = await instance
		.post(apiHost + '/autoresponders', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetAutoresponder = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/autoresponders/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const AddAutoresponder = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/autoresponders/schedule', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditAutoresponder = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/autoresponders/update/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const CancelAutoresponder = async (id) => {
	try {
		const response = await instance
			.post(apiHost + '/autoresponders/cancel/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteAutoresponder = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/autoresponders/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//web forms
export const GetWebForms = async (data) => {
	const response = await instance
		.post(apiHost + '/webforms', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetWebForm = async (id) => {
	const response = await instance
		.get(apiHost + '/webforms/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const AddWebForm = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/webforms/add', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditWebForm = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/webforms/update/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteWebForm = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/webforms/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const GetWebFormCode = async (data) => {
	const response = await instance
		.post(apiHost + '/webforms/html', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

//message threads
export const GetMessageThreads = async (data) => {
	const response = await instance
		.post(apiHost + '/messagethreads', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetMessageThread = async (id) => {
	const response = await instance
		.get(apiHost + '/messagethreads/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetMessageThreadByPhoneNumberAndContact = async (phoneNumberId, contactId) => {
	const response = await instance
		.get(apiHost + '/messagethreads/' + phoneNumberId + '/' + contactId, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetThreadMessages = async (threadId) => {
	const response = await instance
		.get(apiHost + '/messagethreads/' + threadId + '/messages', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetUnreadMessages = async () => {
	const response = await instance
		.get(apiHost + '/messages/unread', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const SetThreadMessagesRead = async (threadId) => {
	const response = await instance
		.post(apiHost + '/messagethreads/' + threadId + '/messages/read', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const DeleteMessageThread = async (id) => {
	const response = await instance
		.delete(apiHost + '/messagethreads/delete/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const StartMessageThreadIfNecessary = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/messagethreads/start', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const SendUserMessage = async (message) => {
	const response = await instance
		.post(apiHost + '/chats', message, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const SendMessage = async (message) => {
	try {
		const response = await instance
			.post(apiHost + '/messages/send', message, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteMessage = async (id) => {
	const response = await instance
		.delete(apiHost + '/messages/delete/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const UploadFile = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/files/upload', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteChat = async (id) => {
	const response = await instance
		.delete(apiHost + '/chats/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const PerformCleanup = async (cleanupData) => {
	const response = await instance
		.post(apiHost + '/chats/cleanup', cleanupData, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetMediaList = async () => {
	const response = await instance
		.get(apiHost + '/files', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

//contacts
export const GetContacts = async (data) => {
	const response = await instance
		.post(apiHost + '/contacts', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetContact = async (id) => {
	try {
		const response = await instance
			.get(apiHost + '/contacts/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const AddContact = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/contacts/add', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const AddContactAndStartThread = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/contacts/add/newthread', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditContact = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/contacts/update/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteContact = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/contacts/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const ExportContacts = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/contacts/export', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//import contacts
export const DownloadExample = async () => {
	const response = await instance
		.post(apiHost + '/import/example', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const ImportUploadFile = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/import/upload', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const ImportGetMappingFields = async () => {
	try {
		const response = await instance
			.get(apiHost + '/import/fields', null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const ImportContacts = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/import/import', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//contact lists
export const GetContactLists = async (data) => {
	const response = await instance
		.post(apiHost + '/contactlists', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetContactList = async (id) => {
	const response = await instance
		.get(apiHost + '/contactlists/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const AddContactList = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/contactlists/add', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditContactList = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/contactlists/update/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteContactList = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/contactlists/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const ExportContactList = async (id) => {
	try {
		const response = await instance
			.post(apiHost + '/contactlists/export/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//custom fields
export const GetCustomFields = async (data) => {
	const response = await instance
		.post(apiHost + '/customfields', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetCustomField = async (id) => {
	const response = await instance
		.get(apiHost + '/customfields/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const AddCustomField = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/customfields/add', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditCustomField = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/customfields/update/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteCustomField = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/customfields/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//plans
export const GetBillingPlans = async () => {
	const response = await instance
		.get(apiHost + '/billing/plans', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

//billing
export const GetCreditCards = async () => {
	const response = await instance
		.get(apiHost + '/billing/cards/get', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const AddCreditCard = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/billing/card/add', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const GetDefaultCreditCard = async () => {
	try {
		const response = await instance
			.get(apiHost + '/billing/card/default/get', null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const SetDefaultCreditCard = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/billing/card/default/set', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteCreditCard = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/billing/card/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const GetPlanSubscription = async (id) => {
	try {
		const response = await instance
			.get(apiHost + '/billing/subscription/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const GetCustomerPortalSessionUrl = async () => {
	try {
		const response = await instance
			.get(apiHost + '/billing/portal/session/url', null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//keywords
export const GetKeywords = async (data) => {
	const response = await instance
		.post(apiHost + '/keywords', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetKeyword = async (id) => {
	const response = await instance
		.get(apiHost + '/keywords/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const AddKeyword = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/keywords/add', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditKeyword = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/keywords/update/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteKeyword = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/keywords/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//flexible content
export const GetCustomContentItems = async (data) => {
	const response = await instance
		.post(apiHost + '/flexiblecontent', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const GetCustomContent = async (id) => {
	const response = await instance
		.get(apiHost + '/flexiblecontent/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

export const AddCustomContent = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/flexiblecontent/add', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditCustomContent = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/flexiblecontent/update/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteCustomContent = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/flexiblecontent/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//analytics
export const GetAnalytics = async (data) => {
	const response = await instance
		.post(apiHost + '/analytics/data', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		});

	return response.data;
};

//logs
export const GetLogs = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/log', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

//administration
export const GetUsers = async (data) => {
	try {
		const response = await instance
			.post(apiHost + '/administration/users/', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const GetUser = async (id) => {
	try {
		const response = await instance
			.get(apiHost + '/administration/users/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const EditUser = async (id, data) => {
	try {
		const response = await instance
			.post(apiHost + '/administration/users/edit/' + id, data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const GetRoles = async () => {
	try {
		const response = await instance
			.get(apiHost + '/administration/roles/', null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const DeleteUser = async (id) => {
	try {
		const response = await instance
			.delete(apiHost + '/administration/users/delete/' + id, null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data;
	} catch (err) {
		return HandleAxiosException(err);
	}
};
