import { Fragment, useState, useEffect } from 'react';
import { useRef } from 'react';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import AppButton from '../../../appButton/AppButton';
import {
	GetContactLists,
	ImportGetMappingFields,
	ImportUploadFile,
	ImportContacts,
	DownloadExample
} from '../../../../Services';
import FileUploader from '../../fileUpload/fileUploader/FileUploader';
import Select from 'react-select';
import { toast } from 'react-toastify';
import './ImportDialog.css';

const ImportDialog = ({ position = '', onClose, callback }) => {
	const [ isImporting, setIsImporting ] = useState(false);
	const [ step, setStep ] = useState(1);
	const [ selectedFiles, setSelectedFiles ] = useState([]);
	const [ contactLists, setContactLists ] = useState([]);
	const [ selectedContactLists, setSelectedContactLists ] = useState([]);
	const [ sourceColumns, setSourceColumns ] = useState([]);
	const [ mappingFields, setMappingFields ] = useState([]);
	const mappingContainerRef = useRef();
	const [ matchedFields, setMatchedFields ] = useState({});
	const [ exampleUrl, setExampleUrl ] = useState('');
	const exampleLinkRef = useRef();

	useEffect(
		() => {
			if (step === 1) {
				getContactLists();
			}
		},
		[ step ]
	);

	const getContactLists = async (query) => {
		let data = { query: query };
		const response = await GetContactLists(data);

		if (response !== undefined) {
			let tempContactLists = [];
			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					tempContactLists.push({ value: response[i].id, label: response[i].name });
				}
			}
			setContactLists(tempContactLists);
		}
	};

	const getMappingFields = async (query) => {
		let data = { query: query };
		const response = await ImportGetMappingFields(data);

		if (response !== undefined) {
			setMappingFields(response);
		}
	};

	const handleSelectChange = (option, action) => {
		if (action.name === 'contactLists') {
			setSelectedContactLists(option);
		}
	};

	const onSelectFiles = (files) => {
		setSelectedFiles(files);
	};

	const handleUploadNext = async () => {
		if (!selectedFiles || selectedFiles.length === 0) {
			toast.error('File upload is required!');
			return;
		}

		if (!selectedContactLists || selectedContactLists.length === 0) {
			toast.error('Contact list is required!');
			return;
		}

		var data = new FormData();
		data.append('file', selectedFiles[0]);
		const importResponse = await ImportUploadFile(data);

		if (importResponse !== undefined) {
			setSourceColumns(importResponse);

			getMappingFields();

			setStep(2);
		}
	};

	const handleOnCancel = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleMergeBack = () => {
		setStep(1);
	};

	const handleMergeNext = () => {
		let mappingContainer = mappingContainerRef.current;

		let sourceFields = mappingContainerRef.current.querySelectorAll('.source-field');
		let mappingFields = mappingContainerRef.current.querySelectorAll('.mapping-field');

		if (sourceFields) {
			let tempMatchedFields = {};

			for (let i = 0; i < sourceFields.length; i++) {
				let sourceField = sourceFields[i];

				if (sourceField.checked) {
					let index = sourceField.dataset.index;

					//find mapping field with same index
					if (mappingFields) {
						let mappingField = document.querySelector('[data-index="MF_' + index + '"]');

						if (mappingField) {
							tempMatchedFields[sourceField.id] = mappingField.value;
						}
					}
				}
			}

			setMatchedFields(tempMatchedFields);
		}

		setStep(3);
	};

	const handleFinalBack = () => {
		setStep(2);
	};

	const handleFinalImport = async () => {
		setIsImporting(true);

		setTimeout(async function() {
			//prepare contact lists for POST action
			let tempContactLists = [];

			if (selectedContactLists) {
				for (let i = 0; i < selectedContactLists.length; i++) {
					tempContactLists.push(selectedContactLists[i].value);
				}
			}

			let data = { filename: selectedFiles[0].path, matchedFields: matchedFields, listIds: tempContactLists };
			const response = await ImportContacts(data);

			if (response && response.error) {
				toast.error(response.error);
				setIsImporting(false);

				return;
			}

			if (response !== undefined) {
				if (callback) {
					callback();
				}
			}

			setIsImporting(false);
		}, 2000);
	};

	const handleSourceFieldChange = (e) => {
		let value = e.target.value;
	};

	const handleMappingSelectChange = (e) => {
		let value = e.target.value;
	};

	const handleDownloadExample = async () => {
		const response = await DownloadExample();

		if (response !== undefined && response !== '') {
			setExampleUrl(response);

			openInNewTab(response);
			//exampleLinkRef.current.click();
		}
	};

	const openInNewTab = (url) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<ModalDialog position={position}>
			<ModalDialogContent>
				{step === 1 && (
					<Fragment>
						<h4 className="mb-3">Upload file (Step 1 of 3)</h4>
						<div className="form-inputs">
							<div className="form-group mb-3">
								<label htmlFor="phoneNumber">Upload file</label>
								<FileUploader
									onSelect={onSelectFiles}
									files={selectedFiles}
									accept={{
										'text/csv'                 : [ '.csv' ],
										'application/vnd.ms-excel' : [ '.xls', '.xlsx' ]
									}}
									maxFiles={1}
									showExample={true}
									onDownloadExample={handleDownloadExample}
								/>
							</div>
							<div className="form-group mb-3">
								<label htmlFor="lists">Add contacts to list(s)</label>
								<Select
									name="contactLists"
									id="contactLists"
									isMulti
									value={selectedContactLists}
									//disabled={isSaving}
									onChange={handleSelectChange}
									options={contactLists}
									className="react-select-component"
									classNamePrefix="react-select"
								/>
							</div>
						</div>
						<button className="d-none" />
					</Fragment>
				)}
				{step === 2 && (
					<Fragment>
						<h4 className="mb-3">Map fields (Step 2 of 3)</h4>
						<div className="form-inputs">
							<div className="form-group mb-4">
								<div>
									<span>Uploaded file: {selectedFiles[0].path}</span>
								</div>
							</div>
							<div className="form-group mb-0" ref={mappingContainerRef}>
								{sourceColumns &&
								sourceColumns.length > 0 && (
									<Fragment>
										<div className="row mb-1">
											<div className="col-6">
												<span>Source fields</span>
											</div>
											<div className="col-6">
												<span>Mapping fields</span>
											</div>
										</div>
										{sourceColumns.map((sourceItem, index) => (
											<div className="row align-items-center" key={sourceItem}>
												<div className="col-6">
													<div className="form-check py-1">
														<input
															id={sourceItem}
															className="form-check-input source-field"
															type="checkbox"
															checked={true}
															onChange={handleSourceFieldChange}
															data-index={index}
														/>
														<label className="form-check-label mb-0" htmlFor={sourceItem}>
															{sourceItem}
														</label>
													</div>
												</div>
												<div className="col-6">
													{mappingFields &&
													mappingFields.length > 0 && (
														<div className="form-check py-1 ps-0">
															<select
																className="form-select mapping-field"
																onChange={handleMappingSelectChange}
																data-source={sourceItem}
																data-index={`MF_${index}`}
																defaultValue={sourceItem}
															>
																<option value="">- Don't Map -</option>
																{mappingFields.map((mappingField) => (
																	<option
																		value={mappingField.name}
																		key={mappingField.name}
																	>
																		{mappingField.label}
																	</option>
																))}
															</select>
														</div>
													)}
												</div>
											</div>
										))}
									</Fragment>
								)}
							</div>
						</div>
					</Fragment>
				)}
				{step === 3 && (
					<Fragment>
						<h4 className="mb-3">Import contacts (Step 3 of 3)</h4>
						<div className="form-inputs">
							<span className="import-confirm-notice">
								{isImporting ? (
									"Import process is in progress... Please don't refresh the window"
								) : (
									'Please click on Import button to import contacts from selected file'
								)}
							</span>
						</div>
					</Fragment>
				)}
			</ModalDialogContent>
			<ModalDialogButtons shaded>
				{step === 1 && (
					<Fragment>
						<AppButton type="submit" children={<span>Next</span>} onClick={handleUploadNext} />
						<AppButton
							className="outline-primary"
							children={<span>Cancel</span>}
							onClick={handleOnCancel}
						/>
					</Fragment>
				)}
				{step === 2 && (
					<Fragment>
						<AppButton type="submit" children={<span>Next</span>} onClick={handleMergeNext} />
						<AppButton className="outline-primary" children={<span>Back</span>} onClick={handleMergeBack} />
					</Fragment>
				)}
				{step === 3 && (
					<Fragment>
						<AppButton
							type="submit"
							children={<span>Import</span>}
							onClick={handleFinalImport}
							disabled={isImporting}
							showSpinner={isImporting}
							spinnerText="Importing..."
						/>
						<AppButton
							className="outline-primary"
							children={<span>Back</span>}
							onClick={handleFinalBack}
							disabled={isImporting}
						/>
					</Fragment>
				)}
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default ImportDialog;
