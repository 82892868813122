import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from '../../common';
import { useNavigate } from 'react-router-dom';
import './AppLinkGoBack.css';

const AppLinkGoBack = ({ onClick, redirect, mainHeaderLink = false, mobileLink = true }) => {
	const navigate = useNavigate();
	const [ showLink, setShowLink ] = useState(false);

	useEffect(() => {
		if (mainHeaderLink) {
			setShowLink(true);
		} else {
			if (isMobile() && mobileLink) {
				setShowLink(true);
			}
		}
	}, []);

	const handleOnClick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<Fragment>
			{showLink && (
				<Link to={redirect ? redirect : '#'} className="go-back-link" title="Go Back" onClick={handleOnClick}>
					<i className="fas fa-arrow-left" />
				</Link>
			)}
		</Fragment>
	);
};

export default AppLinkGoBack;
