import './BroadcastsListItem.css';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const BroadcastsListItem = ({ item }) => {
	const location = useLocation();
	const [ broadcastId, setBroadcastId ] = useState('');

	useEffect(
		() => {
			setBroadcastId(window.location.pathname.replace('/campaigns', '').replaceAll('/', ''));
		},
		[ location ]
	);

	return (
		<div
			className={`broadcast-item-container ${broadcastId && broadcastId === item.id ? 'active' : ''}`}
			id={item.id}
		>
			<Link to={`/campaigns/${item.id}`}>
				<div className="broadcast-item-wrapper">
					<div className="broadcast-name">
						<span>{item.name}</span>
					</div>
					<div className="broadcast-status">
						<span>{item.status}</span>
					</div>
					<div className="broadcast-contacts-count">
						<span>{`${item.contactsCount
							? item.contactsCount + (item.contactsCount === 1 ? ' contact' : ' contacts')
							: 0}`}</span>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default BroadcastsListItem;
