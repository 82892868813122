import './WebFormsList.css';
import WebFormsListItem from '../webFormsListItem/WebFormsListItem';
import { Fragment } from 'react';

const WebFormsList = ({ items }) => {
	return (
		<div className="webforms-list-container">
			<Fragment>
				{items && items.length > 0 ? (
					items.map((item) => <WebFormsListItem item={item} key={item.id} />)
				) : (
					<div className="no-data">You have no web forms</div>
				)}
			</Fragment>
		</div>
	);
};

export default WebFormsList;
