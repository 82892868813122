import { Fragment, useEffect } from 'react';
import { useStateValue } from '../../StateProvider';
import { useNavigate } from 'react-router-dom';
// import LoggedInAsAdmin from '../loggedInAsAdmin/LoggedInAsAdmin';
import './Administration.css';
import AdministrationSidebar from './administrationSidebar/AdministrationSidebar';
import ScrollableContainer from '../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import MainAppContentHeader from '../mainApp/mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import Footer from '../mainApp/footer/Footer';
import ScrollableContainerContent from '../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';

const Administration = () => {
	const [ { user }, dispatch ] = useStateValue();
	const navigate = useNavigate();

	useEffect(() => {
		if (!user || !user.isAdmin) {
			navigate('/');
		}
	}, []);

	return (
		<Fragment>
			<div className="app-administration-container panel">
				{/* <LoggedInAsAdmin />
			<MainAppHeader />
			<AdministrationNav /> */}
				<ScrollableContainer className="administration-content-container">
					<ScrollableContainerHeader>
						<MainAppContentHeader title="Administration" />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<AdministrationSidebar />

						<Footer />
					</ScrollableContainerContent>
				</ScrollableContainer>
			</div>
		</Fragment>
	);
};

export default Administration;
