import './MessageThreadsList.css';
import MessageThread from '../messageThread/MessageThread';
import { Fragment } from 'react';

const MessageThreadsList = ({ threads }) => {
	return (
		<div className="message-threads-list-container">
			<Fragment>
				{threads && threads.length > 0 ? (
					threads.map((messageThread) => <MessageThread thread={messageThread} key={messageThread.id} />)
				) : (
					<div className="no-data">You have no conversations</div>
				)}
			</Fragment>
		</div>
	);
};

export default MessageThreadsList;
