import { useState, useEffect, Fragment } from 'react';
import WebFormsSidebarHeaderPlaceholder from '../../../placeholders/webformsSidebarHeaderPlaceholder/WebFormsSidebarHeaderPlaceholder';
import WebFormPlaceholder from '../../../placeholders/webFormPlaceholder/WebFormPlaceholder';
import WebFormsList from './webFormsList/WebFormsList';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import WebFormsSidebarHeader from './webFormsSidebarHeader/WebFormsSidebarHeader';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import { GetWebForms } from '../../../../Services';
import './WebFormsSidebar.css';

const WebFormsSidebar = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ webForms, setWebForms ] = useState([]);
	const [ { reloadWebForms, searchQuery }, dispatch ] = useStateValue();

	useEffect(() => {
		dispatch({
			type        : actionTypes.SEARCH_QUERY,
			searchQuery : null
		});
	}, []);

	useEffect(
		() => {
			getWebForms(searchQuery);
		},
		[ searchQuery ]
	);

	useEffect(
		() => {
			if (reloadWebForms) {
				getWebForms(searchQuery);

				dispatch({
					type           : actionTypes.RELOAD_WEBFORMS,
					reloadWebForms : false
				});
			}
		},
		[ reloadWebForms ]
	);

	const getWebForms = async (query) => {
		let data = { query: query };
		const items = await GetWebForms(data);

		if (items) {
			//sort by date desc
			items.sort((a, b) => {
				let dateA = new Date(a.dateCreated),
					dateB = new Date(b.dateCreated);
				return dateB - dateA;
			});

			setWebForms(items);
		}

		setIsLoading(false);
	};

	return (
		<ScrollableContainer className="webforms-sidebar-container">
			{isLoading ? (
				<Fragment>
					<ScrollableContainerHeader>
						<WebFormsSidebarHeaderPlaceholder />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<WebFormPlaceholder />
						<WebFormPlaceholder />
						<WebFormPlaceholder />
						<WebFormPlaceholder />
						<WebFormPlaceholder />
					</ScrollableContainerContent>
				</Fragment>
			) : (
				<Fragment>
					<ScrollableContainerHeader>
						<WebFormsSidebarHeader items={webForms} callback={getWebForms} />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<WebFormsList items={webForms} />
					</ScrollableContainerContent>
				</Fragment>
			)}
		</ScrollableContainer>
	);
};

export default WebFormsSidebar;
