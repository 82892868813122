import './FileUploader.css';
import React from 'react';
import Dropzone from 'react-dropzone';

const FileUploader = ({ onSelect, files, accept, maxFiles, showExample, onDownloadExample }) => {
	const onSelectFiles = (acceptedFiles) => {
		if (onSelect) {
			files = acceptedFiles;

			onSelect(acceptedFiles);
		}
	};

	const handleDownloadExample = async () => {
		if (onDownloadExample) {
			onDownloadExample();
		}
	};

	return (
		<div className="dropzone-uploader">
			<Dropzone onDrop={onSelectFiles} maxFiles={maxFiles} accept={accept}>
				{({ getRootProps, getInputProps }) => (
					<section>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<p className="mb-0">Drag and drop some files here, or click to select files</p>
						</div>
						{files &&
						files.length > 0 && (
							<aside>
								{files.map((file) => (
									<span className="uploaded-file" key={file.path}>
										<i className="fa-solid fa-file-circle-check me-2" />
										{file.path} ({file.size} bytes)
									</span>
								))}
							</aside>
						)}
						{showExample && (
							<div className="download-example">
								<button className="btn btn-link p-0" onClick={handleDownloadExample}>
									Download example
								</button>
							</div>
						)}
					</section>
				)}
			</Dropzone>
		</div>
	);
};

export default FileUploader;
