import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../../StateProvider';
import './Account.css';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import AccountContentArea from './accountContentArea/AccountContentArea';
import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import MainAppContentHeader from '../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import CustomFields from '../contacts/contactsContentArea/contactsContent/customFields/CustomFields';
import { GetCustomFields } from '../../../Services';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import Footer from '../footer/Footer';
import AccountIntegration from './accountContentArea/accountIntegration/AccountIntegration';

const Account = () => {
	const [ customFields, setCustomFields ] = useState([]);
	const [ selectedTab, setSelectedTab ] = useState(null);
	const [ { user, searchQuery }, dispatch ] = useStateValue();
	const location = useLocation();

	useEffect(
		() => {
			if (window.location.hash) {
				setSelectedTab(window.location.hash.replace('#', ''));
			}

			getCustomFields(searchQuery);
		},
		[ searchQuery, location ]
	);

	const getCustomFields = async (query) => {
		try {
			let data = { query: query };
			const response = await GetCustomFields(data);

			if (response) {
				//show default first
				response.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));

				setCustomFields(response);
			}
		} catch (error) {}

		//setIsLoading(false);
	};
	return (
		<Fragment>
			<div className="app-account-container panel">
				{/* <AccountSidebar /> */}
				<ScrollableContainer className="account-content-container">
					<ScrollableContainerHeader>
						<MainAppContentHeader title="Account Settings" />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<div className="account-content-tabs">
							<ul className="nav nav-tabs" id="myTab" role="tablist">
								<li className="nav-item" role="presentation">
									<a
										className={`nav-link${selectedTab === null || selectedTab === 'settings'
											? ' active'
											: ''}`}
										href="/account#settings"
										//data-bs-toggle="tab"
										data-bs-target="#settings"
										type="button"
										role="tab"
										aria-controls="settings"
										aria-selected={selectedTab === null}
									>
										Account Settings
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className={`nav-link${selectedTab === 'billing' ? ' active' : ''}`}
										href="/account#billing"
										//data-bs-toggle="tab"
										data-bs-target="#billing"
										type="button"
										role="tab"
										aria-controls="billing"
										aria-selected={selectedTab === 'billing'}
									>
										Billing & Subscription
									</a>
								</li>
								{/* <li className="nav-item" role="presentation">
									<a
										className={`nav-link${selectedTab === 'customfields' ? ' active' : ''}`}
										href="/account#customfields"
										//data-bs-toggle="tab"
										data-bs-target="#customfields"
										type="button"
										role="tab"
										aria-controls="customfields"
										aria-selected={selectedTab === 'customfields'}
									>
										Custom Fields
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className={`nav-link${selectedTab === 'integration' ? ' active' : ''}`}
										href="/account#integration"
										//data-bs-toggle="tab"
										data-bs-target="#integration"
										type="button"
										role="tab"
										aria-controls="integration"
										aria-selected={selectedTab === 'integration'}
									>
										API Integration
									</a>
								</li> */}
							</ul>
							<div className="tab-content" id="myTabContent">
								<div
									className={`tab-pane fade${selectedTab === null || selectedTab === 'settings'
										? ' active show'
										: ''}`}
									id="settings"
									role="tabpanel"
									aria-labelledby="settings-tab"
								>
									<AccountContentArea type="settings" />
								</div>
								<div
									className={`tab-pane fade${selectedTab === 'billing' ? ' active show' : ''}`}
									id="billing"
									role="tabpanel"
									aria-labelledby="billing-tab"
								>
									<AccountContentArea type="billing" />
								</div>
								{/* <div
									className={`tab-pane fade${selectedTab === 'customfields' ? ' active show' : ''}`}
									id="customfields"
									role="tabpanel"
									aria-labelledby="customfields-tab"
								>
									<CustomFields fields={customFields} callback={() => getCustomFields(searchQuery)} />
								</div>
								<div
									className={`tab-pane fade${selectedTab === 'integration' ? ' active show' : ''}`}
									id="integration"
									role="tabpanel"
									aria-labelledby="integration-tab"
								>
									<AccountIntegration />
								</div> */}
							</div>
						</div>
						<Footer />

						<Routes>
							<Route path="/billing/change-plan" element={<AccountContentArea type="change-plan" />} />
							{/* <Route path="/billing/payment" element={<AccountContentArea type="payment" />} /> */}
							<Route path="/billing" element={<AccountContentArea type="billing" />} />
							{/* <Route path="/settings" element={<AccountContentArea type="settings" />} /> */}
							{/* <Route path="/*" element={<AccountContentArea type="settings" />} /> */}
						</Routes>
					</ScrollableContainerContent>
				</ScrollableContainer>
			</div>
		</Fragment>
	);
};

export default Account;
