import { useState, useEffect } from 'react';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import { Link } from 'react-router-dom';
import { GetAnalytics } from '../../../../Services';
import './ContactsSidebar.css';

const ContactsSidebar = ({ type }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ analyticsData, setAnalyticsData ] = useState([]);

	useEffect(() => {
		getAnalyticsData();
	}, []);

	const getAnalyticsData = async () => {
		let startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10)).toISOString();
		let endDate = new Date().toISOString();

		let data = {
			startDate : startDate,
			endDate   : endDate
		};
		const response = await GetAnalytics(data);

		if (response !== undefined) {
			setAnalyticsData(response);
		}
	};

	if (isLoading) {
		return (
			<ScrollableContainer className="contacts-sidebar-container">
				<ScrollableContainerContent>
					<div className="container">
						<div className={`contacts-item row ${type === 'contacts' ? 'active' : ''}`}>
							<div className="contacts-item-label col-12 col-sm-10">
								<Link to="/contacts" className="btn btn-link">
									<span>Contacts</span>
								</Link>
							</div>
							<div className="contacts-item-count col-12 col-sm-2">
								{analyticsData && analyticsData.newContactsCount ? (
									<span>{analyticsData.newContactsCount}</span>
								) : (
									''
								)}
							</div>
						</div>
						<div className={`contacts-item row ${type === 'lists' ? 'active' : ''}`}>
							<div className="contacts-item-label col-12 col-sm-10">
								<Link to="/contacts/lists" className="btn btn-link">
									<span>Lists</span>
								</Link>
							</div>
							<div className="contacts-item-count col-12 col-sm-2">
								{analyticsData && analyticsData.newContactListsCount ? (
									<span>{analyticsData.newContactListsCount}</span>
								) : (
									''
								)}
							</div>
						</div>
						{/* <div className={`contacts-item row ${type === 'custom-fields' ? 'active' : ''}`}>
							<div className="contacts-item-label col-10">
								<Link to="/contacts/custom-fields" className="btn btn-link">
									<span>Custom Fields</span>
								</Link>
							</div>
							<div className="contacts-item-count col-2"></div>
						</div> */}
					</div>
				</ScrollableContainerContent>
			</ScrollableContainer>
		);
	}

	return (
		<ScrollableContainer className="contacts-sidebar-container">
			<ScrollableContainerContent />
		</ScrollableContainer>
	);
};

export default ContactsSidebar;
