import './ScrollableContainerContent.css';

const ScrollableContainerContent = ({ children, ref }) => {
	return (
		// <div className="app-scrollable-container-content-area">
		<div className="app-scrollable-container-content" ref={ref}>
			{children}
		</div>
		// </div>
	);
};

export default ScrollableContainerContent;
