import './AutorespondersList.css';
import AutorespondersListItem from '../autorespondersListItem/AutorespondersListItem';
import { Fragment } from 'react';

const AutorespondersList = ({ items }) => {
	return (
		<div className="autoresponders-list-container">
			<Fragment>
				{items && items.length > 0 ? (
					items.map((item) => <AutorespondersListItem item={item} key={item.id} />)
				) : (
					<div className="no-data">You have no drip campaigns</div>
				)}
			</Fragment>
		</div>
	);
};

export default AutorespondersList;
