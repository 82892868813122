import { useState, useEffect, Fragment } from 'react';
import AutorespondersSidebarHeaderPlaceholder from '../../../placeholders/autorespondersSidebarHeaderPlaceholder/AutorespondersSidebarHeaderPlaceholder';
import AutoresponderPlaceholder from '../../../placeholders/autoresponderPlaceholder/AutoresponderPlaceholder';
import AutorespondersList from './autorespondersList/AutorespondersList';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import AutorespondersSidebarHeader from './autorespondersSidebarHeader/AutorespondersSidebarHeader';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import { GetAutoresponders } from '../../../../Services';
import './AutorespondersSidebar.css';

const AutorespondersSidebar = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ autoresponders, setAutoresponders ] = useState([]);
	const [ { reloadAutoresponders, searchQuery }, dispatch ] = useStateValue();

	useEffect(() => {
		dispatch({
			type        : actionTypes.SEARCH_QUERY,
			searchQuery : null
		});
	}, []);

	useEffect(
		() => {
			getAutoresponders(searchQuery);

			let autorespondersInterval = setInterval(() => {
				getAutoresponders(searchQuery);
			}, 5000);

			return () => {
				clearInterval(autorespondersInterval);
			};
		},
		[ searchQuery ]
	);

	useEffect(
		() => {
			if (reloadAutoresponders) {
				getAutoresponders(searchQuery);

				dispatch({
					type                 : actionTypes.RELOAD_AUTORESPONDERS,
					reloadAutoresponders : false
				});
			}
		},
		[ reloadAutoresponders ]
	);

	const getAutoresponders = async (query) => {
		let data = { query: query };
		const items = await GetAutoresponders(data);

		if (items) {
			//sort by date desc
			items.sort((a, b) => {
				let dateA = new Date(a.dateCreated),
					dateB = new Date(b.dateCreated);
				return dateB - dateA;
			});

			setAutoresponders(items);
		}

		setIsLoading(false);
	};

	return (
		<ScrollableContainer className="autoresponders-sidebar-container">
			{isLoading ? (
				<Fragment>
					<ScrollableContainerHeader>
						<AutorespondersSidebarHeaderPlaceholder />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<AutoresponderPlaceholder />
						<AutoresponderPlaceholder />
						<AutoresponderPlaceholder />
						<AutoresponderPlaceholder />
						<AutoresponderPlaceholder />
					</ScrollableContainerContent>
				</Fragment>
			) : (
				<Fragment>
					<ScrollableContainerHeader>
						<AutorespondersSidebarHeader items={autoresponders} callback={getAutoresponders} />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<AutorespondersList items={autoresponders} />
					</ScrollableContainerContent>
				</Fragment>
			)}
		</ScrollableContainer>
	);
};

export default AutorespondersSidebar;
