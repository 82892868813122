import { useState, useRef, Fragment } from 'react';
import './AccountIntegration.css';
import AppButton from '../../../../appButton/AppButton';
import { copyToClipboard } from '../../../../../common';
import { useStateValue } from '../../../../../StateProvider';
import { Link } from 'react-router-dom';
import ScrollableContainer from '../../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import MainAppContentHeader from '../../../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import Footer from '../../../footer/Footer';

const AccountIntegration = () => {
	const [ { user }, dispatch ] = useStateValue();
	const zapierApiKeyRef = useRef();

	return (
		<div className="app-integration-container panel">
			<ScrollableContainer className="account-integration-container">
				<ScrollableContainerHeader>
					<MainAppContentHeader title="API Integration" showGoBackLink={true} goBackUrl="/tools" />
				</ScrollableContainerHeader>
				<ScrollableContainerContent>
					<Fragment>
						<label htmlFor="zapierApiKey">Zapier Api Key</label>
						<div className="input-group mb-3 mt-1">
							<div className="position-relative flex-fill">
								<input
									type="text"
									id="zapierApiKey"
									name="zapierApiKey"
									ref={zapierApiKeyRef}
									className="form-control"
									disabled={true}
									defaultValue={user.zapierApiKey}
								/>
								<AppButton
									className="link btn-copy-input"
									children={<i className="fa-regular fa-copy" />}
									title="Copy to clipboard"
									onClick={() =>
										copyToClipboard(zapierApiKeyRef.current.value, 'Zapier Api Key copied!')}
								/>
							</div>
							<div className="input-group-append align-self-center ps-3">
								<Link to="/zapier" target="_blank" className="app-link">
									Documentation
								</Link>
							</div>
						</div>
					</Fragment>
					<Footer />
				</ScrollableContainerContent>
			</ScrollableContainer>
		</div>
	);
};

export default AccountIntegration;
