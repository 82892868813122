import { useState, useEffect, useRef } from 'react';
import MessageDetails from '../message/MessageDetails';
import { Fragment } from 'react';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';
import './MessagesList.css';

const MessagesList = ({ messages }) => {
	const [ { selectedThread }, dispatch ] = useStateValue();
	const messagesContainerRef = useRef();
	const [ needsScrollUpdate, setNeedsScrollUpdate ] = useState(true);
	const scrollToBotRef = useRef(null);

	useEffect(
		() => {
			if (messages.length > 0) {
				scrollToBottom();
			}
		},
		[ selectedThread ]
	);

	useEffect(
		() => {
			if (needsScrollUpdate && messages.length > 0) {
				scrollToBottom();
			}
		},
		[ messages ]
	);

	const scrollToBottom = () => {
		scrollToBotRef.current.scrollIntoView({ behavior: 'auto' }); // change to 'smooth' if you want it animated
	};

	const isUserNearBottom = () => {
		const threshold = 150;
		const position = messagesContainerRef.current.scrollTop + messagesContainerRef.current.offsetHeight;
		const height = messagesContainerRef.current.scrollHeight;
		return position > height - threshold;
	};

	const handleChatScroll = () => {
		if (isUserNearBottom()) {
			setNeedsScrollUpdate(true);
		} else {
			setNeedsScrollUpdate(false);
		}
	};

	return (
		<div className="messages-list-container" ref={messagesContainerRef} onScroll={handleChatScroll}>
			<Fragment>
				{messages &&
					messages.length > 0 &&
					messages.map((message) => <MessageDetails message={message} key={message.id} />)}
				<div ref={scrollToBotRef} />
			</Fragment>
		</div>
	);
};

export default MessagesList;
