import { Fragment, useEffect, useState, useRef } from 'react';
import './KeywordsForm.css';
import AppButton from '../../../../../appButton/AppButton';
import { useStateValue } from '../../../../../../StateProvider';
import { actionTypes } from '../../../../../../reducer';
import { toast } from 'react-toastify';
import ScrollableContainer from '../../../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerFooter from '../../../../../scrollableContainer/scrollableContainerFooter/ScrollableContainerFooter';
import { GetKeyword, AddKeyword, GetContactLists, EditKeyword, DeleteKeyword } from '../../../../../../Services';
import FileUploadDialog from '../../../../fileUpload/FileUploadDialog';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import AppLinkGoBack from '../../../../../appLinkGoBack/AppLinkGoBack';

const KeywordsForm = ({ id, onDelete }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ { user, phoneNumber, selectedMedia }, dispatch ] = useStateValue();
	const [ formData, setFormData ] = useState({});
	const [ keyword, setKeyword ] = useState({});
	const [ showUploadFilesDialog, setShowUploadFilesDialog ] = useState(false);
	const [ contactLists, setContactLists ] = useState([]);
	const [ selectedKeywordLists, setSelectedKeywordLists ] = useState([]);
	const [ selectedSuppressionListId, setSelectedSuppressionListId ] = useState([]);
	const [ keywordType, setKeywordType ] = useState('');
	const [ messageType, setMessageType ] = useState('');
	const messageTypeSMSRef = useRef();
	const messageTypeMMSRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(
		() => {
			getKeyword();
		},
		[ location ]
	);

	const getKeyword = async () => {
		let tempContactLists = await getContactLists();

		if (id) {
			setIsEdit(true);

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});

			const response = await GetKeyword(id);

			if (response) {
				let tempFormData = response;
				setKeyword(response);

				//contact lists
				let tempKeywordLists = [];
				if (response.keywordLists !== undefined && response.keywordLists.length > 0) {
					for (let i = 0; i < response.keywordLists.length; i++) {
						tempKeywordLists.push({
							value : response.keywordLists[i].contactList.id,
							label : response.keywordLists[i].contactList.name
						});
					}
				}

				//suppression list
				let tempSuppressionListId = [];
				let suppressionListIdItem = tempContactLists.find((x) => x.value === response.suppressionListId);
				if (suppressionListIdItem) {
					tempSuppressionListId.push({
						value : suppressionListIdItem.value,
						label : suppressionListIdItem.label
					});
				}

				//attachments
				let tempKeywordAttachments = [];
				if (response.keywordAttachments !== undefined && response.keywordAttachments.length > 0) {
					for (let i = 0; i < response.keywordAttachments.length; i++) {
						tempKeywordAttachments.push({
							id   : response.keywordAttachments[i].media.id,
							name : response.keywordAttachments[i].media.name,
							url  : response.keywordAttachments[i].media.mediaUrl
						});
					}
				}

				setSelectedKeywordLists(tempKeywordLists);
				setSelectedSuppressionListId(tempSuppressionListId);
				setKeywordType(tempFormData.type);
				setMessageType(tempFormData.messageType);

				dispatch({
					type          : actionTypes.SELECTED_MEDIA,
					selectedMedia : tempKeywordAttachments
				});

				setFormData(tempFormData);
			}
		} else {
			setFormData({
				name    : '',
				message : ''
			});

			setIsEdit(false);
			setKeywordType('Immediate');
			setMessageType('SMS');
			setSelectedKeywordLists([]);
			setSelectedSuppressionListId([]);

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});
		}

		setIsLoading(false);
	};

	const getContactLists = async (query) => {
		let data = { query: query };
		const response = await GetContactLists(data);

		if (response !== undefined) {
			let tempContactLists = [];
			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					tempContactLists.push({ value: response[i].id, label: response[i].name });
				}
			}
			setContactLists(tempContactLists);

			return tempContactLists;
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		let value = e.target.value;

		if (target === 'messageType') {
			setMessageType(value);
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}
	};

	const handleSelectChange = (option, action) => {
		if (action.name === 'keywordLists') {
			setSelectedKeywordLists(option);
		} else if (action.name === 'suppressionListId') {
			setSelectedSuppressionListId({
				value : option.value,
				label : option.label
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (formData.name.trim() === '') {
			toast.error('Keyword name is required');
			return;
		}

		if (formData.message.trim() === '') {
			toast.error('Message cannot be empty');
			return;
		}

		setIsSaving(true);

		//prepare keyword lists for POST action
		let tempKeywordLists = [];

		if (selectedKeywordLists) {
			for (let i = 0; i < selectedKeywordLists.length; i++) {
				tempKeywordLists.push(selectedKeywordLists[i].value);
			}
		}

		//prepare attachments
		var tempSelectedMedia = [];

		if (selectedMedia !== null && selectedMedia.length > 0) {
			for (let i = 0; i < selectedMedia.length; i++) {
				tempSelectedMedia.push(selectedMedia[i].id);
			}
		}

		let tempFormData = formData;
		tempFormData.userId = user.id;
		tempFormData.phoneNumberId = phoneNumber.id;
		tempFormData.messageType = messageType;
		tempFormData.type = keywordType;
		tempFormData.listIds = tempKeywordLists;
		tempFormData.attachmentIds = tempSelectedMedia;
		tempFormData.suppressionListId = selectedSuppressionListId.value;

		let response = null;

		if (id) {
			response = await EditKeyword(id, tempFormData);
		} else {
			tempFormData.status = 'Active';

			response = await AddKeyword(tempFormData);
		}

		if (response && response.error) {
			toast.error(response.error);
			setIsSaving(false);

			return;
		}

		if (response !== undefined) {
			dispatch({
				type           : actionTypes.RELOAD_KEYWORDS,
				reloadKeywords : true
			});

			if (!isEdit) {
				navigate('/tools/keywords/' + response);
			}
		} else {
			//toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const removeAttachment = (e) => {
		const selectedAttachment = e.currentTarget;
		var id = selectedAttachment.getAttribute('data-id');

		var tempSelectedMediaItems = selectedMedia;

		tempSelectedMediaItems = tempSelectedMediaItems.filter((obj) => obj.id !== id);

		dispatch({
			type          : actionTypes.SELECTED_MEDIA,
			selectedMedia : tempSelectedMediaItems
		});
	};

	const toggleUploadFilesDialog = () => {
		setShowUploadFilesDialog((prev) => !prev);
	};

	const onMessageTypeSMSClick = () => {
		messageTypeSMSRef.current.classList.remove('btn-outline-primary');
		messageTypeSMSRef.current.classList.add('btn-primary');
		messageTypeMMSRef.current.classList.remove('btn-primary');
		messageTypeMMSRef.current.classList.add('btn-outline-primary');
		setMessageType('SMS');
	};

	const onMessageTypeMMSClick = () => {
		messageTypeSMSRef.current.classList.remove('btn-primary');
		messageTypeSMSRef.current.classList.add('btn-outline-primary');
		messageTypeMMSRef.current.classList.remove('btn-outline-primary');
		messageTypeMMSRef.current.classList.add('btn-primary');
		setMessageType('MMS');
	};

	const deleteKeyword = async () => {
		if (window.confirm('Are you sure you want to delete the keyword?')) {
			const response = await DeleteKeyword(id);

			if (response !== undefined) {
				if (onDelete) {
					onDelete();
				}

				dispatch({
					type           : actionTypes.RELOAD_KEYWORDS,
					reloadKeywords : true
				});

				navigate('/tools/keywords');
			}
		}
	};

	return (
		<Fragment>
			{!isLoading ? (
				<ScrollableContainer className="keywords-form-container">
					<ScrollableContainerHeader>
						<div className="form-header-title row mx-0">
							<div className="col-9 ps-0">
								<AppLinkGoBack redirect="/tools/keywords" />
								<span>{id ? 'Keyword Details' : 'Create Keyword'}</span>
							</div>
						</div>
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<form>
							<div className="form-inputs">
								<div className="form-group mb-3">
									<label htmlFor="name">Keyword</label>
									<input
										type="text"
										id="name"
										name="name"
										className="form-control"
										value={formData.name}
										disabled={isLoading || isSaving}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="keywordLists">Add to List</label>
									<Select
										name="keywordLists"
										id="keywordLists"
										isMulti={true}
										value={selectedKeywordLists}
										isDisabled={isLoading || isSaving}
										onChange={handleSelectChange}
										options={contactLists}
										className="react-select-component"
										classNamePrefix="react-select"
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="messageType">Message Type</label>
									<div className="btn-group d-block" role="group" aria-label="Message Type">
										<button
											type="button"
											className={
												messageType === '' || messageType === 'SMS' ? (
													'btn btn-primary'
												) : (
													'btn btn-outline-primary'
												)
											}
											ref={messageTypeSMSRef}
											onClick={onMessageTypeSMSClick}
											disabled={isLoading || isSaving}
										>
											SMS
										</button>
										<button
											type="button"
											className={
												messageType === 'MMS' ? 'btn btn-primary' : 'btn btn-outline-primary'
											}
											ref={messageTypeMMSRef}
											onClick={onMessageTypeMMSClick}
											disabled={isLoading || isSaving}
										>
											MMS
										</button>
									</div>
								</div>
								<div className="form-group mb-3 message-body">
									<label htmlFor="message">Message</label>
									<textarea
										id="message"
										name="message"
										rows="3"
										className="form-control"
										placeholder="Message"
										value={formData.message}
										onChange={handleChange}
										disabled={isLoading || isSaving}
									/>
									{selectedMedia !== null &&
									selectedMedia.length > 0 && (
										<div className="send-message-attachments-area">
											{selectedMedia.map((mediaItem) => (
												<div className="attachment-item" key={mediaItem.id} id={mediaItem.id}>
													<Link to={mediaItem.url} target="_blank">
														<span>{mediaItem.name}</span>
													</Link>
													<button
														className="btn btn-link attachment-item-remove"
														data-id={mediaItem.id}
														onClick={removeAttachment}
														disabled={isLoading || isSaving}
													>
														<i className="fa-solid fa-xmark p-0 m-0" />
													</button>
												</div>
											))}
										</div>
									)}
									<div className="send-message-actions">
										<div className="send-message-action">
											<AppButton
												className="link"
												children={<i className="fa-solid fa-paperclip mx-2" />}
												onClick={toggleUploadFilesDialog}
												disabled={isLoading || isSaving}
											/>
										</div>
									</div>
								</div>
							</div>
						</form>
					</ScrollableContainerContent>
					<ScrollableContainerFooter>
						<div className="container-buttons">
							<button
								type="button"
								className="btn btn-primary"
								onClick={handleSubmit}
								disabled={isLoading || isSaving}
							>
								{!isSaving ? (
									<Fragment>
										<span>Save</span>
									</Fragment>
								) : (
									<Fragment>
										{/* <Spinner /> */}
										<span>Saving...</span>
									</Fragment>
								)}
							</button>
							{isEdit && (
								<AppButton className="danger" children={<span>Delete</span>} onClick={deleteKeyword} />
							)}
						</div>
					</ScrollableContainerFooter>
				</ScrollableContainer>
			) : (
				<div className="text-center p-3">{/* <Spinner /> */}</div>
			)}
			{showUploadFilesDialog && <FileUploadDialog toggleDialog={toggleUploadFilesDialog} />}
		</Fragment>
	);
};

export default KeywordsForm;
