import './AutorespondersContentArea.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import MainAppContentHeader from '../../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import AutorespondersSidebar from '../autorespondersSidebar/AutorespondersSidebar';
import AutorespondersForm from './autoresponders/autorespondersForm/AutorespondersForm';
import Footer from '../../footer/Footer';
import { isMobile } from '../../../../common';
import { useLocation } from 'react-router-dom';

const AutorespondersContentArea = () => {
	const { id } = useParams();
	const location = useLocation();
	const [ showSidebar, setShowSidebar ] = useState(true);
	const [ showForm, setShowForm ] = useState(true);

	useEffect(
		() => {
			if (isMobile()) {
				if (id || window.location.pathname === '/tools/autoresponders/add') {
					setShowSidebar(false);
					setShowForm(true);
				} else {
					setShowSidebar(true);
					setShowForm(false);
				}
			} else {
				if (id || window.location.pathname === '/tools/autoresponders/add') {
					setShowForm(true);
				}
			}
		},
		[ location ]
	);

	return (
		<ScrollableContainer className="autoresponders-content-container">
			<ScrollableContainerHeader>
				{showSidebar && (
					<MainAppContentHeader title="Drip Campaigns" showGoBackLink={true} goBackUrl="/tools" />
				)}
			</ScrollableContainerHeader>
			<ScrollableContainerContent>
				{showSidebar && <AutorespondersSidebar id={id} />}
				{showForm && <AutorespondersForm id={id} />}
				<Footer />
			</ScrollableContainerContent>
		</ScrollableContainer>
	);
};

export default AutorespondersContentArea;
