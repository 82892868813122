import { Fragment, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetBillingPlans } from '../../../../../../Services';
import { useStateValue } from '../../../../../../StateProvider';
import { actionTypes } from '../../../../../../reducer';
import { formatCurrency } from '../../../../../../common';
import { toast } from 'react-toastify';
import './PlanChange.css';

const PlanChange = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ plans, setPlans ] = useState([]);
	const [ planPeriod, setPlanPeriod ] = useState('monthly');
	const [ newPlan, setNewPlan ] = useState({});
	const [ { selectedPlan, user }, dispatch ] = useStateValue();
	const navigate = useNavigate();
	const managePlansUrl =
		'https://billing.stripe.com/p/login/9AQ8xU15x5Zf0ta8ww?prefilled_email=' + encodeURI(user.email);

	useEffect(() => {
		//getPlans();
		if (user.billingId) {
			window.location = managePlansUrl;
		}

		setIsLoading(false);
	}, []);

	const getPlans = async (query) => {
		let data = { query: query };
		const response = await GetBillingPlans(data);

		if (response) {
			let tempPlans = response;

			if (planPeriod === 'monthly') {
				tempPlans = tempPlans.filter((x) => x.price !== 0);
			} else if (planPeriod === 'annually') {
				tempPlans = tempPlans.filter((x) => x.annualPrice !== 0);
			}

			setPlans(tempPlans);
		}
	};

	const handlePlanSelectChange = (e) => {
		let selectedPlanPeriod = e.target.id.replace('PlanOption', '');
		setPlanPeriod(selectedPlanPeriod);
	};

	const selectPlan = (e) => {
		var planId = e.currentTarget.getAttribute('data-id');

		let newPlan = plans.find((x) => x.id === planId);

		if (newPlan) {
			setNewPlan(newPlan);
		}
	};

	const updatePlan = (e) => {
		if (newPlan && newPlan.id) {
			dispatch({
				type         : actionTypes.SELECTED_PLAN,
				selectedPlan : newPlan
			});

			navigate('/account/billing/payment');
		} else {
			toast.error('You have to select a plan.');
		}
	};

	return (
		<Fragment>
			{!isLoading && (
				<Fragment>
					<div className="change-plan-container">
						<stripe-pricing-table
							// pricing-table-id="prctbl_1MEytZCC3JE4B136kfofhIMK"
							// publishable-key="pk_test_51LYBifCC3JE4B136sU0ZJa6NKLjdPjVr8fN6pI1wOcRMx8k3lZsYXMNsZKid3jhfNtmLoXy791RUTV1xk2N6Uq5U00zFtz350v"
							pricing-table-id="prctbl_1MGVHQCC3JE4B136v4P2mJX5"
							publishable-key="pk_live_51LYBifCC3JE4B1362nvKuNKUqRrBPbixGECW6SaRiVFpMbGBRWzgLvBPM8m6AEy7TxnZoJqRm9cZQuflwFcnq4on00wHxCjhza"
							client-reference-id={user.id}
						/>
					</div>
					{/* <div className="change-plan-container">
						<div className="plan-selector">
							<div className="form-check form-check-inline me-5">
								<input
									className="form-check-input"
									type="radio"
									name="planSelectOptions"
									id="monthlyPlanOption"
									onChange={handlePlanSelectChange}
									defaultChecked={planPeriod === 'monthly'}
								/>
								<label className="form-check-label" htmlFor="monthlyPlanOption">
									Monthly
								</label>
							</div>
							<div className="form-check form-check-inline">
								<input
									className="form-check-input"
									type="radio"
									name="planSelectOptions"
									id="annuallyPlanOption"
									onChange={handlePlanSelectChange}
									defaultChecked={planPeriod === 'annually'}
								/>
								<label className="form-check-label" htmlFor="annuallyPlanOption">
									Annually
								</label>
							</div>
						</div>
					</div>
					<div className="change-plan-container">
						<table className="table table-borderless">
							<thead className="table-secondary">
								<tr>
									<th scope="col">Name</th>
									<th scope="col">Credits</th>
									<th scope="col">Price/mo</th>
									<th scope="col" />
								</tr>
							</thead>
							<tbody>
								<Fragment>
									{plans &&
										plans.length > 0 &&
										plans.map((plan) => (
											<tr
												key={plan.id}
												data-id={plan.id}
												className={`${plan.id === newPlan.id ? 'active' : ''}`}
											>
												<td>{plan.name}</td>
												<td>{plan.credits}</td>
												<td>
													{formatCurrency(
														planPeriod === 'monthly' ? plan.price : plan.annualPrice / 12
													)}
												</td>
												<td className="settings-action">
													<button
														type="button"
														className="btn btn-link p-0"
														data-id={plan.id}
														onClick={selectPlan}
													>
														<span>Select</span>
													</button>
												</td>
											</tr>
										))}
								</Fragment>
							</tbody>
						</table>
					</div>
					<div className="change-plan-container py-0">
						<button className="btn btn-primary" onClick={updatePlan}>
							Update Plan
						</button>
					</div> */}
				</Fragment>
			)}
		</Fragment>
	);
};

export default PlanChange;
