import { Link } from 'react-router-dom';
import './WebFormsSidebarHeader.css';
import SearchListInput from '../../../searchListInput/SearchListInput';

const WebFormsSidebarHeader = ({ callback }) => {
	return (
		<div className="webforms-sidebar-header-container">
			<div className="webforms-sidebar-header-search">
				<SearchListInput callback={callback} />
			</div>
			<div className="webforms-sidebar-header-add-button">
				<Link to="/tools/webforms/add" className="btn btn-primary btn-lg">
					<span>New</span>
				</Link>
			</div>
		</div>
	);
};

export default WebFormsSidebarHeader;
