import './KeywordsList.css';
import KeywordsListItem from '../keywordsListItem/KeywordsListItem';
import { Fragment } from 'react';

const KeywordsList = ({ items }) => {
	return (
		<div className="keywords-list-container">
			<Fragment>
				{items && items.length > 0 ? (
					items.map((item) => <KeywordsListItem item={item} key={item.id} />)
				) : (
					<div className="no-data">You have no keywords</div>
				)}
			</Fragment>
		</div>
	);
};

export default KeywordsList;
