import { useRef } from 'react';
import AppButton from '../../../../appButton/AppButton';
import { actionTypes } from '../../../../../reducer';
import { useStateValue } from '../../../../../StateProvider';
import './InboxSidebarHeader.css';
import SearchListInput from '../../../searchListInput/SearchListInput';

const InboxSidebarHeader = ({ threads, callback }) => {
	const [ { user, isNewThread, selectedThread, searchQuery, searchInboxType }, dispatch ] = useStateValue();
	const refSearchInboxType = useRef();

	const onNewThread = () => {
		dispatch({
			type        : actionTypes.IS_NEW_THREAD,
			isNewThread : true
		});
	};

	const selectSearchInboxType = () => {
		let inboxType = refSearchInboxType.current.value;

		dispatch({
			type            : actionTypes.SEARCH_INBOX_TYPE,
			searchInboxType : inboxType
		});

		if (callback) {
			callback(searchQuery, inboxType);
		}
	};

	return (
		<div className="inbox-sidebar-header-container">
			<div className="inbox-sidebar-header-search">
				<SearchListInput callback={callback} />
			</div>
			<div className="inbox-sidebar-header-select">
				<select className="form-select" ref={refSearchInboxType} onChange={selectSearchInboxType}>
					<option value="All">All</option>
					<option value="Unread">Unread</option>
				</select>
			</div>
			<div className="inbox-sidebar-header-add-button">
				<AppButton className="primary btn-lg" onClick={onNewThread} children={<span>New</span>} />
			</div>
		</div>
	);
};

export default InboxSidebarHeader;
