import './Logs.css';
import { useState, Fragment, useEffect } from 'react';
import Moment from 'react-moment';
import { GetLogs } from '../../../Services';
import { actionTypes } from '../../../reducer';
import { useStateValue } from '../../../StateProvider';
import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import MainAppContentHeader from '../../mainApp/mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import ContactsListHeaderPlaceholder from '../../placeholders/contactsListHeaderPlaceholder/ContactsListHeaderPlaceholder';
import TablePlaceholder from '../../placeholders/tablePlaceholder/TablePlaceholder';
import Footer from '../../mainApp/footer/Footer';
import SearchListInput from '../../mainApp/searchListInput/SearchListInput';

const Logs = ({ logs, callback }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ logId, setLogId ] = useState(0);
	const [ logItems, setLogItems ] = useState(0);
	const [ { user, searchQuery, isContactsSearch }, dispatch ] = useStateValue();

	useEffect(() => {
		getLogs();
	}, []);

	const getLogs = async (query) => {
		try {
			let data = { query: query, isContactsSearch: false, includeDefaultCustomFields: true };
			const response = await GetLogs(data);

			if (response && response.error) {
				//toast.error(response.error);
				return;
			}

			if (response !== undefined && response !== '') {
				//set default sort by date desc
				response.sort((a, b) => {
					let dateA = new Date(a.dateCreated),
						dateB = new Date(b.dateCreated);
					return dateB - dateA;
				});

				setLogItems(response);
			}
		} catch (error) {}

		setIsLoading(false);
	};

	return (
		<div className="app-logs-container panel">
			<ScrollableContainer className="logs-content-container">
				<ScrollableContainerHeader>
					<MainAppContentHeader title="Log" showGoBackLink={true} goBackUrl="/administration" />
				</ScrollableContainerHeader>
				<ScrollableContainerContent>
					<ScrollableContainer className="logs-content-area p-0">
						{isLoading ? (
							<Fragment>
								<ScrollableContainerHeader>
									<ContactsListHeaderPlaceholder />
								</ScrollableContainerHeader>
								<ScrollableContainerContent>
									<TablePlaceholder />
								</ScrollableContainerContent>
							</Fragment>
						) : (
							<Fragment>
								<ScrollableContainerHeader>
									<div className="custom-fields-content-header-container">
										<div className="custom-fields-content-header-search">
											<SearchListInput callback={() => getLogs(searchQuery)} />
										</div>
									</div>
								</ScrollableContainerHeader>
								<ScrollableContainerContent>
									<div className="logs-list-container">
										<div className="table-responsive">
											<table className="table table-borderless">
												<thead className="table-secondary">
													<tr>
														<th scope="col" className="event">
															Event
														</th>
														<th scope="col" className="message">
															Message
														</th>
														<th scope="col" className="created">
															Created
														</th>
													</tr>
												</thead>
												<tbody>
													<Fragment>
														{logItems &&
															logItems.length > 0 &&
															logItems.map((log) => (
																<tr
																	key={log.id}
																	className={`log-item ${logId && logId === log.id
																		? 'active'
																		: ''}`}
																>
																	<td>{log.event}</td>
																	<td>{log.message}</td>
																	<td>
																		<Moment format="MM/DD/YYYY hh:mm a">
																			{log.dateCreated}
																		</Moment>
																	</td>
																</tr>
															))}
													</Fragment>
												</tbody>
											</table>
										</div>
									</div>
								</ScrollableContainerContent>
							</Fragment>
						)}
					</ScrollableContainer>

					<Footer />
				</ScrollableContainerContent>
			</ScrollableContainer>
		</div>
	);
};

export default Logs;
