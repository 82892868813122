import { Fragment, useRef, useEffect } from 'react';
import AppButton from '../../appButton/AppButton';
import { actionTypes } from '../../../reducer';
import { useStateValue } from '../../../StateProvider';
import './SearchListInput.css';

const SearchListInput = ({ callback, type }) => {
	const refSearchInput = useRef();
	const [
		{
			searchQuery,
			searchInboxType,
			reloadInbox,
			reloadBroadcasts,
			reloadWebForms,
			reloadKeywords,
			reloadAutoresponders,
			isContactsSearch
		},
		dispatch
	] = useStateValue();

	useEffect(
		() => {
			dispatch({
				type        : actionTypes.SEARCH_QUERY,
				searchQuery : refSearchInput.current.value
			});
		},
		[ reloadInbox, reloadBroadcasts, reloadWebForms, reloadKeywords, reloadAutoresponders ]
	);

	const handleSearch = () => {
		if (type && type === 'contacts') {
			dispatch({
				type             : actionTypes.IS_CONTACTS_SEARCH,
				isContactsSearch : true
			});
		}

		if (callback) {
			callback(searchQuery, searchInboxType);
		}
	};

	const onSearchKeyDown = (e) => {
		dispatch({
			type        : actionTypes.SEARCH_QUERY,
			searchQuery : refSearchInput.current.value
		});

		if (type && type === 'contacts') {
			dispatch({
				type             : actionTypes.IS_CONTACTS_SEARCH,
				isContactsSearch : true
			});
		}

		if (e.key === 'Enter') {
			handleSearch();
		}
	};

	const onSearchChange = (e) => {
		dispatch({
			type        : actionTypes.SEARCH_QUERY,
			searchQuery : refSearchInput.current.value
		});
	};

	return (
		<Fragment>
			<input
				type="search"
				className="form-control search-list-input"
				onKeyDown={onSearchKeyDown}
				onChange={onSearchChange}
				ref={refSearchInput}
			/>
			<AppButton
				className="link search-list-button"
				children={
					<span>
						<i className="fa-solid fa-magnifying-glass" />
					</span>
				}
				onClick={handleSearch}
			/>
		</Fragment>
	);
};

export default SearchListInput;
