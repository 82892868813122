import { Fragment, useRef, useEffect, useState } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { actionTypes } from '../../../../../reducer';
import { useStateValue } from '../../../../../StateProvider';
import { GetMessageThread, SetThreadMessagesRead } from '../../../../../Services';
import './MessageThread.css';

const MessageThread = ({ thread }) => {
	const messageThreadRef = useRef();
	const [ { selectedThread, isNewThread, reloadInbox, selectedMedia }, dispatch ] = useStateValue();
	const [ manuallySelected, setManuallySelected ] = useState(false);

	useEffect(() => {
		if (!manuallySelected && messageThreadRef.current) {
			messageThreadRef.current.scrollIntoView({ behavior: 'auto' });
		}
	}, []);

	const isDateToday = (date) => {
		return moment(date).isSame(moment(), 'day');
	};

	const onMessageThreadSelect = async () => {
		setManuallySelected(false);

		//get thread object
		let thread = await GetMessageThread(messageThreadRef.current.id);

		if (thread !== undefined) {
			dispatch({
				type           : actionTypes.SELECTED_THREAD,
				selectedThread : thread
			});

			// set all messages as read
			let setReadResponse = await SetThreadMessagesRead(thread.id);
		}

		dispatch({
			type        : actionTypes.RELOAD_INBOX,
			reloadInbox : true
		});

		dispatch({
			type        : actionTypes.IS_NEW_THREAD,
			isNewThread : false
		});

		dispatch({
			type          : actionTypes.SELECTED_MEDIA,
			selectedMedia : null
		});
	};

	return (
		<div
			className={`message-thread-container ${selectedThread != null && selectedThread.id === thread.id
				? 'active'
				: ''}`}
			ref={messageThreadRef}
			onClick={onMessageThreadSelect}
			id={thread.id}
		>
			<div className="message-thread-contact-name">
				<span>
					{thread.contact.fullName !== null && thread.contact.fullName.trim() !== '' ? (
						thread.contact.fullName
					) : (
						thread.contact.phoneNumberFriendly
					)}
				</span>
				{/* {thread.contact.status && (
					<span
						className={`message-thread-contact-status ${thread.contact.status.toLowerCase()}`}
						title={`${thread.contact.status} contact`}
					>
						<i className="fa-solid fa-circle" />
					</span>
				)} */}
			</div>
			<div className="message-thread-body d-inline-block text-truncate">
				<span className="d-inline-block text-truncate">
					<Fragment>{thread.lastMessage !== null ? thread.lastMessage.body : ''}</Fragment>
				</span>
			</div>
			<div className="message-thread-time">
				<span>
					<Fragment>
						{thread.messages != null && thread.messages.length > 0 ? isDateToday(
							thread.lastMessage.dateCreated
						) ? (
							<Moment format="h:mm a">{thread.lastMessage.dateCreated}</Moment>
						) : (
							<Moment format="M/DD">{thread.lastMessage.dateCreated}</Moment>
						) : (
							''
						)}
					</Fragment>
				</span>
			</div>
			{thread.unreadMessages !== null &&
			thread.unreadMessages !== 0 && (
				<div className="message-thread-count">
					<span>
						<Fragment>{thread.unreadMessages}</Fragment>
					</span>
				</div>
			)}
		</div>
	);
};

export default MessageThread;
