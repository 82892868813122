import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { actionTypes } from '../../reducer';
import { GetAccount } from '../../Services';
import { useStateValue } from '../../StateProvider';
import { deleteToken, getToken, setToken } from '../../Token';
import AppRouter from '../appRouter/AppRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../loader/Loader';

const AppLoader = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ { user }, dispatch ] = useStateValue();
	const [ toastifyPosition, setToastifyPosition ] = useState('bottom-left');
	// const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		getAccountDetails();
	}, []);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW <= 767) {
			setToastifyPosition('bottom-center');
		} else {
			setToastifyPosition('bottom-left');
		}
	};

	const getAccountDetails = async () => {
		let token = getToken();

		if (token) {
			try {
				const response = await GetAccount();

				if (response) {
					const { ...tempUser } = response;

					// if (tempUser.status === 'Canceled' || tempUser.status === 'canceled') {
					// 	navigate('/login');
					// }

					//setToken(token);

					dispatch({
						type : actionTypes.SET_USER,
						user : tempUser
					});

					dispatch({
						type        : actionTypes.SET_PHONE_NUMBER,
						phoneNumber : tempUser.phoneNumbers[0]
					});
				} else {
					let tempToken = getToken();

					if (tempToken) {
						deleteToken();
					}
				}
			} catch (error) {
				let tempToken = getToken();

				if (tempToken) {
					deleteToken();
				}
			}
		}

		setIsLoading(false);
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Fragment>
			<BrowserRouter basename="/">
				<AppRouter />
			</BrowserRouter>

			<ToastContainer
				position={toastifyPosition}
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				limit={1}
			/>
		</Fragment>
	);
};

export default AppLoader;
