import './CustomFieldFormDialog.css';
import { useState, Fragment, useEffect, useRef } from 'react';
import AppButton from '../../../../../appButton/AppButton';
import ModalDialog from '../../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { AddCustomField, EditCustomField, GetCustomField, DeleteCustomField } from '../../../../../../Services';
import { toast } from 'react-toastify';
import AppSpinner from '../../../../../appSpinner/AppSpinner';

const CustomFieldFormDialog = ({ customFieldId, callback, onClose }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ mergeFieldValue, setMergeFieldValue ] = useState('');

	useEffect(() => {
		getCustomFieldDetails();
	}, []);

	const getCustomFieldDetails = async () => {
		if (customFieldId !== undefined && customFieldId !== null && customFieldId !== 0 && customFieldId !== '') {
			setIsEdit(true);

			const response = await GetCustomField(customFieldId);

			if (response !== undefined) {
				setFormData(response);
				setMergeFieldValue(response.mergeField);
			}
		} else {
			setFormData({
				...formData,
				type : 'text'
			});
		}

		setIsLoading(false);
	};

	const handleChange = (e) => {
		var target = e.target.name;
		var value = e.target.value;

		setFormData({
			...formData,
			[target] : value
		});

		if (target === 'name') {
			setMergeFieldValue(value !== '' ? '##' + value + '##' : '');
		}
	};

	const handleOnCancel = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.name) {
			toast.error('Name is required!');
			return;
		}

		setIsSaving(true);

		var tempFormData = formData;
		tempFormData['mergeField'] = mergeFieldValue;

		setFormData(tempFormData);

		let response = null;

		if (isEdit) {
			response = await EditCustomField(customFieldId, formData);
		} else {
			response = await AddCustomField(formData);
		}

		if (response && response.error) {
			toast.error(response.error);

			return;
		}

		if (response !== undefined) {
			if (callback) {
				callback();
			}

			if (onClose) {
				onClose();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const deleteCustomField = async () => {
		if (window.confirm('Are you sure you want to delete the custom field?')) {
			const response = await DeleteCustomField(customFieldId);

			if (response !== undefined) {
				if (callback) {
					callback();
				}

				if (onClose) {
					onClose();
				}
			}
		}
	};

	return (
		<ModalDialog position="right-sidebar" onClose={onClose}>
			<Fragment>
				{!isLoading ? (
					<form onSubmit={handleSubmit}>
						<ModalDialogContent>
							<h4 className="mb-3">{`${isEdit ? 'Edit' : 'Add'} Custom Field`}</h4>
							<div className="form-inputs">
								<div className="form-group mb-3">
									<label htmlFor="name">Type</label>
									<select
										id="type"
										name="type"
										className="form-select"
										disabled={isSaving}
										value={formData.type}
										onChange={handleChange}
									>
										<option value="text">Text</option>
										<option value="number">Number</option>
										<option value="select">Select</option>
										<option value="date">Date</option>
										<option value="datetime">Date Time</option>
										<option value="zipcode">Zip Code</option>
										<option value="phone">Phone</option>
										<option value="website">Website</option>
									</select>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="name">Name</label>
									<input
										type="text"
										id="name"
										name="name"
										className="form-control"
										disabled={isSaving}
										defaultValue={formData.name || ''}
										onChange={handleChange}
									/>
								</div>
								{formData.type === 'select' && (
									<div className="form-group mb-3">
										<label htmlFor="defaultValue">Values</label>
										<textarea
											id="defaultValue"
											name="defaultValue"
											className="form-control"
											rows={4}
											disabled={isSaving}
											value={formData.defaultValue || ''}
											onChange={handleChange}
										/>
									</div>
								)}
								<div className="form-group mb-3">
									<label htmlFor="name">Tag</label>
									<input
										type="text"
										id="mergeField"
										name="mergeField"
										className="form-control"
										disabled={true}
										value={mergeFieldValue}
									/>
								</div>
							</div>
						</ModalDialogContent>
						<ModalDialogButtons shaded>
							<AppButton type="submit" children={<span>Save</span>} />
							<AppButton
								className="outline-primary"
								children={<span>Cancel</span>}
								onClick={handleOnCancel}
							/>
							{isEdit && (
								<AppButton
									className="danger"
									children={<span>Delete</span>}
									onClick={deleteCustomField}
								/>
							)}
						</ModalDialogButtons>
					</form>
				) : (
					<AppSpinner />
				)}
			</Fragment>
		</ModalDialog>
	);
};

export default CustomFieldFormDialog;
