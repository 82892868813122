import './ContactListFormDialog.css';
import { useState, useEffect } from 'react';
import AppButton from '../../../../../appButton/AppButton';
import ModalDialog from '../../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import Select from 'react-select';
import {
	AddContactList,
	EditContactList,
	GetContactList,
	DeleteContactList,
	GetContacts
} from '../../../../../../Services';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';
import { toast } from 'react-toastify';
import { Fragment } from 'react';
import AppSpinner from '../../../../../appSpinner/AppSpinner';

const ContactListFormDialog = ({ id, callback, onClose }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ contacts, setContacts ] = useState([]);
	const [ selectedContacts, setSelectedContacts ] = useState([]);
	const [ { phoneNumber, isContactsSearch }, dispatch ] = useStateValue();

	useEffect(() => {
		getContactListDetails();
	}, []);

	const getContactListDetails = async () => {
		getContacts();

		if (id !== undefined && id !== null && id !== '') {
			setIsEdit(true);

			const response = await GetContactList(id);

			if (response !== undefined) {
				setFormData(response);

				let tempSelectedContacts = [];
				if (response.contacts !== undefined && response.contacts.length > 0) {
					for (let i = 0; i < response.contacts.length; i++) {
						let contact = response.contacts[i];

						let label =
							contact.fullName !== null && contact.fullName.trim() !== ''
								? contact.fullName
								: contact.phoneNumberFriendly;

						tempSelectedContacts.push({
							value : contact.id,
							label : label
						});
					}
				}

				setSelectedContacts(tempSelectedContacts);
			}
		}

		setIsLoading(false);
	};

	const getContacts = async (query) => {
		let data = { query: query, isContactsSearch: false };
		const response = await GetContacts(data);

		if (response !== undefined) {
			let tempContacts = [];

			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					let label =
						response[i].fullName !== null && response[i].fullName.trim() !== ''
							? response[i].fullName
							: response[i].phoneNumberFriendly;

					tempContacts.push({ value: response[i].id, label: label });
				}
			}

			setContacts(tempContacts);
		}
	};

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleSelectChange = (option, action) => {
		if (action.name === 'contacts') {
			setSelectedContacts(option);
		}
	};

	const handleOnCancel = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.name) {
			toast.error('Name is required!');
			return;
		}

		setIsSaving(true);

		//prepare contact lists for POST action
		let tempContacts = [];

		if (selectedContacts) {
			for (let i = 0; i < selectedContacts.length; i++) {
				tempContacts.push(selectedContacts[i].value);
			}
		}

		let tempFormData = formData;
		tempFormData.contactIds = tempContacts;
		tempFormData.phoneNumberId = phoneNumber.id;

		let response = null;

		if (isEdit) {
			response = await EditContactList(id, tempFormData);
		} else {
			// let data = {
			// 	name : formData.name
			// };

			response = await AddContactList(tempFormData);
		}

		if (response !== undefined) {
			if (callback) {
				callback();
			}

			if (onClose) {
				onClose();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const deleteContactList = async () => {
		if (window.confirm('Are you sure you want to delete the contact list?')) {
			const response = await DeleteContactList(id);

			if (response !== undefined) {
				if (callback) {
					callback();
				}

				if (onClose) {
					onClose();
				}
			}
		}
	};

	return (
		<ModalDialog position="right-sidebar" onClose={onClose}>
			<Fragment>
				{!isLoading ? (
					<form onSubmit={handleSubmit}>
						<ModalDialogContent>
							<h4 className="mb-3">{`${isEdit ? 'Edit' : 'Add'} Contact List`}</h4>
							<div className="form-inputs">
								{isEdit && (
									<div className="form-group mb-3">
										<label htmlFor="name">Id</label>
										<input
											type="text"
											id="id"
											name="id"
											className="form-control"
											disabled={true}
											defaultValue={formData.id || ''}
										/>
									</div>
								)}

								<div className="form-group mb-3">
									<label htmlFor="name">Name</label>
									<input
										type="text"
										id="name"
										name="name"
										className="form-control"
										disabled={isSaving}
										defaultValue={formData.name || ''}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="contacts">Contacts</label>
									<Select
										name="contacts"
										id="contacts"
										isMulti
										value={selectedContacts}
										isDisabled={isSaving}
										onChange={handleSelectChange}
										options={contacts}
										className="react-select-component"
										classNamePrefix="react-select"
									/>
								</div>
							</div>
						</ModalDialogContent>
						<ModalDialogButtons shaded>
							<AppButton type="submit" children={<span>Save</span>} />
							<AppButton
								className="outline-primary"
								children={<span>Cancel</span>}
								onClick={handleOnCancel}
							/>
							{isEdit && (
								<AppButton
									className="danger"
									children={<span>Delete</span>}
									onClick={deleteContactList}
								/>
							)}
						</ModalDialogButtons>
					</form>
				) : (
					<AppSpinner />
				)}
			</Fragment>
		</ModalDialog>
	);
};

export default ContactListFormDialog;
