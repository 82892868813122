import './AppSpinner.css';
import { Spinner } from 'react-bootstrap';

const AppSpinner = ({ text = '' }) => {
	return (
		<div className="spinner-container">
			<Spinner animation="border" role="status">
				<span className="visually-hidden">{text}</span>
			</Spinner>
		</div>
	);
};

export default AppSpinner;
