import './MediaPlaceholder.css';

const MediaPlaceholder = () => {
	return (
		<div className="media media-placeholder">
			<div className="content placeholder-glow">
				<span className="placeholder col-12" />
			</div>
		</div>
	);
};

export default MediaPlaceholder;
