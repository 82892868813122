import './Footer.css';
import FooterLinks from './footerLinks/FooterLinks';

const Footer = () => {
	return (
		<div className="footer-container">
			<span>{new Date().getFullYear()} MindfulSMS</span>
			<FooterLinks />
		</div>
	);
};

export default Footer;
