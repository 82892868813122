import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import './Inbox.css';
import InboxSidebar from './inboxSidebar/InboxSidebar';
import InboxMessages from './inboxMessages/InboxMessages';
import MainAppContentHeader from '../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import Footer from '../footer/Footer';

const Inbox = () => {
	return (
		<div className="app-inbox-container panel">
			<ScrollableContainer className="inbox-content-container">
				<ScrollableContainerHeader>
					<MainAppContentHeader title="Inbox" />
				</ScrollableContainerHeader>
				<ScrollableContainerContent>
					<InboxSidebar />
					<InboxMessages />
					<Footer />
				</ScrollableContainerContent>
			</ScrollableContainer>
		</div>
	);
};

export default Inbox;
