import { Fragment, useEffect, useState, useCallback } from 'react';
import './WebFormsForm.css';
import { useStateValue } from '../../../../../../StateProvider';
import { actionTypes } from '../../../../../../reducer';
import { toast } from 'react-toastify';
import ScrollableContainer from '../../../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerFooter from '../../../../../scrollableContainer/scrollableContainerFooter/ScrollableContainerFooter';
import { GetWebForm, AddWebForm, EditWebForm, GetContactLists, DeleteWebForm } from '../../../../../../Services';
import WebFormFieldItem from './webFormFieldItem/WebFormFieldItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import AppButton from '../../../../../appButton/AppButton';
import AppLinkGoBack from '../../../../../appLinkGoBack/AppLinkGoBack';

const WebFormsForm = ({ id, onDelete }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();
	const [ formData, setFormData ] = useState({});
	const [ webFormFields, setWebFormFields ] = useState([]);
	const [ contactLists, setContactLists ] = useState([]);
	const [ selectedLists, setSelectedLists ] = useState([]);
	const [ contactListType, setContactListType ] = useState('Automatic');
	const navigate = useNavigate();

	useEffect(
		() => {
			getContactLists();
			getWebForm();
		},
		[ id ]
	);

	const getWebForm = async () => {
		setFormData({ name: '', redirectUrl: '', postUrl: '' });

		if (id) {
			setIsEdit(true);

			const response = await GetWebForm(id);

			if (response) {
				let tempFormData = response;

				//contact lists
				let tempWebFormLists = [];
				if (response.webFormLists !== undefined && response.webFormLists.length > 0) {
					for (let i = 0; i < response.webFormLists.length; i++) {
						tempWebFormLists.push({
							value : response.webFormLists[i].contactList.id,
							label : response.webFormLists[i].contactList.name
						});
					}
				}

				setWebFormFields(tempFormData.webFormFields);
				setSelectedLists(tempWebFormLists);
				setContactListType(tempFormData.contactListType);

				setFormData(tempFormData);
			}
		} else {
			setIsEdit(false);
			//setContactListType('Automatic');
			setSelectedLists([]);

			let tempFields = [];
			tempFields.push({
				id           : null,
				name         : 'PhoneNumber',
				index        : 0,
				type         : 'text',
				label        : 'Phone Number',
				defaultValue : '',
				required     : true,
				cfId         : null
			});

			setWebFormFields(tempFields);
		}

		setIsLoading(false);
	};

	const getContactLists = async (query) => {
		let data = { query: query };
		const response = await GetContactLists(data);

		if (response !== undefined) {
			let tempContactLists = [];
			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					tempContactLists.push({ value: response[i].id, label: response[i].name });
				}
			}
			setContactLists(tempContactLists);
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		let value = e.target.value;

		if (target === 'contactListType') {
			setContactListType(e.target.id.replace('contactListType', ''));
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}
	};

	const handleSelectChange = (option, action) => {
		if (action.name === 'contactLists') {
			setSelectedLists(option);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (formData.name.trim() === '') {
			toast.error('Web form name is required');
			return;
		}

		setIsSaving(true);

		//prepare lists for POST action
		let tempContactLists = [];

		if (selectedLists) {
			for (let i = 0; i < selectedLists.length; i++) {
				tempContactLists.push(selectedLists[i].value);
			}
		}

		//form fields
		let tempWebFormFields = [];

		if (webFormFields) {
			for (let i = 0; i < webFormFields.length; i++) {
				let fieldItem = webFormFields[i];

				if (
					fieldItem.cfId !== undefined &&
					(fieldItem.cfId === '' || fieldItem.cfId === '0' || fieldItem.cfId === 0)
				) {
					fieldItem.cfId = null;
				}

				tempWebFormFields.push(fieldItem);
			}
		}

		let tempFormData = formData;
		tempFormData.userId = user.id;
		tempFormData.contactListType = contactListType;
		tempFormData.listIds = tempContactLists;
		tempFormData.webFormFields = tempWebFormFields;

		if (
			tempFormData.redirectUrl &&
			tempFormData.redirectUrl.indexOf('http://') === -1 &&
			tempFormData.redirectUrl.indexOf('https://') === -1
		) {
			tempFormData.redirectUrl = 'https://' + tempFormData.redirectUrl;
		}

		if (
			tempFormData.postUrl &&
			tempFormData.postUrl.indexOf('http://') === -1 &&
			tempFormData.postUrl.indexOf('https://') === -1
		) {
			tempFormData.postUrl = 'https://' + tempFormData.postUrl;
		}

		let response = null;

		if (id) {
			response = await EditWebForm(id, tempFormData);
		} else {
			response = await AddWebForm(tempFormData);
		}

		if (response !== undefined) {
			dispatch({
				type           : actionTypes.RELOAD_WEBFORMS,
				reloadWebForms : true
			});

			if (!isEdit) {
				navigate('/tools/webforms/' + response);
			}
		} else {
			//toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const addNewField = () => {
		let tempFields = webFormFields.map((x) => x);
		tempFields.push({
			id           : null,
			name         : '',
			index        : webFormFields.length,
			type         : 'text',
			label        : '',
			defaultValue : '',
			cfId         : null,
			required     : false
		});

		setWebFormFields(tempFields);
	};

	const deleteWebForm = async () => {
		if (window.confirm('Are you sure you want to delete the web form?')) {
			const response = await DeleteWebForm(id);

			if (response !== undefined) {
				if (onDelete) {
					onDelete();
				}

				dispatch({
					type           : actionTypes.RELOAD_WEBFORMS,
					reloadWebForms : true
				});

				navigate('/tools/webforms');
			}
		}
	};

	const moveItem = useCallback(
		(dragIndex, hoverIndex) => {
			const dragItem = webFormFields[dragIndex];
			const hoverItem = webFormFields[hoverIndex];

			// Swap places of dragItem and hoverItem in the webFormFields array
			setWebFormFields((webFormFields) => {
				const updatedWebFormFields = [ ...webFormFields ];
				updatedWebFormFields[dragIndex] = hoverItem;
				updatedWebFormFields[hoverIndex] = dragItem;

				//update field index
				updatedWebFormFields[hoverIndex].index = hoverIndex;
				updatedWebFormFields[dragIndex].index = dragIndex;

				return updatedWebFormFields;
			});
		},
		[ webFormFields ]
	);

	const deleteItem = useCallback(
		(index) => {
			setWebFormFields((webFormFields) => {
				const item = webFormFields[index];
				const updatedWebFormFields = [ ...webFormFields ];

				if (item.id === null || item.id === undefined || item.id === '') {
					return updatedWebFormFields.filter((x) => x.index !== index);
				} else {
					updatedWebFormFields[index].deleted = true;
					return updatedWebFormFields;
				}
			});
		},
		[ webFormFields ]
	);

	return (
		<Fragment>
			{!isLoading ? (
				<ScrollableContainer className="webforms-form-container">
					<ScrollableContainerHeader>
						<div className="form-header-title row mx-0">
							<div className="col-9 ps-0">
								<AppLinkGoBack redirect="/tools/webforms" />
								<span>{isEdit ? 'Web Form Details' : 'Create Web Form'}</span>
							</div>
						</div>
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<form>
							<div className="form-inputs">
								<div className="form-group mb-3">
									<label htmlFor="name">Web Form Name</label>
									<input
										type="text"
										id="name"
										name="name"
										className="form-control"
										value={formData.name || ''}
										disabled={isLoading || isSaving}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="contactListType">Contact Lists</label>
									<div className="form-check">
										<input
											type="radio"
											id="contactListTypeAutomatic"
											name="contactListType"
											className="form-check-input"
											onChange={handleChange}
											disabled={isLoading || isSaving}
											defaultChecked={contactListType === 'Automatic'}
										/>
										<label htmlFor="contactListTypeAutomatic" className="form-check-label">
											Automatically push contacts into a list
										</label>
									</div>
									{contactListType === 'Automatic' && (
										<div className="form-group mb-3">
											<label htmlFor="contactLists">Send To</label>
											<Select
												name="contactLists"
												id="contactLists"
												isMulti={true}
												value={selectedLists}
												isDisabled={isLoading || isSaving}
												onChange={handleSelectChange}
												options={contactLists}
												className="react-select-component"
												classNamePrefix="react-select"
											/>
										</div>
									)}
									<div className="form-check mt-2">
										<input
											type="radio"
											id="contactListTypePrompt"
											name="contactListType"
											className="form-check-input"
											onChange={handleChange}
											disabled={isLoading || isSaving}
											defaultChecked={contactListType === 'Prompt'}
										/>
										<label htmlFor="contactListTypePrompt" className="form-check-label">
											Prompt contacts to join a list
										</label>
									</div>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="name">Fields</label>
									{webFormFields &&
									webFormFields.length > 0 && (
										<DndProvider backend={HTML5Backend}>
											{webFormFields.map((field, index) => (
												<WebFormFieldItem
													field={field}
													index={index}
													onChange={handleChange}
													onMoveItem={moveItem}
													onDeleteItem={deleteItem}
													key={field.index}
												/>
											))}
										</DndProvider>
									)}
									<AppButton onClick={addNewField} children={<span>Add New Field</span>} />
								</div>
								<div className="form-group mb-3">
									<label htmlFor="redirectUrl">Submit Redirect Url</label>
									<input
										type="text"
										id="redirectUrl"
										name="redirectUrl"
										className="form-control"
										placeholder="https://www.example.com"
										value={formData.redirectUrl || ''}
										disabled={isLoading || isSaving}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="postUrl">Opt-in HTTP Post Url</label>
									<input
										type="text"
										id="postUrl"
										name="postUrl"
										className="form-control"
										placeholder="https://www.example.com"
										value={formData.postUrl || ''}
										disabled={isLoading || isSaving}
										onChange={handleChange}
									/>
								</div>
							</div>
						</form>
					</ScrollableContainerContent>
					<ScrollableContainerFooter>
						<div className="container-buttons">
							<button
								type="button"
								className="btn btn-primary "
								onClick={handleSubmit}
								disabled={isLoading || isSaving}
							>
								{!isSaving ? (
									<Fragment>
										<span>Save</span>
									</Fragment>
								) : (
									<Fragment>
										{/* <Spinner /> */}
										<span>Saving...</span>
									</Fragment>
								)}
							</button>
							{isEdit && (
								<AppButton className="danger" children={<span>Delete</span>} onClick={deleteWebForm} />
							)}
						</div>
					</ScrollableContainerFooter>
				</ScrollableContainer>
			) : (
				<div className="text-center p-3">{/* <Spinner /> */}</div>
			)}
		</Fragment>
	);
};

export default WebFormsForm;
