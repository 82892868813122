import { useState, useEffect, Fragment } from 'react';
import InboxSidebarHeaderPlaceholder from '../../../placeholders/inboxSidebarHeaderPlaceholder/InboxSidebarHeaderPlaceholder';
import MessageThreadPlaceholder from '../../../placeholders/messageThreadPlaceholder/MessageThreadPlaceholder';
import MessageThreadsList from '../messageThreads/messageThreadsList/MessageThreadsList';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import InboxSidebarHeader from './InboxSidebarHeader/InboxSidebarHeader';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import { GetMessageThreads } from '../../../../Services';
import { isMobile } from '../../../../common';
import './InboxSidebar.css';

const InboxSidebar = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ messageThreads, setMessageThreads ] = useState([]);
	const [
		{ selectedThread, isNewThread, newMessageSent, reloadInbox, searchQuery, searchInboxType },
		dispatch
	] = useStateValue();

	useEffect(() => {
		dispatch({
			type        : actionTypes.SEARCH_QUERY,
			searchQuery : null
		});
		dispatch({
			type            : actionTypes.SEARCH_INBOX_TYPE,
			searchInboxType : null
		});
	}, []);

	useEffect(
		() => {
			getMessageThreads(searchQuery, searchInboxType);

			let threadsInterval = setInterval(() => {
				getMessageThreads(searchQuery, searchInboxType);
			}, 2000);

			return () => {
				clearInterval(threadsInterval);
			};
		},
		[ searchQuery, searchInboxType ]
	);

	const getMessageThreads = async (query, inboxType) => {
		let data = { query: query, inboxType: inboxType };
		const threads = await GetMessageThreads(data);

		if (threads) {
			setMessageThreads(threads);
		}

		setIsLoading(false);
	};

	useEffect(
		() => {
			if (selectedThread === null) {
				if (!isMobile()) {
					//select first thread only if its not mobile view
					dispatch({
						type           : actionTypes.SELECTED_THREAD,
						selectedThread : messageThreads && messageThreads.length > 0 ? messageThreads[0] : null
					});
				}
			} else {
				dispatch({
					type        : actionTypes.RELOAD_INBOX,
					reloadInbox : true
				});
				// if (messageThreads && messageThreads.length > 0) {
				// 	//reload selected thread
				// 	let tempSelectedThread = messageThreads.find((x) => x.id === selectedThread.id);
				// 	if (tempSelectedThread) {
				// 		dispatch({
				// 			type           : actionTypes.SELECTED_THREAD,
				// 			selectedThread : tempSelectedThread
				// 		});
				// 	}
				// }
			}
		},
		[ messageThreads ]
	);

	useEffect(
		() => {
			if (newMessageSent) {
				dispatch({
					type           : actionTypes.NEW_MESSAGE_SENT,
					newMessageSent : false
				});
			}
		},
		[ newMessageSent ]
	);

	return (
		<Fragment>
			{isMobile() && (selectedThread || isNewThread) ? (
				<div />
			) : (
				<ScrollableContainer className="inbox-message-threads-container">
					{isLoading ? (
						<Fragment>
							<ScrollableContainerHeader>
								<InboxSidebarHeaderPlaceholder />
							</ScrollableContainerHeader>
							<ScrollableContainerContent>
								<MessageThreadPlaceholder />
								<MessageThreadPlaceholder />
								<MessageThreadPlaceholder />
								<MessageThreadPlaceholder />
								<MessageThreadPlaceholder />
							</ScrollableContainerContent>
						</Fragment>
					) : (
						<Fragment>
							<ScrollableContainerHeader>
								<InboxSidebarHeader threads={messageThreads} callback={getMessageThreads} />
							</ScrollableContainerHeader>
							<ScrollableContainerContent>
								<MessageThreadsList threads={messageThreads} />
							</ScrollableContainerContent>
						</Fragment>
					)}
				</ScrollableContainer>
			)}
		</Fragment>
	);
};

export default InboxSidebar;
