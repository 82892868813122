import { Link } from 'react-router-dom';
import './Logo.css';

const Logo = ({ collapsed }) => {
	return (
		<Link to="/">
			<div className={`app-logo ${collapsed ? 'small' : ''}`}>
				<img />
			</div>
		</Link>
	);
};

export default Logo;
