import './ContactsExportDialog.css';
import AppButton from '../../../../appButton/AppButton';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { Link } from 'react-router-dom';

const ContactsExportDialog = ({ url, onClose }) => {
	const handleOnCancel = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<ModalDialog>
			<ModalDialogContent>
				<h4 className="mb-3">Export details</h4>
				<div className="form-inputs">
					<div className="form-group mb-3">
						<label htmlFor="name">Export process completed successfully!</label>
						<div>
							<Link to={url} target="_blank">
								<span>Download link</span>
							</Link>
						</div>
					</div>
				</div>
			</ModalDialogContent>
			<ModalDialogButtons shaded>
				<AppButton className="outline-primary" children={<span>Close</span>} onClick={handleOnCancel} />
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default ContactsExportDialog;
