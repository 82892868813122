import { useState, useEffect } from 'react';
import AppButton from '../../../../../appButton/AppButton';
import SearchListInput from '../../../../searchListInput/SearchListInput';
import CustomFieldFormDialog from '../../contactsFormDialog/customFields/CustomFieldFormDialog';
import './CustomFieldsHeader.css';

const CustomFieldsHeader = ({ callback }) => {
	const [ showCustomFieldDetailsDialog, setShowCustomFieldDetailsDialog ] = useState(false);

	const toggleCustomFieldDetailsDialog = () => {
		setShowCustomFieldDetailsDialog((prev) => !prev);
	};

	return (
		<div className="custom-fields-content-header-container">
			<div className="custom-fields-content-header-search">
				<SearchListInput callback={callback} />
			</div>
			<div className="custom-fields-content-header-add-button">
				<AppButton
					className="primary btn-lg"
					children={<span>Add Custom Field</span>}
					onClick={toggleCustomFieldDetailsDialog}
				/>
			</div>
			{/* <h5 className="custom-fields-list-title">Custom Fields</h5> */}

			{showCustomFieldDetailsDialog && (
				<CustomFieldFormDialog onClose={toggleCustomFieldDetailsDialog} callback={callback} />
			)}
		</div>
	);
};

export default CustomFieldsHeader;
