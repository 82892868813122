import { useState, useEffect, Fragment } from 'react';
import MessagePlaceholder from '../../../placeholders/messagePlaceholder/MessagePlaceholder';
import MessagesList from './inboxMessagesContent/messagesList/MessagesList';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerFooter from '../../../scrollableContainer/scrollableContainerFooter/ScrollableContainerFooter';
import InboxMessagesHeader from './inboxMessagesHeader/InboxMessagesHeader';
import InboxMessagesFooter from './inboxMessagesFooter/InboxMessagesFooter';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import './InboxMessages.css';
import { GetThreadMessages } from '../../../../Services';
import ContactFormSidebar from '../../contacts/contactsContentArea/contactsFormDialog/contacts/ContactFormSidebar';
import ContactFormDialog from '../../contacts/contactsContentArea/contactsFormDialog/contacts/ContactFormDialog';
import { isMobile } from '../../../../common';

const InboxMessages = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ messagesList, setMessagesList ] = useState([]);
	const [ contactId, setContactId ] = useState(0);
	const [ showContactDetailsSidebar, setShowContactDetailsSidebar ] = useState(false);
	const [ { isNewThread, selectedThread, reloadInbox }, dispatch ] = useStateValue();

	useEffect(
		() => {
			getThreadMessages();

			if (selectedThread) {
				setContactId(selectedThread.contactId);
			}
		},
		[ selectedThread ]
	);

	useEffect(
		() => {
			if (reloadInbox) {
				getThreadMessages();

				dispatch({
					type        : actionTypes.RELOAD_INBOX,
					reloadInbox : false
				});
			}
		},
		[ reloadInbox ]
	);

	const getThreadMessages = async () => {
		if (selectedThread) {
			const tempMessageList = await GetThreadMessages(selectedThread.id);

			if (tempMessageList) {
				//order by date
				tempMessageList.sort((a, b) => {
					let dateA = new Date(a.dateCreated),
						dateB = new Date(b.dateCreated);
					return dateA - dateB;
				});
			}

			setMessagesList(tempMessageList);
		} else {
			setMessagesList({});
		}

		setIsLoading(false);
	};

	const handleContactSelect = (e) => {
		setShowContactDetailsSidebar((prev) => !prev);

		var selectedContactId = selectedThread.contact.id;
		setContactId(selectedContactId);
	};

	const toggleContactDetailsSidebar = () => {
		setShowContactDetailsSidebar((prev) => !prev);
	};

	return (
		<Fragment>
			{isMobile() && (!selectedThread && !isNewThread) ? (
				<div />
			) : (
				<Fragment>
					<ScrollableContainer
						className={`inbox-content-messages-container ${showContactDetailsSidebar
							? 'contact-view'
							: ''}`}
					>
						<ScrollableContainer className="inbox-messages-container">
							<ScrollableContainerHeader>
								<InboxMessagesHeader
									onNewContact={getThreadMessages}
									onContactSelect={handleContactSelect}
								/>
							</ScrollableContainerHeader>
							{isLoading && (
								<Fragment>
									<ScrollableContainerContent>
										<MessagePlaceholder />
										<MessagePlaceholder />
										<MessagePlaceholder />
										<MessagePlaceholder />
										<MessagePlaceholder />
									</ScrollableContainerContent>
								</Fragment>
							)}
							<MessagesList messages={isNewThread ? [] : messagesList} />
							<ScrollableContainerFooter>
								<InboxMessagesFooter callback={getThreadMessages} />
							</ScrollableContainerFooter>
						</ScrollableContainer>
					</ScrollableContainer>
					{showContactDetailsSidebar && (
						<ScrollableContainer className="inbox-contact-container">
							<ScrollableContainerContent>
								{isMobile() ? (
									<ContactFormDialog id={contactId} onClose={toggleContactDetailsSidebar} />
								) : (
									<ContactFormSidebar id={contactId} onClose={toggleContactDetailsSidebar} />
								)}
							</ScrollableContainerContent>
						</ScrollableContainer>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default InboxMessages;
