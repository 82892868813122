import './CustomContentPlaceholder.css';

const CustomContentPlaceholder = () => {
	return (
		<div className="customcontent customcontent-placeholder">
			<div className="content placeholder-glow">
				<span className="placeholder placeholder-lg col-8" />
				<span className="placeholder placeholder-lg col-4" />
			</div>
		</div>
	);
};

export default CustomContentPlaceholder;
