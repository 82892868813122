import { Fragment } from 'react';
import './ModalDialog.css';
import SidebarCloseButton from '../sidebarCloseButton/SidebarCloseButton';

const ModalDialog = ({ children, position = 'default', onClose, onSidebarCloseRedirect }) => {
	return (
		<Fragment>
			<div className="modal-background" />
			<div className={`modal-dialog-container ${position === 'default' ? '' : position}`}>
				{/* {position === 'right-sidebar' && (
					<SidebarCloseButton onSidebarClose={onClose} redirect={onSidebarCloseRedirect} />
				)} */}
				<div className="modal-dialog shadow-large">
					{
						<Fragment>
							{position === 'right-sidebar' && (
								<SidebarCloseButton onSidebarClose={onClose} redirect={onSidebarCloseRedirect} />
							)}
							{children}
						</Fragment>
					}
				</div>
			</div>
		</Fragment>
	);
};

export default ModalDialog;
