import { Fragment, useEffect, useState, useRef } from 'react';
import './AutorespondersForm.css';
import AppButton from '../../../../../appButton/AppButton';
import { useStateValue } from '../../../../../../StateProvider';
import { actionTypes } from '../../../../../../reducer';
import { toast } from 'react-toastify';
import ScrollableContainer from '../../../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerFooter from '../../../../../scrollableContainer/scrollableContainerFooter/ScrollableContainerFooter';
import {
	GetAutoresponder,
	AddAutoresponder,
	GetContactLists,
	EditAutoresponder,
	DeleteAutoresponder,
	GetCustomFields
} from '../../../../../../Services';
import { ButtonGroup, Button } from 'react-bootstrap';
import FileUploadDialog from '../../../../fileUpload/FileUploadDialog';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import AppLinkGoBack from '../../../../../appLinkGoBack/AppLinkGoBack';

const AutorespondersForm = ({ id, onDelete }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ { user, phoneNumber, selectedMedia }, dispatch ] = useStateValue();
	const [ formData, setFormData ] = useState({ customFieldId: null });
	const [ autoresponder, setAutoresponder ] = useState({});
	const [ showUploadFilesDialog, setShowUploadFilesDialog ] = useState(false);
	const [ contactLists, setContactLists ] = useState([]);
	const [ selectedAutoresponderLists, setSelectedAutoresponderLists ] = useState([]);
	const [ selectedSuppressionListId, setSelectedSuppressionListId ] = useState([]);
	const [ autoresponderType, setAutoresponderType ] = useState('');
	const [ messageType, setMessageType ] = useState('');
	const messageTypeSMSRef = useRef();
	const messageTypeMMSRef = useRef();
	const [ clickedButton, setClickedButton ] = useState('last7Days');
	const [ startDate, setStartDate ] = useState(null);
	const [ endDate, setEndDate ] = useState(null);
	const [ customFields, setCustomFields ] = useState([]);
	const startDateRef = useRef();
	const endDateRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(
		() => {
			getAutoresponder();
		},
		[ location, startDate, endDate ]
	);

	useEffect(
		() => {
			setClickedButton('last7Days');
		},
		[ location ]
	);

	const getAutoresponder = async () => {
		let tempContactLists = await getContactLists();
		let tempCustomFields = await getCustomFields();

		if (id) {
			setIsEdit(true);

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});

			let tempStartDate = startDate;
			let tempEndDate = endDate;
			let date = new Date();

			if (!tempStartDate || tempStartDate === null) {
				tempStartDate = new Date(date.setDate(date.getDate() - 7)).toISOString();
			}

			if (!tempEndDate || tempEndDate === null) {
				tempEndDate = new Date().toISOString();
			}

			let data = {
				startDate : tempStartDate,
				endDate   : tempEndDate
			};

			const response = await GetAutoresponder(id, data);

			if (response && response.error) {
				toast.error('Could not get drip campaign details');
				setIsLoading(false);

				return;
			}

			if (response) {
				let tempFormData = response;
				setAutoresponder(response);

				//contact lists
				let tempAutoresponderLists = [];
				if (
					response.autoresponderLists &&
					response.autoresponderLists !== null &&
					response.autoresponderLists.length > 0
				) {
					for (let i = 0; i < response.autoresponderLists.length; i++) {
						tempAutoresponderLists.push({
							value : response.autoresponderLists[i].contactList.id,
							label : response.autoresponderLists[i].contactList.name
						});
					}
				}

				//suppression list
				let tempSuppressionListId = [];
				let suppressionListIdItem = tempContactLists.find((x) => x.value === response.suppressionListId);
				if (suppressionListIdItem) {
					tempSuppressionListId.push({
						value : suppressionListIdItem.value,
						label : suppressionListIdItem.label
					});
				}

				//attachments
				let tempAutoresponderAttachments = [];
				if (
					response.autoresponderAttachments &&
					response.autoresponderAttachments !== null &&
					response.autoresponderAttachments.length > 0
				) {
					for (let i = 0; i < response.autoresponderAttachments.length; i++) {
						//tempAutoresponderAttachments.push(response.autoresponderAttachments[i].media);
						tempAutoresponderAttachments.push({
							id   : response.autoresponderAttachments[i].media.id,
							name : response.autoresponderAttachments[i].media.name,
							url  : response.autoresponderAttachments[i].media.mediaUrl
						});
					}
				}

				setSelectedAutoresponderLists(tempAutoresponderLists);
				setSelectedSuppressionListId(tempSuppressionListId);
				setAutoresponderType(tempFormData.type);
				setMessageType(tempFormData.messageType);

				dispatch({
					type          : actionTypes.SELECTED_MEDIA,
					selectedMedia : tempAutoresponderAttachments
				});

				if (tempCustomFields && tempCustomFields.length > 0) {
					if (!tempFormData.customFieldId) {
						tempFormData.customFieldId = tempCustomFields[0].id;
					} else {
						//if selected custom field doesn't exist anymore
						let cfExistsInList = tempCustomFields.some((obj) => obj.id === tempFormData.customFieldId);

						if (!cfExistsInList) {
							tempFormData.customFieldId = tempCustomFields[0].id;
						}
					}
				}

				setFormData(tempFormData);
			}
		} else {
			setFormData({
				name            : '',
				body            : '',
				fallbackBody    : '',
				delayInterval   : 'Minutes',
				delayAmount     : 0,
				customTimeFrame : 'Before',
				customFieldId   : tempCustomFields && tempCustomFields.length > 0 ? tempCustomFields[0].id : null
			});

			setIsEdit(false);
			setAutoresponderType('Immediate');
			setMessageType('SMS');
			setSelectedAutoresponderLists([]);
			setSelectedSuppressionListId([]);

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});
		}

		setIsLoading(false);
	};

	const getContactLists = async (query) => {
		let data = { query: query };
		const response = await GetContactLists(data);

		if (response !== undefined) {
			let tempContactLists = [];
			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					tempContactLists.push({ value: response[i].id, label: response[i].name });
				}
			}
			setContactLists(tempContactLists);

			return tempContactLists;
		}
	};

	const getCustomFields = async () => {
		let data = { query: null };
		const response = await GetCustomFields(data);

		if (response !== undefined) {
			//show default first
			response.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));

			//show only custom fileds with type of date or datetime
			let dateCustomFields = response.filter((x) => x.type === 'datetime');

			setCustomFields(dateCustomFields);

			return dateCustomFields;
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		let value = e.target.value;

		if (target === 'messageType') {
			setMessageType(value);
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}
	};

	const handleDateRangeChange = (e) => {
		let selectedStartDate = startDateRef.current.value;
		let selectedEndDate = endDateRef.current.value;

		if (selectedStartDate && selectedEndDate) {
			setStartDate(new Date(selectedStartDate).toISOString());
			setEndDate(new Date(selectedEndDate).toISOString());
		}
	};

	const handleSelectChange = (option, action) => {
		if (action.name === 'autoresponderLists') {
			setSelectedAutoresponderLists(option);
		} else if (action.name === 'suppressionListId') {
			setSelectedSuppressionListId({
				value : option.value,
				label : option.label
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (formData.name.trim() === '') {
			toast.error('Drip campaign name is required');
			return;
		}

		if (autoresponderType === 'Custom' && (!formData.customFieldId || formData.customFieldId == '')) {
			toast.error('Custom field value is required');
			return;
		}

		setIsSaving(true);

		//prepare autoresponder lists for POST action
		let tempAutoresponderLists = [];

		if (selectedAutoresponderLists) {
			for (let i = 0; i < selectedAutoresponderLists.length; i++) {
				tempAutoresponderLists.push(selectedAutoresponderLists[i].value);
			}
		}

		//prepare attachments
		var tempSelectedMedia = [];

		if (selectedMedia !== null && selectedMedia.length > 0) {
			for (let i = 0; i < selectedMedia.length; i++) {
				tempSelectedMedia.push(selectedMedia[i].id);
			}
		}

		let tempFormData = formData;
		tempFormData.userId = user.id;
		tempFormData.phoneNumberId = phoneNumber.id;
		tempFormData.messageType = messageType;
		tempFormData.type = autoresponderType;
		tempFormData.listIds = tempAutoresponderLists;
		tempFormData.attachmentIds = tempSelectedMedia;
		tempFormData.suppressionListId = selectedSuppressionListId.value;

		let response = null;

		if (id) {
			response = await EditAutoresponder(id, tempFormData);
		} else {
			tempFormData.status = 'Active';

			response = await AddAutoresponder(tempFormData);
		}

		if (response && response.error) {
			toast.error(response.error);
			setIsSaving(false);

			return;
		}

		if (response !== undefined) {
			dispatch({
				type                 : actionTypes.RELOAD_AUTORESPONDERS,
				reloadAutoresponders : true
			});

			if (!isEdit) {
				navigate('/tools/autoresponders/' + response);
			}
		} else {
			//toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const removeAttachment = (e) => {
		const selectedAttachment = e.currentTarget;
		var id = selectedAttachment.getAttribute('data-id');

		var tempSelectedMediaItems = selectedMedia;

		tempSelectedMediaItems = tempSelectedMediaItems.filter((obj) => obj.id !== id);

		dispatch({
			type          : actionTypes.SELECTED_MEDIA,
			selectedMedia : tempSelectedMediaItems
		});
	};

	const toggleUploadFilesDialog = () => {
		setShowUploadFilesDialog((prev) => !prev);
	};

	const onMessageTypeSMSClick = () => {
		messageTypeSMSRef.current.classList.remove('btn-outline-primary');
		messageTypeSMSRef.current.classList.add('btn-primary');
		messageTypeMMSRef.current.classList.remove('btn-primary');
		messageTypeMMSRef.current.classList.add('btn-outline-primary');
		setMessageType('SMS');
	};

	const onMessageTypeMMSClick = () => {
		messageTypeSMSRef.current.classList.remove('btn-primary');
		messageTypeSMSRef.current.classList.add('btn-outline-primary');
		messageTypeMMSRef.current.classList.remove('btn-outline-primary');
		messageTypeMMSRef.current.classList.add('btn-primary');
		setMessageType('MMS');
	};

	const onAutoresponderTypeImmediateClick = () => {
		setAutoresponderType('Immediate');
	};

	const onAutoresponderTypeScheduleClick = () => {
		setAutoresponderType('Delay');
	};

	const onAutoresponderTypeCustomClick = () => {
		setAutoresponderType('Custom');
	};

	const deleteAutoresponder = async () => {
		if (window.confirm('Are you sure you want to delete the drip campaign?')) {
			const response = await DeleteAutoresponder(id);

			if (response !== undefined) {
				if (onDelete) {
					onDelete();
				}

				dispatch({
					type                 : actionTypes.RELOAD_AUTORESPONDERS,
					reloadAutoresponders : true
				});

				navigate('/tools/autoresponders');
			}
		}
	};

	const handleStatsButtonClick = (e) => {
		const value = e.target.value;
		let date = new Date();

		setClickedButton(value);

		switch (value) {
			case 'last7Days':
				setStartDate(new Date(date.setDate(date.getDate() - 7)).toISOString());
				setEndDate(new Date().toISOString());
				getAutoresponder();
				break;
			case 'last30Days':
				setStartDate(new Date(date.setDate(date.getDate() - 30)).toISOString());
				setEndDate(new Date().toISOString());
				getAutoresponder();
				break;
			case 'custom':
				break;
		}
	};

	return (
		<Fragment>
			{!isLoading ? (
				<ScrollableContainer className="autoresponders-form-container">
					<ScrollableContainerHeader>
						<div className="form-header-title row mx-0">
							<div className="col-12 ps-0">
								<AppLinkGoBack redirect="/tools/autoresponders" />
								<span>{isEdit ? 'Drip Campaign Details' : 'Create Drip Campaign'}</span>
							</div>
						</div>
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<form>
							<div className="form-inputs">
								{autoresponder &&
								autoresponder.stats && (
									<div className="form-group mb-4">
										<div className="stats-wrapper mb-3">
											<div className="row">
												<div className="col-auto pe-4">
													<ButtonGroup
														aria-label="Date Range"
														onClick={handleStatsButtonClick}
													>
														<Button
															variant={`${clickedButton === 'last7Days'
																? 'primary'
																: 'outline-primary'}`}
															value="last7Days"
														>
															Last 7 Days
														</Button>
														<Button
															variant={`${clickedButton === 'last30Days'
																? 'primary'
																: 'outline-primary'}`}
															value="last30Days"
														>
															Last 30 Days
														</Button>
														<Button
															variant={`${clickedButton === 'custom'
																? 'primary'
																: 'outline-primary'}`}
															value="custom"
														>
															Custom
														</Button>
													</ButtonGroup>
												</div>
												{clickedButton === 'custom' && (
													<Fragment>
														<div className="col-auto mt-2 pe-0">
															<span>From: </span>
														</div>
														<div className="col-auto">
															<input
																type="date"
																id="startDate"
																ref={startDateRef}
																name="startDate"
																className="form-control w-auto"
																onInput={handleDateRangeChange}
															/>
														</div>
														<div className="col-auto mt-2 ms-4 px-0">
															<span>To: </span>
														</div>
														<div className="col-auto">
															<input
																type="date"
																id="endDate"
																name="endDate"
																ref={endDateRef}
																className="form-control w-auto"
																onInput={handleDateRangeChange}
															/>
														</div>
													</Fragment>
												)}
											</div>
										</div>
										<div className="stats-wrapper">
											<div className="stats-item">
												<div className="stats-item-title">
													<span>Sent</span>
												</div>
												<div className="stats-item-value">
													<span>{autoresponder.stats.sent || 0}</span>
												</div>
											</div>
											<div className="stats-item">
												<div className="stats-item-title">
													<span>Undelivered</span>
												</div>
												<div className="stats-item-value">
													<span>{autoresponder.stats.undelivered || 0}</span>
												</div>
											</div>
											<div className="stats-item">
												<div className="stats-item-title">
													<span>Unsubscribes</span>
												</div>
												<div className="stats-item-value">
													<span>{autoresponder.stats.unsubscribes || 0}</span>
												</div>
											</div>
											<div className="stats-item">
												<div className="stats-item-title">
													<span>Success Rate</span>
												</div>
												<div className="stats-item-value">
													<span>{`${autoresponder.stats.successRate}%` || 0}</span>
												</div>
											</div>
										</div>
									</div>
								)}
								<div className="form-group mb-3">
									<label htmlFor="name">Drip Campaign Name</label>
									<input
										type="text"
										id="name"
										name="name"
										className="form-control"
										value={formData.name}
										disabled={isLoading || isSaving}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="autoresponderLists">Send To</label>
									<Select
										name="autoresponderLists"
										id="autoresponderLists"
										isMulti={true}
										value={selectedAutoresponderLists}
										isDisabled={isLoading || isSaving}
										onChange={handleSelectChange}
										options={contactLists}
										className="react-select-component"
										classNamePrefix="react-select"
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="suppressionListId">Suppression List</label>
									<Select
										name="suppressionListId"
										id="suppressionListId"
										isMulti={false}
										value={selectedSuppressionListId}
										isDisabled={isLoading || isSaving}
										onChange={handleSelectChange}
										options={contactLists}
										className="react-select-component"
										classNamePrefix="react-select"
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="messageType">Message Type</label>
									<div className="btn-group d-block" role="group" aria-label="Message Type">
										<button
											type="button"
											className={
												messageType === '' || messageType === 'SMS' ? (
													'btn btn-primary'
												) : (
													'btn btn-outline-primary'
												)
											}
											ref={messageTypeSMSRef}
											onClick={onMessageTypeSMSClick}
											disabled={isLoading || isSaving}
										>
											SMS
										</button>
										<button
											type="button"
											className={
												messageType === 'MMS' ? 'btn btn-primary' : 'btn btn-outline-primary'
											}
											ref={messageTypeMMSRef}
											onClick={onMessageTypeMMSClick}
											disabled={isLoading || isSaving}
										>
											MMS
										</button>
									</div>
								</div>
								<div className="form-group mb-3 message-body">
									<label htmlFor="message">Message</label>
									<textarea
										id="body"
										name="body"
										rows="3"
										className="form-control"
										placeholder="Write Your Message"
										value={formData.body}
										onChange={handleChange}
										disabled={isLoading || isSaving}
									/>
									{selectedMedia !== null &&
									selectedMedia.length > 0 && (
										<div className="send-message-attachments-area">
											{selectedMedia.map((mediaItem) => (
												<div className="attachment-item" key={mediaItem.id} id={mediaItem.id}>
													<Link to={mediaItem.url} target="_blank">
														<span>{mediaItem.name}</span>
													</Link>
													<button
														className="btn btn-link attachment-item-remove"
														data-id={mediaItem.id}
														onClick={removeAttachment}
														disabled={isLoading || isSaving}
													>
														<i className="fa-solid fa-xmark p-0 m-0" />
													</button>
												</div>
											))}
										</div>
									)}
									<div className="send-message-actions">
										<div className="send-message-action">
											<AppButton
												className="link"
												children={<i className="fa-solid fa-paperclip mx-2" />}
												onClick={toggleUploadFilesDialog}
												disabled={isLoading || isSaving}
											/>
										</div>
									</div>
								</div>
								{messageType === 'MMS' && (
									<div className="form-group mb-3">
										<label htmlFor="fallbackBody">SMS Fallback</label>
										<textarea
											id="fallbackBody"
											name="fallbackBody"
											rows="3"
											className="form-control"
											placeholder="Write Your Message"
											onChange={handleChange}
											value={formData.fallbackBody}
											disabled={isLoading || isSaving}
										/>
									</div>
								)}
								<div className="form-group mb-3 autoresponder-type-field">
									<span>Sending Options</span>
								</div>
								<div className="form-group mb-3">
									<div className="btn-group d-block" role="group" aria-label="Drip Campaign Type">
										<button
											type="button"
											className={
												(autoresponderType === 'Immediate'
													? 'btn btn-primary'
													: 'btn btn-outline-primary') + ' btn-autoresponder-type'
											}
											// ref={autoresponderTypeImmediateRef}
											onClick={onAutoresponderTypeImmediateClick}
											disabled={isLoading || isSaving}
										>
											Immediately After Opt-in
										</button>
										<button
											type="button"
											className={
												(autoresponderType === 'Delay'
													? 'btn btn-primary'
													: 'btn btn-outline-primary') + ' btn-autoresponder-type'
											}
											// ref={autoresponderTypeScheduleRef}
											onClick={onAutoresponderTypeScheduleClick}
											disabled={isLoading || isSaving}
										>
											After Delay
										</button>
										<button
											type="button"
											className={
												(autoresponderType === 'Custom'
													? 'btn btn-primary'
													: 'btn btn-outline-primary') + ' btn-autoresponder-type'
											}
											// ref={autoresponderTypeCustomRef}
											onClick={onAutoresponderTypeCustomClick}
											disabled={isLoading || isSaving}
										>
											Custom
										</button>
									</div>
								</div>
								{(autoresponderType === 'Delay' || autoresponderType === 'Custom') && (
									<div className="form-group mb-3 input-group">
										<label htmlFor="delayInterval" />
										{autoresponderType === 'Custom' && (
											<div className="col-lg-2 col-md-4 px-0 me-5">
												<select
													id="customFieldId"
													name="customFieldId"
													className="form-select"
													disabled={isLoading || isSaving}
													value={formData.customFieldId || 0}
													onChange={handleChange}
												>
													{customFields &&
														customFields.map((customField) => (
															<option value={customField.id} key={customField.id}>
																{customField.name}
															</option>
														))}
												</select>
											</div>
										)}
										<div className="col-lg-1 col-md-2 px-0 me-2">
											<input
												type="number"
												id="delayAmount"
												name="delayAmount"
												className="form-control"
												disabled={isLoading || isSaving}
												value={formData.delayAmount || 0}
												onChange={handleChange}
											/>
										</div>
										<div className="col-lg-2 col-md-3 px-0 me-2">
											<select
												id="delayInterval"
												name="delayInterval"
												className="form-select"
												disabled={isLoading || isSaving}
												value={formData.delayInterval}
												onChange={handleChange}
											>
												<option value="Minutes">Minutes</option>
												<option value="Hours">Hours</option>
												<option value="Days">Days</option>
												<option value="Weeks">Weeks</option>
												<option value="Months">Months</option>
											</select>
										</div>
										{autoresponderType === 'Custom' && (
											<div className="col-lg-2 col-md-3 px-0">
												<select
													id="customTimeFrame"
													name="customTimeFrame"
													className="form-select"
													disabled={isLoading || isSaving}
													value={formData.customTimeFrame}
													onChange={handleChange}
												>
													<option value="Before">Before</option>
													<option value="After">After</option>
												</select>
											</div>
										)}
									</div>
								)}
							</div>
						</form>
					</ScrollableContainerContent>
					<ScrollableContainerFooter>
						<div className="container-buttons">
							<button
								type="button"
								className="btn btn-primary"
								onClick={handleSubmit}
								disabled={isLoading || isSaving}
							>
								{!isSaving ? (
									<Fragment>
										<span>Save</span>
									</Fragment>
								) : (
									<Fragment>
										{/* <Spinner /> */}
										<span>Saving...</span>
									</Fragment>
								)}
							</button>
							{isEdit && (
								<AppButton
									className="danger"
									children={<span>Delete</span>}
									onClick={deleteAutoresponder}
								/>
							)}
						</div>
					</ScrollableContainerFooter>
				</ScrollableContainer>
			) : (
				<div className="text-center p-3">{/* <Spinner /> */}</div>
			)}
			{showUploadFilesDialog && <FileUploadDialog toggleDialog={toggleUploadFilesDialog} />}
		</Fragment>
	);
};

export default AutorespondersForm;
