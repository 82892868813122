import { useState } from 'react';
import ContactFormDialog from '../../contactsFormDialog/contacts/ContactFormDialog';
import ImportDialog from '../../../importDialog/ImportDialog';
import './ContactsHeader.css';
import SearchListInput from '../../../../searchListInput/SearchListInput';

const ContactsHeader = ({ callback }) => {
	const [ showContactDetailsDialog, setShowContactDetailsDialog ] = useState(false);
	const [ showImportContactsDialog, setShowImportContactsDialog ] = useState(false);

	const toggleContactDetailsDialog = () => {
		setShowContactDetailsDialog((prev) => !prev);
	};

	const toggleImportContactsDialog = () => {
		setShowImportContactsDialog((prev) => !prev);
	};

	const importContacts = (e) => {
		toggleImportContactsDialog();
	};

	const importContactsCallback = (e) => {
		toggleImportContactsDialog();
		if (callback) {
			callback();
		}
	};

	return (
		<div className="contacts-content-header-container">
			<div className="contacts-content-header-search">
				<SearchListInput callback={callback} type="contacts" />
			</div>
			<div className="contacts-content-header-add-button">
				{/* <AppButton children={<span>Add Contact</span>} onClick={toggleContactDetailsDialog} /> */}
				<div className="btn-group dropbottom">
					<button
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						className="btn btn-primary btn-lg dropdown-toggle"
					>
						<span>Add Contacts </span>
					</button>
					<ul className="dropdown-menu">
						<li>
							<button className="btn dropdown-item" onClick={importContacts}>
								<span>Import</span>
							</button>
						</li>
						<li>
							<button className="btn dropdown-item" onClick={toggleContactDetailsDialog}>
								<span>Add Manually</span>
							</button>
						</li>
					</ul>
				</div>
			</div>
			{/* <h5 className="contacts-list-title">Contacts</h5> */}

			{showContactDetailsDialog && <ContactFormDialog onClose={toggleContactDetailsDialog} callback={callback} />}

			{showImportContactsDialog && (
				<ImportDialog onClose={toggleImportContactsDialog} callback={importContactsCallback} />
			)}
		</div>
	);
};

export default ContactsHeader;
