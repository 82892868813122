import { useState, useEffect, Fragment } from 'react';
import BroadcastsSidebarHeaderPlaceholder from '../../../placeholders/broadcastsSidebarHeaderPlaceholder/BroadcastsSidebarHeaderPlaceholder';
import BroadcastPlaceholder from '../../../placeholders/broadcastPlaceholder/BroadcastPlaceholder';
import BroadcastsList from './broadcastsList/BroadcastsList';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import BroadcastsSidebarHeader from './broadcastsSidebarHeader/BroadcastsSidebarHeader';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import { GetCampaigns } from '../../../../Services';
import './BroadcastsSidebar.css';

const BroadcastsSidebar = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ broadcasts, setBroadcasts ] = useState([]);
	const [ { reloadBroadcasts, searchQuery }, dispatch ] = useStateValue();

	useEffect(() => {
		dispatch({
			type        : actionTypes.SEARCH_QUERY,
			searchQuery : null
		});
	}, []);

	useEffect(
		() => {
			getBroadcasts(searchQuery);

			let broadcastsInterval = setInterval(() => {
				getBroadcasts(searchQuery);
			}, 5000);

			return () => {
				clearInterval(broadcastsInterval);
			};
		},
		[ searchQuery ]
	);

	useEffect(
		() => {
			if (reloadBroadcasts) {
				getBroadcasts(searchQuery);

				dispatch({
					type             : actionTypes.RELOAD_BROADCASTS,
					reloadBroadcasts : false
				});
			}
		},
		[ reloadBroadcasts ]
	);

	const getBroadcasts = async (query) => {
		//console.log('broadcast reloaded');
		let data = { query: query };
		const items = await GetCampaigns(data);

		if (items) {
			//sort by date desc
			items.sort((a, b) => {
				let dateA = new Date(a.dateCreated),
					dateB = new Date(b.dateCreated);
				return dateB - dateA;
			});

			setBroadcasts(items);
		}

		setIsLoading(false);
	};

	return (
		<ScrollableContainer className="broadcasts-sidebar-container">
			{isLoading ? (
				<Fragment>
					<ScrollableContainerHeader>
						<BroadcastsSidebarHeaderPlaceholder />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<BroadcastPlaceholder />
						<BroadcastPlaceholder />
						<BroadcastPlaceholder />
						<BroadcastPlaceholder />
						<BroadcastPlaceholder />
					</ScrollableContainerContent>
				</Fragment>
			) : (
				<Fragment>
					<ScrollableContainerHeader>
						<BroadcastsSidebarHeader items={broadcasts} callback={getBroadcasts} />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<BroadcastsList items={broadcasts} />
					</ScrollableContainerContent>
				</Fragment>
			)}
		</ScrollableContainer>
	);
};

export default BroadcastsSidebar;
