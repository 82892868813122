import './ContactFormSidebar.css';
import { useState, useEffect, useRef, Fragment } from 'react';
import AppButton from '../../../../../appButton/AppButton';
import {
	AddContact,
	EditContact,
	GetContact,
	DeleteContact,
	GetContactLists,
	GetCustomFields
} from '../../../../../../Services';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';
import { toast } from 'react-toastify';
import ContactForm from './contactForm/ContactForm';
import ScrollableContainer from '../../../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerFooter from '../../../../../scrollableContainer/scrollableContainerFooter/ScrollableContainerFooter';
import ScrollableContainerHeader from '../../../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';

const ContactFormSidebar = ({ id, callback, onClose }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ contactLists, setContactLists ] = useState([]);
	const [ customFields, setCustomFields ] = useState([]);
	const [ selectedContactLists, setSelectedContactLists ] = useState([]);
	const [ contactTags, setContactTags ] = useState([]);
	const [ broadcastsMessages, setBroadcastsMessages ] = useState([]);
	const [ { selectedThread, phoneNumber, reloadInbox }, dispatch ] = useStateValue();
	const USPhoneRegex = /^\d{10}$/;
	const phoneRegex = /^\d+/g;

	useEffect(
		() => {
			getContactDetails();
			setIsLoading(true);
		},
		[ id ]
	);

	const getContactLists = async (query) => {
		let data = { query: query };
		const response = await GetContactLists(data);

		if (response !== undefined) {
			let tempContactLists = [];
			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					tempContactLists.push({ value: response[i].id, label: response[i].name });
				}
			}
			setContactLists(tempContactLists);
		}
	};

	const getContactDetails = async () => {
		getContactLists();

		//load all custom fields
		let userCustomFields = await GetCustomFields({ query: null, includeDefaultCustomFields: false });

		if (id !== undefined && id !== null && id !== '') {
			setIsEdit(true);

			const response = await GetContact(id);

			if (response !== undefined) {
				let tempFormData = response;

				if (tempFormData.phoneNumber) {
					tempFormData.phoneNumber = tempFormData.phoneNumber.replace('+1', '');
				}

				//contact lists
				let tempSelectedContactLists = [];
				if (response.contactLists !== undefined && response.contactLists.length > 0) {
					for (let i = 0; i < response.contactLists.length; i++) {
						//tempSelectedContactLists.push(response.contactLists[i].contactListId);
						tempSelectedContactLists.push({
							value : response.contactLists[i].id,
							label : response.contactLists[i].name
						});
					}
				}

				setSelectedContactLists(tempSelectedContactLists);

				//contact custom fields
				if (userCustomFields !== undefined && userCustomFields.length > 0) {
					for (let i = 0; i < userCustomFields.length; i++) {
						userCustomFields[i].contactCustomFieldId = null;

						//add custom fields to form data
						tempFormData['CF_' + userCustomFields[i].id] = userCustomFields[i].value;
					}

					if (response.customFields !== undefined && response.customFields.length > 0) {
						//for each custom field apply value
						for (let i = 0; i < response.customFields.length; i++) {
							let fieldId = response.customFields[i].customFieldId;

							let foundItem = userCustomFields.find((x) => x.id === fieldId);

							if (foundItem) {
								foundItem.contactCustomFieldId = response.customFields[i].id;
								foundItem.value = response.customFields[i].value;
							}
						}
					}

					tempFormData.customFields = userCustomFields;

					setCustomFields(userCustomFields);
				}

				//tags
				if (response.tags !== undefined && response.tags.length > 0) {
					let tempFormTags = [];

					for (let i = 0; i < response.tags.length; i++) {
						tempFormTags.push(response.tags[i].value);
					}

					tempFormData.formTags = tempFormTags;

					setContactTags(tempFormTags);
				}

				//broadcasts
				if (response.broadcastMessages !== null && response.broadcastMessages.length > 0) {
					setBroadcastsMessages(response.broadcastMessages);
				}

				setFormData(tempFormData);
			}
		} else {
			let tempFormData = {};

			if (userCustomFields !== undefined && userCustomFields.length > 0) {
				for (let i = 0; i < userCustomFields.length; i++) {
					userCustomFields[i].contactCustomFieldId = null;
					userCustomFields[i].value = null;
				}
			}

			tempFormData.customFields = userCustomFields;
			setFormData(tempFormData);

			setCustomFields(userCustomFields);
		}

		setIsLoading(false);
	};

	const handleChange = (e) => {
		if (e.target.name === 'phoneNumber') {
			let value = e.target.value.replace(/[^0-9]+/g, '');

			setFormData({
				...formData,
				[e.target.name]: value
			});
		} else if (e.target.name.indexOf('CF_') > -1) {
			let tempCustomFields = customFields;

			let cfId = e.target.name.replace('CF_', '');

			let foundCF = tempCustomFields.find((x) => x.id === cfId);
			if (foundCF) {
				foundCF.value = e.target.value;
			}

			setCustomFields(tempCustomFields);
		} else {
			setFormData({
				...formData,
				[e.target.name]: e.target.value
			});
		}
	};

	const handleSelectChange = (option, action) => {
		if (action.name === 'contactLists') {
			setSelectedContactLists(option);
		}
	};

	const handleTagsChange = (e) => {
		setContactTags(e);
	};

	const handleOnCancel = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!formData.phoneNumber) {
			toast.error('Phone Number is required!');
			return;
		}

		let isValidPhone = false;

		const isPhoneNumber = USPhoneRegex.test(formData.phoneNumber);

		if (formData.phoneNumber !== '') {
			// if (formData.Country === 'United States') {
			// 	if (USPhoneRegex.test(formData.phoneNumber)) {
			// 		isValidPhone = true;
			// 	}
			// } else if (isPhoneNumber) {
			// 	isValidPhone = true;
			// }

			if (isPhoneNumber) {
				isValidPhone = true;
			}
		}

		if (!isValidPhone) {
			toast.error('Please enter a valid phone number.');
			return;
		}

		if (!formData.firstName) {
			toast.error('First Name is required!');
			return;
		}

		if (!formData.lastName) {
			toast.error('Last Name is required!');
			return;
		}

		setIsSaving(true);

		//prepare contact lists for POST action
		let tempContactLists = [];

		if (selectedContactLists) {
			for (let i = 0; i < selectedContactLists.length; i++) {
				tempContactLists.push(selectedContactLists[i].value);
			}
		}

		//prepare custom fields for POST action
		let tempCustomFields = [];

		if (formData.customFields) {
			for (let i = 0; i < formData.customFields.length; i++) {
				let cf = formData.customFields[i];

				tempCustomFields.push({
					customFieldId        : cf.id,
					contactCustomFieldId : cf.contactCustomFieldId,
					value                : cf.value && cf.value !== null ? cf.value : ''
				});
			}
		}

		let tempFormData = formData;
		tempFormData.listIds = tempContactLists;
		tempFormData.tags = contactTags;
		tempFormData.customFields = tempCustomFields;
		tempFormData.phoneNumberId = phoneNumber.id;

		let response = null;

		if (isEdit) {
			response = await EditContact(id, tempFormData);
		} else {
			response = await AddContact(tempFormData);
		}

		if (response && response.error) {
			toast.error(response.error);
			setIsSaving(false);

			return;
		}

		if (response === '') {
			if (callback) {
				callback();
			}

			dispatch({
				type        : actionTypes.RELOAD_INBOX,
				reloadInbox : true
			});
		}

		setIsSaving(false);
	};

	const deleteContact = async () => {
		if (window.confirm('Are you sure you want to delete the contact?')) {
			const response = await DeleteContact(id);

			if (response !== undefined) {
				if (selectedThread !== null) {
					if (selectedThread.contactId === id) {
						dispatch({
							type           : actionTypes.SELECTED_THREAD,
							selectedThread : null
						});
					}
				}

				if (callback) {
					callback();
				}

				if (onClose) {
					onClose();
				}
			}
		}
	};

	return (
		<div className="inbox-contact-form">
			<ScrollableContainer>
				<ScrollableContainerHeader>
					<div className="row">
						<div className="col-6">
							<h4 className="mb-3">{`${isEdit ? 'Edit' : 'Add'} Contact`}</h4>
						</div>
						{formData.status && (
							<div className="col-6 d-flex justify-content-end">
								<span className={`contact-status ${formData.status.toLowerCase()}`}>
									{formData.status}
								</span>
							</div>
						)}
					</div>
				</ScrollableContainerHeader>
				<ScrollableContainerContent>
					<ContactForm
						formData={formData}
						contactLists={contactLists}
						customFields={customFields}
						selectedContactLists={selectedContactLists}
						broadcastsMessages={broadcastsMessages}
						contactTags={contactTags}
						isLoading={isLoading}
						isEdit={isEdit}
						isSaving={isSaving}
						handleChange={handleChange}
						handleSelectChange={handleSelectChange}
						handleTagsChange={handleTagsChange}
					/>
				</ScrollableContainerContent>
				<ScrollableContainerFooter>
					{!isLoading && (
						<div className="inbox-contact-form-buttons">
							<AppButton type="button" children={<span>Save</span>} onClick={handleSubmit} />
							<AppButton
								className="outline-primary"
								children={<span>Close</span>}
								onClick={handleOnCancel}
							/>
						</div>
					)}
				</ScrollableContainerFooter>
			</ScrollableContainer>
		</div>
	);
};

export default ContactFormSidebar;
