import './BroadcastPlaceholder.css';

const BroadcastPlaceholder = () => {
	return (
		<div className="broadcast broadcast-placeholder">
			<div className="content placeholder-glow">
				<span className="placeholder placeholder-lg col-8" />
				<span className="placeholder placeholder-lg col-4" />
			</div>
		</div>
	);
};

export default BroadcastPlaceholder;
