import { Fragment, useEffect, useState, useRef } from 'react';
import './BroadcastsForm.css';
import AppButton from '../../../../../appButton/AppButton';
import { useStateValue } from '../../../../../../StateProvider';
import { actionTypes } from '../../../../../../reducer';
import { toast } from 'react-toastify';
import ScrollableContainer from '../../../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerFooter from '../../../../../scrollableContainer/scrollableContainerFooter/ScrollableContainerFooter';
import { GetCampaign, AddCampaign, GetContactLists, CancelCampaign, DeleteCampaign } from '../../../../../../Services';
import FileUploadDialog from '../../../../fileUpload/FileUploadDialog';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import AppLinkGoBack from '../../../../../appLinkGoBack/AppLinkGoBack';

const BroadcastsForm = ({ id }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ { user, phoneNumber, selectedMedia }, dispatch ] = useStateValue();
	const [ formData, setFormData ] = useState({});
	const [ broadcast, setBroadcast ] = useState({});
	const [ showUploadFilesDialog, setShowUploadFilesDialog ] = useState(false);
	const [ contactLists, setContactLists ] = useState([]);
	const [ selectedCampaignLists, setSelectedCampaignLists ] = useState([]);
	const [ selectedSuppressionListId, setSelectedSuppressionListId ] = useState([]);
	const [ campaignType, setCampaignType ] = useState('');
	const [ messageType, setMessageType ] = useState('');
	const messageTypeSMSRef = useRef();
	const messageTypeMMSRef = useRef();
	const campaignTypeImmediateRef = useRef();
	const campaignTypeScheduleRef = useRef();
	const [ clickedButton, setClickedButton ] = useState('last7Days');
	const [ startDate, setStartDate ] = useState(null);
	const [ endDate, setEndDate ] = useState(null);
	const startDateRef = useRef();
	const endDateRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(
		() => {
			getBroadcast();
		},
		[ location, startDate, endDate ]
	);

	useEffect(
		() => {
			setClickedButton('last7Days');
		},
		[ location ]
	);

	const getBroadcast = async () => {
		let tempContactLists = await getContactLists();

		if (id) {
			setIsEdit(true);

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});

			let tempStartDate = startDate;
			let tempEndDate = endDate;
			let date = new Date();

			if (!tempStartDate || tempStartDate === null) {
				tempStartDate = new Date(date.setDate(date.getDate() - 7)).toISOString();
			}

			if (!tempEndDate || tempEndDate === null) {
				tempEndDate = new Date().toISOString();
			}

			let data = {
				startDate : tempStartDate,
				endDate   : tempEndDate
			};

			const response = await GetCampaign(id, data);

			if (response && response.error) {
				toast.error('Could not get broadcast details');
				setIsLoading(false);

				return;
			}

			if (response) {
				let tempFormData = response;
				setBroadcast(response);

				//set datetime format to display
				if (tempFormData.startDate) {
					tempFormData.startDate = tempFormData.startDate.toString().substring(0, 16);
				}

				//contact lists
				let tempCampaignLists = [];
				if (response.campaignLists && response.campaignLists !== null && response.campaignLists.length > 0) {
					for (let i = 0; i < response.campaignLists.length; i++) {
						tempCampaignLists.push({
							value : response.campaignLists[i].contactList.id,
							label : response.campaignLists[i].contactList.name
						});
					}
				}

				//suppression list
				let tempSuppressionListId = [];
				let suppressionListIdItem = tempContactLists.find((x) => x.value === response.suppressionListId);
				if (suppressionListIdItem) {
					tempSuppressionListId.push({
						value : suppressionListIdItem.value,
						label : suppressionListIdItem.label
					});
				}

				//attachments
				let tempCampaignAttachments = [];
				if (
					response.campaignAttachments &&
					response.campaignAttachments !== null &&
					response.campaignAttachments.length > 0
				) {
					for (let i = 0; i < response.campaignAttachments.length; i++) {
						//tempCampaignAttachments.push(response.campaignAttachments[i].media);
						tempCampaignAttachments.push({
							id   : response.campaignAttachments[i].media.id,
							name : response.campaignAttachments[i].media.name,
							url  : response.campaignAttachments[i].media.mediaUrl
						});
					}
				}

				setSelectedCampaignLists(tempCampaignLists);
				setSelectedSuppressionListId(tempSuppressionListId);
				setCampaignType(tempFormData.campaignType);
				setMessageType(tempFormData.messageType);

				dispatch({
					type          : actionTypes.SELECTED_MEDIA,
					selectedMedia : tempCampaignAttachments
				});

				setFormData(tempFormData);
			}
		} else {
			setFormData({
				name               : '',
				body               : '',
				fallbackBody       : '',
				unsubscribeMessage : '',
				startDate          : ''
			});

			setIsEdit(false);
			setCampaignType('Immediate');
			setMessageType('SMS');
			setSelectedCampaignLists([]);
			setSelectedSuppressionListId([]);

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});
		}

		setIsLoading(false);
	};

	const getContactLists = async (query) => {
		let data = { query: query };
		const response = await GetContactLists(data);

		if (response !== undefined) {
			let tempContactLists = [];
			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					tempContactLists.push({ value: response[i].id, label: response[i].name });
				}
			}
			setContactLists(tempContactLists);

			return tempContactLists;
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		let value = e.target.value;

		if (target === 'messageType') {
			setMessageType(value);
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}
	};

	const handleDateRangeChange = (e) => {
		let selectedStartDate = startDateRef.current.value;
		let selectedEndDate = endDateRef.current.value;

		if (!selectedStartDate || selectedStartDate === null || !selectedEndDate || selectedEndDate === null) {
			return;
		}

		setStartDate(new Date(selectedStartDate).toISOString());
		setEndDate(new Date(selectedEndDate).toISOString());
	};

	const handleSelectChange = (option, action) => {
		if (action.name === 'campaignLists') {
			setSelectedCampaignLists(option);
		} else if (action.name === 'suppressionListId') {
			setSelectedSuppressionListId({
				value : option.value,
				label : option.label
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (formData.name.trim() === '') {
			toast.error('Broadcast name is required');
			return;
		}

		setIsSaving(true);

		//prepare Broadcasts lists for POST action
		let tempCampaignLists = [];

		if (selectedCampaignLists) {
			for (let i = 0; i < selectedCampaignLists.length; i++) {
				tempCampaignLists.push(selectedCampaignLists[i].value);
			}
		}

		//prepare attachments
		var tempSelectedMedia = [];

		if (selectedMedia !== null && selectedMedia.length > 0) {
			for (let i = 0; i < selectedMedia.length; i++) {
				tempSelectedMedia.push(selectedMedia[i].id);
			}
		}

		let tempFormData = formData;
		tempFormData.userId = user.id;
		tempFormData.phoneNumberId = phoneNumber.id;
		tempFormData.messageType = messageType;
		tempFormData.campaignType = campaignType;
		tempFormData.listIds = tempCampaignLists;
		tempFormData.attachmentIds = tempSelectedMedia;
		tempFormData.suppressionListId = selectedSuppressionListId.value;

		if (campaignType === 'Immediate') {
			tempFormData.startDate = null;
			tempFormData.status = 'Active';
		} else {
			tempFormData.status = 'Scheduled';
		}

		let response = null;

		if (id) {
			//response = await EditCampaign(id, tempFormData);
		} else {
			response = await AddCampaign(tempFormData);
		}

		if (response && response.error) {
			toast.error(response.error);
			setIsSaving(false);

			return;
		}

		if (response !== undefined) {
			dispatch({
				type             : actionTypes.RELOAD_BROADCASTS,
				reloadBroadcasts : true
			});

			navigate('/campaigns/' + response);
		} else {
			//toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const removeAttachment = (e) => {
		const selectedAttachment = e.currentTarget;
		var id = selectedAttachment.getAttribute('data-id');

		var tempSelectedMediaItems = selectedMedia;

		tempSelectedMediaItems = tempSelectedMediaItems.filter((obj) => obj.id !== id);

		dispatch({
			type          : actionTypes.SELECTED_MEDIA,
			selectedMedia : tempSelectedMediaItems
		});
	};

	const toggleUploadFilesDialog = () => {
		setShowUploadFilesDialog((prev) => !prev);
	};

	const onMessageTypeSMSClick = () => {
		messageTypeSMSRef.current.classList.remove('btn-outline-primary');
		messageTypeSMSRef.current.classList.add('btn-primary');
		messageTypeMMSRef.current.classList.remove('btn-primary');
		messageTypeMMSRef.current.classList.add('btn-outline-primary');
		setMessageType('SMS');
	};

	const onMessageTypeMMSClick = () => {
		messageTypeSMSRef.current.classList.remove('btn-primary');
		messageTypeSMSRef.current.classList.add('btn-outline-primary');
		messageTypeMMSRef.current.classList.remove('btn-outline-primary');
		messageTypeMMSRef.current.classList.add('btn-primary');
		setMessageType('MMS');
	};

	const onCampaignTypeImmediateClick = () => {
		campaignTypeImmediateRef.current.classList.remove('btn-primary');
		campaignTypeImmediateRef.current.classList.add('btn-outline-primary');
		campaignTypeScheduleRef.current.classList.remove('btn-outline-primary');
		campaignTypeScheduleRef.current.classList.add('btn-primary');
		setCampaignType('Immediate');
	};

	const onCampaignTypeScheduleClick = () => {
		campaignTypeImmediateRef.current.classList.remove('btn-primary');
		campaignTypeImmediateRef.current.classList.add('btn-outline-primary');
		campaignTypeScheduleRef.current.classList.remove('btn-outline-primary');
		campaignTypeScheduleRef.current.classList.add('btn-primary');
		setCampaignType('Schedule');
	};

	const handleCancelBroadcast = async () => {
		if (window.confirm('Are you sure you want to cancel the broadcast?')) {
			const response = await CancelCampaign(id);

			if (response && response.error) {
				toast.error(response.error);
				setIsSaving(false);

				return;
			}

			if (response === '') {
				dispatch({
					type             : actionTypes.RELOAD_BROADCASTS,
					reloadBroadcasts : true
				});

				setBroadcast({
					...broadcast,
					status : 'Canceled'
				});

				navigate('/campaigns/' + id);
			}
		}
	};

	const handleDeleteBroadcast = async () => {
		if (window.confirm('Are you sure you want to delete the broadcast?')) {
			const response = await DeleteCampaign(id);

			if (response && response.error) {
				toast.error(response.error);
				setIsSaving(false);

				return;
			}

			if (response === '') {
				dispatch({
					type             : actionTypes.RELOAD_BROADCASTS,
					reloadBroadcasts : true
				});

				navigate('/campaigns');
			}
		}
	};

	const handleStatsButtonClick = (e) => {
		const value = e.target.value;
		let date = new Date();

		setClickedButton(value);

		switch (value) {
			case 'last7Days':
				setStartDate(new Date(date.setDate(date.getDate() - 7)).toISOString());
				setEndDate(new Date().toISOString());
				getBroadcast();
				break;
			case 'last30Days':
				setStartDate(new Date(date.setDate(date.getDate() - 30)).toISOString());
				setEndDate(new Date().toISOString());
				getBroadcast();
				break;
			case 'custom':
				break;
		}
	};

	return (
		<Fragment>
			{!isLoading ? (
				<ScrollableContainer className="broadcasts-form-container">
					<ScrollableContainerHeader>
						<div className="form-header-title row mx-0">
							<div className="col-9 ps-0">
								<AppLinkGoBack redirect="/campaigns" />
								<span>{isEdit ? 'Broadcast Details' : 'Create Broadcast'}</span>
							</div>
						</div>
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<form>
							<div className="form-inputs">
								{broadcast &&
								broadcast.status === 'Completed' &&
								broadcast.stats && (
									<div className="form-group mb-4">
										{/* <div className="section-divider-title pt-2">
											<span>Statistics</span>
										</div> */}
										<div className="broadcast-stats-wrapper mb-3">
											<div className="row">
												<div className="col-auto pe-4">
													<ButtonGroup
														aria-label="Date Range"
														onClick={handleStatsButtonClick}
													>
														<Button
															variant={`${clickedButton === 'last7Days'
																? 'primary'
																: 'outline-primary'}`}
															value="last7Days"
														>
															Last 7 Days
														</Button>
														<Button
															variant={`${clickedButton === 'last30Days'
																? 'primary'
																: 'outline-primary'}`}
															value="last30Days"
														>
															Last 30 Days
														</Button>
														<Button
															variant={`${clickedButton === 'custom'
																? 'primary'
																: 'outline-primary'}`}
															value="custom"
														>
															Custom
														</Button>
													</ButtonGroup>
												</div>
												{clickedButton === 'custom' && (
													<Fragment>
														<div className="col-auto mt-2 pe-0">
															<span>From: </span>
														</div>
														<div className="col-auto">
															<input
																type="date"
																id="startDate"
																ref={startDateRef}
																name="startDate"
																className="form-control w-auto"
																onInput={handleDateRangeChange}
															/>
														</div>
														<div className="col-auto mt-2 ms-4 px-0">
															<span>To: </span>
														</div>
														<div className="col-auto">
															<input
																type="date"
																id="endDate"
																name="endDate"
																ref={endDateRef}
																className="form-control w-auto"
																onInput={handleDateRangeChange}
															/>
														</div>
													</Fragment>
												)}
											</div>
										</div>
										<div className="broadcast-stats-wrapper">
											<div className="broadcast-stats-item">
												<div className="broadcast-stats-item-title">
													<span>Sent</span>
												</div>
												<div className="broadcast-stats-item-value">
													<span>{broadcast.stats.sent || 0}</span>
												</div>
											</div>
											{/* <div className="broadcast-stats-item">
												<div className="broadcast-stats-item-title">
													<span>Credits Used</span>
												</div>
												<div className="broadcast-stats-item-value">
													<span>{broadcast.stats.creditsUsed || 0}</span>
												</div>
											</div> */}
											<div className="broadcast-stats-item">
												<div className="broadcast-stats-item-title">
													<span>Undelivered</span>
												</div>
												<div className="broadcast-stats-item-value">
													<span>{broadcast.stats.undelivered || 0}</span>
												</div>
											</div>
											<div className="broadcast-stats-item">
												<div className="broadcast-stats-item-title">
													<span>Responses</span>
												</div>
												<div className="broadcast-stats-item-value">
													<span>{broadcast.stats.responses || 0}</span>
												</div>
											</div>
											<div className="broadcast-stats-item">
												<div className="broadcast-stats-item-title">
													<span>Success Rate</span>
												</div>
												<div className="broadcast-stats-item-value">
													<span>{`${broadcast.stats.successRate}%` || 0}</span>
												</div>
											</div>
										</div>
									</div>
								)}
								<div className="form-group mb-3">
									<label htmlFor="name">Broadcast Name</label>
									<input
										type="text"
										id="name"
										name="name"
										className="form-control"
										value={formData.name}
										disabled={isLoading || isSaving || isEdit}
										onChange={handleChange}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="messageType">Message Type</label>
									<div className="btn-group d-block" role="group" aria-label="Message Type">
										<button
											type="button"
											className={
												messageType === '' || messageType === 'SMS' ? (
													'btn btn-primary'
												) : (
													'btn btn-outline-primary'
												)
											}
											ref={messageTypeSMSRef}
											onClick={onMessageTypeSMSClick}
											disabled={isLoading || isSaving || isEdit}
										>
											SMS
										</button>
										<button
											type="button"
											className={
												messageType === 'MMS' ? 'btn btn-primary' : 'btn btn-outline-primary'
											}
											ref={messageTypeMMSRef}
											onClick={onMessageTypeMMSClick}
											disabled={isLoading || isSaving || isEdit}
										>
											MMS
										</button>
									</div>
								</div>
								<div className="form-group mb-3 message-body">
									<label htmlFor="message">Message</label>
									<textarea
										id="body"
										name="body"
										rows="3"
										className="form-control"
										placeholder="Write Your Message"
										value={formData.body}
										onChange={handleChange}
										disabled={isLoading || isSaving || isEdit}
									/>
									{selectedMedia !== null &&
									selectedMedia.length > 0 && (
										<div className="send-message-attachments-area">
											{selectedMedia.map((mediaItem) => (
												<div className="attachment-item" key={mediaItem.id} id={mediaItem.id}>
													<Link to={mediaItem.url} target="_blank">
														<span>{mediaItem.name}</span>
													</Link>
													<button
														className="btn btn-link attachment-item-remove"
														data-id={mediaItem.id}
														onClick={removeAttachment}
														disabled={isLoading || isSaving || isEdit}
													>
														<i className="fa-solid fa-xmark p-0 m-0" />
													</button>
												</div>
											))}
										</div>
									)}
									<div className="send-message-actions">
										<div className="send-message-action">
											<AppButton
												className="link"
												children={<i className="fa-solid fa-paperclip mx-2" />}
												onClick={toggleUploadFilesDialog}
												disabled={isLoading || isSaving || isEdit}
											/>
										</div>
									</div>
								</div>
								{messageType === 'MMS' && (
									<div className="form-group mb-3">
										<label htmlFor="fallbackBody">SMS Fallback</label>
										<textarea
											id="fallbackBody"
											name="fallbackBody"
											rows="3"
											className="form-control"
											placeholder="Write Your Message"
											onChange={handleChange}
											value={formData.fallbackBody}
											disabled={isLoading || isSaving || isEdit}
										/>
									</div>
								)}
								<div className="form-group mb-3">
									<label htmlFor="unsubscribeMessage">Opt Out Message</label>
									<textarea
										id="unsubscribeMessage"
										name="unsubscribeMessage"
										rows="2"
										className="form-control"
										placeholder="Reply stop to stop"
										onChange={handleChange}
										value={formData.unsubscribeMessage}
										disabled={isLoading || isSaving || isEdit}
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="campaignLists">Send To</label>
									<Select
										name="campaignLists"
										id="campaignLists"
										isMulti={true}
										value={selectedCampaignLists}
										isDisabled={isLoading || isSaving || isEdit}
										onChange={handleSelectChange}
										options={contactLists}
										className="react-select-component"
										classNamePrefix="react-select"
									/>
								</div>
								<div className="form-group mb-3">
									<label htmlFor="suppressionListId">Suppression List</label>
									<Select
										name="suppressionListId"
										id="suppressionListId"
										isMulti={false}
										value={selectedSuppressionListId}
										isDisabled={isLoading || isSaving || isEdit}
										onChange={handleSelectChange}
										options={contactLists}
										className="react-select-component"
										classNamePrefix="react-select"
									/>
								</div>
								<div className="form-group mb-3">
									<div className="section-divider-title">
										<span>Schedule Settings</span>
									</div>
									<div className="btn-group d-block" role="group" aria-label="Campaign Type">
										<button
											type="button"
											className={
												(campaignType === 'Immediate'
													? 'btn btn-primary'
													: 'btn btn-outline-primary') + ' btn-broadcast-type'
											}
											ref={campaignTypeImmediateRef}
											onClick={onCampaignTypeImmediateClick}
											disabled={isLoading || isSaving || isEdit}
										>
											Immediately
										</button>
										<button
											type="button"
											className={
												(campaignType === 'Schedule'
													? 'btn btn-primary'
													: 'btn btn-outline-primary') + ' btn-broadcast-type'
											}
											ref={campaignTypeScheduleRef}
											onClick={onCampaignTypeScheduleClick}
											disabled={isLoading || isSaving || isEdit}
										>
											Schedule
										</button>
									</div>
								</div>
								{(campaignType === 'Schedule' || (campaignType === 'Immediate' && isEdit)) && (
									<div className="form-group mb-3">
										<label htmlFor="startDate">
											{broadcast && broadcast.status === 'Completed' ? 'Sent On' : 'Send On'}
										</label>
										<div>
											<input
												type="datetime-local"
												id="startDate"
												name="startDate"
												className="form-control w-auto d-inline"
												disabled={isLoading || isSaving || isEdit}
												value={formData.startDate}
												onChange={handleChange}
											/>
											<span className="ms-2">EST</span>
										</div>
									</div>
								)}
							</div>
						</form>
						{isEdit ? (
							broadcast &&
							broadcast.status &&
							broadcast.status === 'Completed' && (
								<div className="container-buttons">
									<AppButton
										className="danger"
										children={<span>Delete</span>}
										onClick={handleDeleteBroadcast}
									/>
								</div>
							)
						) : (
							<div className="container-buttons">
								<button
									type="button"
									className="btn btn-primary"
									onClick={handleSubmit}
									disabled={isLoading || isSaving || isEdit}
								>
									{!isSaving ? (
										<Fragment>
											<span>Save</span>
										</Fragment>
									) : (
										<Fragment>
											{/* <Spinner /> */}
											<span>Saving...</span>
										</Fragment>
									)}
								</button>
							</div>
						)}
					</ScrollableContainerContent>
					{/* <ScrollableContainerFooter>
						
					</ScrollableContainerFooter> */}
				</ScrollableContainer>
			) : (
				<div className="text-center p-3">{/* <Spinner /> */}</div>
			)}
			{showUploadFilesDialog && <FileUploadDialog toggleDialog={toggleUploadFilesDialog} />}
		</Fragment>
	);
};

export default BroadcastsForm;
