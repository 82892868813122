import CustomContentForm from './customContentForm/CustomContentForm';
import CustomContentSidebar from '../customContentSidebar/CustomContentSidebar';
import './CustomContentContentArea.css';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import MainAppContentHeader from '../../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import Footer from '../../footer/Footer';
import { useParams } from 'react-router-dom';
import { Fragment, useState, useEffect } from 'react';
import { isMobile } from '../../../../common';
import { GetCustomContent } from '../../../../Services';
import { useLocation } from 'react-router-dom';

const CustomContentContentArea = ({ showForm }) => {
	const { id } = useParams();
	const location = useLocation();
	const [ showSidebar, setShowSidebar ] = useState(true);
	const [ showInputForm, setShowInputForm ] = useState(false);
	const [ customContentTitle, setCustomContentTitle ] = useState('');

	useEffect(
		() => {
			if (isMobile()) {
				if (id || window.location.pathname === '/flexiblecontent/add') {
					setShowSidebar(false);
					setShowInputForm(true);
				} else {
					setShowSidebar(true);
					setShowInputForm(false);
				}
			} else {
				if (id || window.location.pathname === '/flexiblecontent/add') {
					setShowInputForm(true);
				}
			}

			getCustomContent();
		},
		[ location ]
	);

	const getCustomContent = async () => {
		if (id) {
			const response = await GetCustomContent(id);

			if (response) {
				setCustomContentTitle(response.name);
			}
		}
	};

	return (
		<ScrollableContainer className="customcontent-content-container">
			<ScrollableContainerHeader>
				{showSidebar && <MainAppContentHeader title={customContentTitle} />}
			</ScrollableContainerHeader>
			{/* <div className="customcontent-content-area"> */}
			<ScrollableContainerContent>
				{showSidebar && <Fragment>{showForm && <CustomContentSidebar />}</Fragment>}
				{showInputForm && <CustomContentForm id={id} showForm={showForm} />}
				<Footer />
			</ScrollableContainerContent>
			{/* </div> */}
		</ScrollableContainer>
	);
};

export default CustomContentContentArea;
