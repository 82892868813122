import './MessageThreadPlaceholder.css';

const MessageThreadPlaceholder = () => {
	return (
		<div className="message-thread message-thread-placeholder">
			{/* <div className="avatar placeholder-glow">
				<div className="avatar-circle placeholder" />
			</div> */}
			<div className="content placeholder-glow">
				<span className="placeholder placeholder-lg col-4" />
				<span className="placeholder col-12" />
			</div>
		</div>
	);
};

export default MessageThreadPlaceholder;
