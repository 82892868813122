import { useState, useEffect, Fragment } from 'react';
import CustomContentSidebarHeaderPlaceholder from '../../../placeholders/customContentSidebarHeaderPlaceholder/CustomContentSidebarHeaderPlaceholder';
import CustomContentPlaceholder from '../../../placeholders/customContentPlaceholder/CustomContentPlaceholder';
import CustomContentList from './customContentList/CustomContentList';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import CustomContentSidebarHeader from './customContentSidebarHeader/CustomContentSidebarHeader';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import { GetCustomContentItems } from '../../../../Services';
import './CustomContentSidebar.css';

const CustomContentSidebar = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ customContent, setCustomContent ] = useState([]);
	const [ { reloadCustomContent, searchQuery }, dispatch ] = useStateValue();

	useEffect(() => {
		dispatch({
			type        : actionTypes.SEARCH_QUERY,
			searchQuery : null
		});
	}, []);

	useEffect(
		() => {
			getCustomContent(searchQuery);

			let customcontentInterval = setInterval(() => {
				getCustomContent(searchQuery);
			}, 5000);

			return () => {
				clearInterval(customcontentInterval);
			};
		},
		[ searchQuery ]
	);

	useEffect(
		() => {
			if (reloadCustomContent) {
				getCustomContent(searchQuery);

				dispatch({
					type                : actionTypes.RELOAD_CUSTOMCONTENT,
					reloadCustomContent : false
				});
			}
		},
		[ reloadCustomContent ]
	);

	const getCustomContent = async (query) => {
		let data = { query: query };
		const items = await GetCustomContentItems(data);

		if (items) {
			//sort by date desc
			items.sort((a, b) => {
				let dateA = new Date(a.dateCreated),
					dateB = new Date(b.dateCreated);
				return dateB - dateA;
			});

			setCustomContent(items);
		}

		setIsLoading(false);
	};

	return (
		<ScrollableContainer className="customcontent-sidebar-container">
			{isLoading ? (
				<Fragment>
					<ScrollableContainerHeader>
						<CustomContentSidebarHeaderPlaceholder />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<CustomContentPlaceholder />
						<CustomContentPlaceholder />
						<CustomContentPlaceholder />
						<CustomContentPlaceholder />
						<CustomContentPlaceholder />
					</ScrollableContainerContent>
				</Fragment>
			) : (
				<Fragment>
					<ScrollableContainerHeader>
						<CustomContentSidebarHeader items={customContent} callback={getCustomContent} />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<CustomContentList items={customContent} />
					</ScrollableContainerContent>
				</Fragment>
			)}
		</ScrollableContainer>
	);
};

export default CustomContentSidebar;
