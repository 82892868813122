import './FileUploadDialog.css';
import { useState, useRef, useEffect, Fragment } from 'react';
import AppButton from '../../appButton/AppButton';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import MediaPlaceholder from '../../placeholders/mediaPlaceholder/MediaPlaceholder';
import UserMediaList from './userMediaList/UserMediaList';
import { GetMediaList, UploadFile } from '../../../Services';
import { actionTypes } from '../../../reducer';
import { useStateValue } from '../../../StateProvider';

const FileUploadDialog = ({ toggleDialog, multiSelect = true }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ mediaList, setMediaList ] = useState([]);
	const [ { selectedMedia }, dispatch ] = useStateValue();
	const uploadFileRef = useRef();

	useEffect(() => {
		getMediaList();
	}, []);

	const getMediaList = async () => {
		const response = await GetMediaList();

		if (response) {
			setMediaList(response);
		}

		setIsLoading(false);
	};

	const toggleUploadFilesDialog = () => {
		if (toggleDialog) {
			toggleDialog();
		}
	};

	const attachFiles = () => {
		if (toggleDialog) {
			toggleDialog();
		}
	};

	const onFileUpload = (e) => {
		e.preventDefault();

		let uploadButton = uploadFileRef.current;

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const onFileChange = async (e) => {
		//upload file on server
		let files = uploadFileRef.current.files;

		if (files !== undefined) {
			var data = new FormData();

			for (var i = 0; i !== files.length; i++) {
				data.append('files', files[i]);
			}

			const response = await UploadFile(data);

			if (response !== undefined) {
				getMediaList();
			}
		}
	};

	// const onMediaSelect = () => {
	// 	setMediaList(response);
	// };

	return (
		<ModalDialog>
			<ModalDialogContent>
				<h4 className="mb-3">Attach files</h4>
				<Fragment>
					{isLoading ? (
						<ScrollableContainer className="media-list-container container">
							<ScrollableContainerContent>
								<MediaPlaceholder />
								<MediaPlaceholder />
								<MediaPlaceholder />
								<MediaPlaceholder />
							</ScrollableContainerContent>
						</ScrollableContainer>
					) : (
						<ScrollableContainer className="media-list-container container">
							<ScrollableContainerContent>
								<UserMediaList mediaList={mediaList} multiSelect={multiSelect} />
							</ScrollableContainerContent>
						</ScrollableContainer>
					)}
				</Fragment>
			</ModalDialogContent>
			<ModalDialogButtons shaded>
				<AppButton children={<span>Attach</span>} onClick={attachFiles} />
				<AppButton children={<span>Upload</span>} onClick={onFileUpload} />
				<AppButton
					className="outline-primary"
					children={<span>Cancel</span>}
					onClick={toggleUploadFilesDialog}
				/>
				<input
					type="file"
					onChange={onFileChange}
					className="d-none"
					id="uploadFile"
					multiple
					ref={uploadFileRef}
				/>
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default FileUploadDialog;
