import './MainAppContentHeader.css';
import MainAppContentHeaderQuickActions from './mainAppContentHeaderQuickActions/MainAppContentHeaderQuickActions';
import AppLinkGoBack from '../../../appLinkGoBack/AppLinkGoBack';
import { useStateValue } from '../../../../StateProvider';
import { Fragment } from 'react';

const MainAppContentHeader = ({ title, showGoBackLink = false, goBackUrl = '' }) => {
	const [ { user }, dispatch ] = useStateValue();

	return (
		<Fragment>
			{user &&
			(!user.credits || user.credits === '' || user.credits === '0' || user.credits === 0) && (
				<div className="content-header-container row mx-0">
					<span className="no-credits-warning">
						No Credits available. Please upgrade your plan to continue sending messages.
					</span>
				</div>
			)}
			<div className="content-header-container row mx-0">
				<div className="content-area-title col-md-6 col-10 ps-0">
					<AppLinkGoBack
						showLink={showGoBackLink}
						redirect={goBackUrl}
						mainHeaderLink={showGoBackLink}
						mobileLink={false}
					/>
					<span>{title}</span>
				</div>
				<div className="content-area-actions col-md-6 col-2 ps-0">
					<MainAppContentHeaderQuickActions />
				</div>
			</div>
		</Fragment>
	);
};

export default MainAppContentHeader;
