import './ForgotPassword.css';
import { useState, Fragment } from 'react';
import AppButton from '../../appButton/AppButton';
import smallLogo from '../../../img/logo.png';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordSendEmail } from '../../../Services';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ email, setEmail ] = useState('');
	const navigate = useNavigate();

	const handleChange = (e) => {
		const value = e.target.value.trim();

		setEmail(value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		if (email === '') {
			toast.error('Valid email address is required');
			return;
		}

		let data = {
			email : email
		};

		const response = await ResetPasswordSendEmail(data);

		if (response.error) {
			toast.error('User with specified email not found in the system.');
			setIsSaving(false);
			return;
		}

		if (response !== undefined) {
			toast.success("We've sent you an email with a link to reset your password.");
		}

		setIsSaving(false);
	};

	return (
		<div className="app-login">
			<div className="login-form">
				<div className="login-logo">
					<img src={smallLogo} />
				</div>
				<div className="login-title">Forgot Password?</div>
				<form onSubmit={handleSubmit}>
					<div className="mb-3">
						<label htmlFor="emailAddress" className="form-label">
							Email address
						</label>
						<input
							type="email"
							className="form-control form-control-lg"
							id="email"
							placeholder="Email address"
							name="email"
							value={email}
							onChange={handleChange}
							disabled={isSaving}
						/>
					</div>
					<div className="d-grid">
						<AppButton
							type="submit"
							className="primary btn-lg"
							disabled={isSaving}
							showSpinner={isSaving}
							//spinnerText="Logging in..."
							children={
								<Fragment>
									<span>Send link</span>
								</Fragment>
							}
						/>
					</div>
				</form>
				<button
					type="button"
					className="btn btn-link btn-block btn-go-back mt-2 p-0"
					onClick={() => navigate(-1)}
				>
					{/* <i className="fas fa-chevron-left" /> */}
					<span>Go Back</span>
				</button>
			</div>
		</div>
	);
};

export default ForgotPassword;
