import { useState, useEffect } from 'react';
import AppButton from '../../../../../appButton/AppButton';
import SearchListInput from '../../../../searchListInput/SearchListInput';
import ContactListFormDialog from '../../contactsFormDialog/contactLists/ContactListFormDialog';
import './ContactListsHeader.css';

const ContactListsHeader = ({ callback }) => {
	const [ showContactListDetailsDialog, setShowContactListDetailsDialog ] = useState(false);

	const toggleContactListDetailsDialog = () => {
		setShowContactListDetailsDialog((prev) => !prev);
	};

	return (
		<div className="contact-lists-content-header-container">
			<div className="contact-lists-content-header-search">
				<SearchListInput callback={callback} />
			</div>
			<div className="contact-lists-content-header-add-button">
				<AppButton
					className="primary btn-lg"
					children={<span>Add List</span>}
					onClick={toggleContactListDetailsDialog}
				/>
			</div>
			{/* <h5 className="contact-lists-list-title">Contact Lists</h5> */}

			{showContactListDetailsDialog && (
				<ContactListFormDialog onClose={toggleContactListDetailsDialog} callback={callback} />
			)}
		</div>
	);
};

export default ContactListsHeader;
