import './CustomContentList.css';
import CustomContentListItem from '../customContentListItem/CustomContentListItem';
import { Fragment } from 'react';

const CustomContentList = ({ items }) => {
	return (
		<div className="customcontent-list-container">
			<Fragment>
				{items && items.length > 0 ? (
					items.map((item) => <CustomContentListItem item={item} key={item.id} />)
				) : (
					<div className="no-data">You have no flexible content items</div>
				)}
			</Fragment>
		</div>
	);
};

export default CustomContentList;
