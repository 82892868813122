import './Contacts.css';
import { useState, Fragment, useEffect } from 'react';
import Moment from 'react-moment';
import ContactFormDialog from '../../contactsFormDialog/contacts/ContactFormDialog';
import {
	DeleteContact,
	ExportContacts,
	GetMessageThread,
	GetMessageThreadByPhoneNumberAndContact,
	SetThreadMessagesRead
} from '../../../../../../Services';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';
import ContactsExportDialog from '../../contactsExportDialog/ContactsExportDialog';
import AppButton from '../../../../../appButton/AppButton';
import { Link } from 'react-router-dom';

const Contacts = ({ contacts, callback }) => {
	const [ showContactDetailsDialog, setShowContactDetailsDialog ] = useState(false);
	const [ showContactExportDialog, setShowContactExportDialog ] = useState(false);
	const [ contactId, setContactId ] = useState(0);
	const [ exportUrl, setExportUrl ] = useState('');
	const [ sortField, setSortField ] = useState('');
	const [ order, setOrder ] = useState('asc');
	const [ { selectedThread, phoneNumber, reloadInbox, isNewThread, selectedMedia }, dispatch ] = useStateValue();

	useEffect(() => {
		if (contacts && sortField === '') {
			//set default sort by date desc
			contacts.sort((a, b) => {
				let dateA = new Date(a.dateCreated),
					dateB = new Date(b.dateCreated);
				return dateB - dateA;
			});
		}
	}, []);

	const toggleContactDetailsDialog = () => {
		setShowContactDetailsDialog((prev) => !prev);
	};

	const toggleContactExportDialog = () => {
		setShowContactExportDialog((prev) => !prev);
	};

	const editContact = (e) => {
		setShowContactDetailsDialog((prev) => !prev);

		var selectedContactId = e.currentTarget.getAttribute('data-contact-id');
		setContactId(selectedContactId);
	};

	const deleteContact = async (e) => {
		if (window.confirm('Are you sure you want to delete the contact?')) {
			var selectedContactId = e.currentTarget.getAttribute('data-contact-id');

			const response = await DeleteContact(selectedContactId);

			if (response !== undefined) {
				if (selectedThread !== null) {
					if (selectedThread.contactId === selectedContactId) {
						dispatch({
							type           : actionTypes.SELECTED_THREAD,
							selectedThread : null
						});
					}
				}

				if (callback) {
					callback();
				}
			}
		}
	};

	const exportContacts = async (e) => {
		let contactIdsForExport = [];

		for (let i = 0; i < contacts.length; i++) {
			contactIdsForExport.push(contacts[i].id);
		}

		let data = { ids: contactIdsForExport };

		const response = await ExportContacts(data);

		if (response && response.error) {
			toast.error(response.error);

			return;
		}

		if (response !== undefined && response !== '') {
			setExportUrl(response);
			setShowContactExportDialog(true);
		}
	};

	const handleListItemClick = (e) => {
		var selectedContactId = e.currentTarget.getAttribute('data-contact-id');
		setContactId(selectedContactId);
	};

	const handleContactInboxOpen = async (e) => {
		var selectedContactId = e.currentTarget.getAttribute('data-contact-id');

		//get thread object
		let thread = await GetMessageThreadByPhoneNumberAndContact(phoneNumber.id, selectedContactId);

		if (thread !== undefined && thread.showThread) {
			dispatch({
				type           : actionTypes.SELECTED_THREAD,
				selectedThread : thread
			});

			// set all messages as read
			let setReadResponse = await SetThreadMessagesRead(thread.id);
		} else {
			dispatch({
				type           : actionTypes.SELECTED_THREAD,
				selectedThread : null
			});
		}

		dispatch({
			type        : actionTypes.RELOAD_INBOX,
			reloadInbox : true
		});

		dispatch({
			type        : actionTypes.IS_NEW_THREAD,
			isNewThread : false
		});

		dispatch({
			type          : actionTypes.SELECTED_MEDIA,
			selectedMedia : null
		});
	};

	const handleSortingChange = (accessor) => {
		const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc';
		setSortField(accessor);
		setOrder(sortOrder);
		handleSorting(accessor, sortOrder);
	};

	const handleSorting = (sortField, sortOrder) => {
		if (sortField) {
			contacts.sort((a, b) => {
				if (a[sortField] === null) return 1;
				if (b[sortField] === null) return -1;
				if (a[sortField] === null && b[sortField] === null) return 0;
				return (
					a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
						numeric : true
					}) * (sortOrder === 'asc' ? 1 : -1)
				);
			});
		}
	};

	const sortClassName = (accessor) => {
		return (
			'sortable ' +
			(sortField === accessor && order === 'asc'
				? 'up'
				: sortField === accessor && order === 'desc' ? 'down' : 'default')
		);
	};

	return (
		<div className="contacts-list-container">
			<div className="table-responsive">
				<table className="table table-borderless">
					<thead className="table-secondary">
						<tr>
							<th
								scope="col"
								key="phoneNumberFriendly"
								onClick={() => handleSortingChange('phoneNumberFriendly')}
								className={sortClassName('phoneNumberFriendly')}
							>
								Phone
							</th>
							<th
								scope="col"
								key="fullName"
								onClick={() => handleSortingChange('fullName')}
								className={sortClassName('fullName')}
							>
								Name
							</th>
							<th
								scope="col"
								key="dateCreated"
								onClick={() => handleSortingChange('dateCreated')}
								className={sortClassName('dateCreated')}
							>
								Created
							</th>
							<th
								scope="col"
								key="status"
								onClick={() => handleSortingChange('status')}
								className={sortClassName('status')}
							>
								Status
							</th>
							<th
								scope="col"
								key="sentMessagesCount"
								onClick={() => handleSortingChange('sentMessagesCount')}
								className={sortClassName('sentMessagesCount')}
							>
								Sent
							</th>
							<th scope="col" />
						</tr>
					</thead>
					<tbody>
						<Fragment>
							{contacts &&
								contacts.length > 0 &&
								contacts.map((contact) => (
									<tr
										key={contact.id}
										className={`contact-item ${contactId && contactId === contact.id
											? 'active'
											: ''}`}
									>
										<td>{contact.phoneNumberFriendly}</td>
										<td>{contact.fullName}</td>
										<td>
											<Moment format="MM/DD/YYYY">{contact.dateCreated}</Moment>
										</td>
										<td>{contact.status}</td>
										<td>
											<Link
												to="/inbox"
												onClick={handleContactInboxOpen}
												data-contact-id={contact.id}
											>
												{contact.sentMessagesCount}
											</Link>
										</td>
										<td className="settings-action">
											<div className="btn-group dropstart">
												<button
													type="button"
													data-bs-toggle="dropdown"
													aria-expanded="false"
													className="btn btn-link p-0"
													data-contact-id={contact.id}
													onClick={handleListItemClick}
												>
													<i className="fa-solid fa-gear mx-1" />
												</button>
												<ul className="dropdown-menu">
													<li>
														<button
															className="btn dropdown-item"
															onClick={editContact}
															data-contact-id={contact.id}
														>
															<span>
																<i className="fa-solid fa-pencil" />Edit
															</span>
														</button>
													</li>
													<li>
														<button
															className="btn dropdown-item"
															onClick={deleteContact}
															data-contact-id={contact.id}
														>
															<span>
																<i className="fa-solid fa-trash" />Delete
															</span>
														</button>
													</li>
												</ul>
											</div>
										</td>
									</tr>
								))}
						</Fragment>
					</tbody>
				</table>
			</div>

			<div>
				<AppButton children={<span>Export</span>} onClick={exportContacts} />
			</div>

			{showContactDetailsDialog && (
				<ContactFormDialog id={contactId} onClose={toggleContactDetailsDialog} callback={callback} />
			)}

			{showContactExportDialog && <ContactsExportDialog url={exportUrl} onClose={toggleContactExportDialog} />}
		</div>
	);
};

export default Contacts;
