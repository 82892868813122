import './ProfileIcon.css';
import { useEffect, useState, Fragment } from 'react';
import AvatarLettersIcon from '../mainApp/avatar/AvatarLettersIcon';
import { Link } from 'react-router-dom';
import { deleteToken } from '../../Token';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import LogoutIcon from '../../img/sign_out_icon.png';

const ProfileIcon = ({ onLinkClick }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ tempUser, setTempUser ] = useState({});
	const [ showLogoutLink, setShowLogoutLink ] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(
		() => {
			setTempUser(user);

			// if (location && location.pathname) {
			// 	setShowLogoutLink(location && location.pathname === '/');
			// }
		},
		[ user, location ]
	);

	// const logout = () => {
	// 	deleteToken();
	// 	dispatch({
	// 		type : actionTypes.SET_USER,
	// 		user : null
	// 	});

	// 	dispatch({
	// 		type           : actionTypes.SELECTED_THREAD,
	// 		selectedThread : null
	// 	});

	// 	navigate('/login');
	// };

	const handleLinkClick = () => {
		if (onLinkClick) {
			onLinkClick();
		}
	};

	return (
		<div className="profile-details-container">
			<Fragment>
				<div className="profile-details-inner">
					<AvatarLettersIcon name={tempUser.displayName} />
					<Link to="/account#settings" className="profile-details-link" onClick={handleLinkClick}>
						<div className="profile-details">
							<div className="full-name">{tempUser.displayName}</div>
							<div>Manage Account</div>
						</div>
					</Link>
				</div>
				{/* {showLogoutLink && (
					<Link to="#" className="btn btn-link logout-link" onClick={logout}>
						<img src={LogoutIcon} />
						<span>Log Out</span>
					</Link>
				)} */}
			</Fragment>
		</div>
	);
};

export default ProfileIcon;
