import './MessagePlaceholder.css';

const MessagePlaceholder = () => {
	return (
		<div className="message message-placeholder">
			<div className="icon placeholder-glow">
				<div className="icon-circle placeholder" />
			</div>
			<div className="content placeholder-glow">
				<span className="placeholder placeholder-lg col-4" />
				<span className="placeholder col-12" />
			</div>
		</div>
	);
};

export default MessagePlaceholder;
