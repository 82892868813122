import './ContactLists.css';
import { useState, Fragment } from 'react';
import Moment from 'react-moment';
import ContactListFormDialog from '../../contactsFormDialog/contactLists/ContactListFormDialog';
import { DeleteContactList, ExportContactList } from '../../../../../../Services';
import ContactsExportDialog from '../../contactsExportDialog/ContactsExportDialog';
import { toast } from 'react-toastify';

const ContactLists = ({ lists, callback }) => {
	const [ showContactListDetailsDialog, setShowContactListDetailsDialog ] = useState(false);
	const [ showContactExportDialog, setShowContactExportDialog ] = useState(false);
	const [ contactListId, setContactListId ] = useState(0);
	const [ exportUrl, setExportUrl ] = useState('');

	const toggleContactListDetailsDialog = () => {
		setShowContactListDetailsDialog((prev) => !prev);
	};

	const toggleContactExportDialog = () => {
		setShowContactExportDialog((prev) => !prev);
	};

	const editContactList = (e) => {
		setShowContactListDetailsDialog((prev) => !prev);

		var selectedContactListId = e.currentTarget.getAttribute('data-contact-list-id');
		setContactListId(selectedContactListId);
	};

	const deleteContactList = async (e) => {
		if (window.confirm('Are you sure you want to delete the contact list?')) {
			var selectedContactListId = e.currentTarget.getAttribute('data-contact-list-id');

			const response = await DeleteContactList(selectedContactListId);

			if (response !== undefined) {
				if (callback) {
					callback();
				}
			}
		}
	};

	const exportContactList = async (e) => {
		var selectedContactListId = e.currentTarget.getAttribute('data-contact-list-id');

		let selectedList = lists.find((x) => x.id === selectedContactListId);

		if (
			selectedList &&
			(!selectedList.contacts || selectedList.contacts === null || selectedList.contacts.length === 0)
		) {
			toast.error("Selected list doesn't contain any contact");
			return;
		}

		const response = await ExportContactList(selectedContactListId);

		if (response !== undefined && response !== '') {
			setExportUrl(response);
			setShowContactExportDialog(true);
		}
	};

	const handleListItemClick = (e) => {
		var selectedContactListId = e.currentTarget.getAttribute('data-contact-list-id');
		setContactListId(selectedContactListId);
	};

	return (
		<div className="contact-lists-list-container">
			<div className="table-responsive">
				<table className="table table-borderless">
					<thead className="table-secondary">
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Created</th>
							<th scope="col">Total</th>
							<th scope="col">Active</th>
							<th scope="col">Invalid</th>
							<th scope="col">Unsubscribed</th>
							<th scope="col" />
						</tr>
					</thead>
					<tbody>
						<Fragment>
							{lists &&
								lists.length > 0 &&
								lists.map((list) => (
									<tr
										key={list.id}
										className={`contact-item ${contactListId && contactListId === list.id
											? 'active'
											: ''}`}
									>
										<td>{list.name}</td>
										<td>
											<Moment format="MM/DD/YYYY">{list.dateCreated}</Moment>
										</td>
										<td>{list.contacts ? list.contacts.length : 0}</td>
										<td>{list.activeContacts}</td>
										<td>{list.invalidContacts}</td>
										<td>{list.unsubscribedContacts}</td>
										<td className="settings-action">
											<div className="btn-group dropstart">
												<button
													type="button"
													data-bs-toggle="dropdown"
													aria-expanded="false"
													className="btn btn-link p-0"
													data-contact-list-id={list.id}
													onClick={handleListItemClick}
												>
													<i className="fa-solid fa-gear mx-1" />
												</button>
												<ul className="dropdown-menu">
													<li>
														<button
															className="btn dropdown-item"
															onClick={editContactList}
															data-contact-list-id={list.id}
														>
															<span>
																<i className="fa-solid fa-pencil" />Edit
															</span>
														</button>
													</li>
													<li>
														<button
															className="btn dropdown-item"
															onClick={deleteContactList}
															data-contact-list-id={list.id}
														>
															<span>
																<i className="fa-solid fa-trash" />Delete
															</span>
														</button>
													</li>
													<li>
														<button
															className="btn dropdown-item"
															onClick={exportContactList}
															data-contact-list-id={list.id}
														>
															<span>
																<i className="fa-solid fa-file-export" />Export
															</span>
														</button>
													</li>
												</ul>
											</div>
										</td>
									</tr>
								))}
						</Fragment>
					</tbody>
				</table>
			</div>

			{showContactListDetailsDialog && (
				<ContactListFormDialog
					id={contactListId}
					onClose={toggleContactListDetailsDialog}
					callback={callback}
				/>
			)}

			{showContactExportDialog && <ContactsExportDialog url={exportUrl} onClose={toggleContactExportDialog} />}
		</div>
	);
};

export default ContactLists;
