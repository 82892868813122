import { ButtonGroup, Button } from 'react-bootstrap';
import { Fragment, useState, useEffect } from 'react';
import { useRef } from 'react';
import { GetAnalytics } from '../../../Services';
import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import MainAppContentHeader from '../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import './Analytics.css';

const Analytics = () => {
	const [ clickedButton, setClickedButton ] = useState('last7Days');
	const [ startDate, setStartDate ] = useState(null);
	const [ endDate, setEndDate ] = useState(null);
	const [ analyticsData, setAnalyticsData ] = useState({});
	const startDateRef = useRef();
	const endDateRef = useRef();

	useEffect(
		() => {
			getAnalyticsData();
		},
		[ startDate, endDate ]
	);

	const handleClick = (e) => {
		const value = e.target.value;
		let date = new Date();
		let tempStartDate = null;
		let tempEndDate = null;

		setClickedButton(value);

		switch (value) {
			case 'last7Days':
				tempStartDate = new Date(date.setDate(date.getDate() - 7)).toISOString();
				tempEndDate = new Date().toISOString();
				getAnalyticsData(tempStartDate, tempEndDate);
				break;
			case 'last30Days':
				tempStartDate = new Date(date.setDate(date.getDate() - 30)).toISOString();
				tempEndDate = new Date().toISOString();
				getAnalyticsData(tempStartDate, tempEndDate);
				break;
			case 'custom':
				break;
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;
		// let tempStartDate = null;
		// let tempEndDate = null;

		// if (target === 'startDate') {
		// 	tempStartDate = value;
		// } else if (target === 'endDate') {
		// 	tempEndDate = value;
		// }

		let selectedStartDate = startDateRef.current.value;
		let selectedEndDate = endDateRef.current.value;

		if (!selectedStartDate || selectedStartDate === null || !selectedEndDate || selectedEndDate === null) {
			return;
		}

		selectedStartDate = new Date(selectedStartDate).toISOString();
		selectedEndDate = new Date(selectedEndDate).toISOString();

		getAnalyticsData(selectedStartDate, selectedEndDate);
	};

	const getAnalyticsData = async (start, end) => {
		let tempStartDate = start;
		let tempEndDate = end;
		let date = new Date();

		if (!tempStartDate || tempStartDate === null) {
			tempStartDate = new Date(date.setDate(date.getDate() - 7)).toISOString();
		}

		if (!tempEndDate || tempEndDate === null) {
			tempEndDate = new Date().toISOString();
		}

		let data = {
			startDate : tempStartDate,
			endDate   : tempEndDate
		};

		const response = await GetAnalytics(data);

		if (response !== undefined) {
			setAnalyticsData(response);
		}
	};

	return (
		<div className="app-analytics-container panel">
			<ScrollableContainer className="analytics-content-container">
				<ScrollableContainerHeader>
					<MainAppContentHeader title="Analytics" />
				</ScrollableContainerHeader>
				<ScrollableContainerContent>
<div className="analytics-container">
				<div className="analytics-section">
					<div className="analytics-selector">
						<ButtonGroup aria-label="Date Range" onClick={handleClick}>
							<Button
								variant={`${clickedButton === 'last7Days' ? 'primary' : 'outline-primary'}`}
								value="last7Days"
							>
								Last 7 Days
							</Button>
							<Button
								variant={`${clickedButton === 'last30Days' ? 'primary' : 'outline-primary'}`}
								value="last30Days"
							>
								Last 30 Days
							</Button>
							<Button
								variant={`${clickedButton === 'custom' ? 'primary' : 'outline-primary'}`}
								value="custom"
							>
								Custom
							</Button>
						</ButtonGroup>
					</div>
				</div>
				{clickedButton === 'custom' && (
					<div className="analytics-section">
						<div className="row">
							<div className="col-auto mt-2 pe-0">
								<span>From: </span>
							</div>
							<div className="col-auto">
								<input
									type="date"
									id="startDate"
									ref={startDateRef}
									name="startDate"
									className="form-control w-auto"
									onInput={handleChange}
								/>
							</div>
							<div className="col-auto mt-2 ms-4 px-0">
								<span>To: </span>
							</div>
							<div className="col-auto">
								<input
									type="date"
									id="endDate"
									name="endDate"
									ref={endDateRef}
									className="form-control w-auto"
									onInput={handleChange}
								/>
							</div>
						</div>
					</div>
				)}
				<div className="analytics-section">
					<div className="row mb-3 analytics-item">
						<div className="col-12 analytics-item-title">Campaign Messages</div>
						<div className="col-2">
							Sent: <span className="analytics-value">{analyticsData.totalMessagesSentCampaigns ?? 0}</span>
						</div>
						<div className="col-2">
							Responses:
							<span className="analytics-value">{analyticsData.totalMessagesReceivedCampaigns ?? 0}</span>
						</div>
						<div className="col-2">
							Unsubscribed: <span className="analytics-value">{analyticsData.unsubscribed ?? 0}</span>
						</div>
					</div>
					<div className="row mb-3 analytics-item">
						<div className="col-12 analytics-item-title">Inbox Messages</div>
						<div className="col-2">
							Sent: <span className="analytics-value">{analyticsData.totalMessagesSentInbox ?? 0}</span>
						</div>
						<div className="col-2">
							Responses: <span className="analytics-value">{analyticsData.totalMessagesReceivedInbox ?? 0}</span>
						</div>
						<div className="col-2">
							Unsubscribed: <span className="analytics-value">{analyticsData.unsubscribed ?? 0}</span>
						</div>
					</div>
					<div className="row mb-3 analytics-item">
						<div className="col-12 analytics-item-title">Subscribers</div>
						<div className="col-2">
							Application: <span className="analytics-value">{analyticsData.newContactsCountFromApp ?? 0}</span>
						</div>
						<div className="col-2">
							Import: <span className="analytics-value">{analyticsData.newContactsCountFromImport ?? 0}</span>
						</div>
						<div className="col-2">
							Web forms: <span className="analytics-value">{analyticsData.newContactsCountFromWebForm ?? 0}</span>
						</div>
					</div>
				</div>
			</div>
				</ScrollableContainerContent>
			</ScrollableContainer>
		</div>
	);
};

export default Analytics;
