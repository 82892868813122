import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import './AdministrationSidebar.css';

const AdministrationSidebar = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ selectedType, setSelectedType ] = useState('');
	const location = useLocation();

	useEffect(
		() => {
			setSelectedType(window.location.pathname.replace('/administration', '').replaceAll('/', ''));
		},
		[ location ]
	);

	if (isLoading) {
		return (
			<ScrollableContainer className="administration-sidebar-container">
				<ScrollableContainerContent>
					<div className="container">
						{/* <div
							className={`administration-item row ${selectedType === '' || selectedType === 'users'
								? 'active'
								: ''}`}
						>
							<div className="administration-item-label col-12">
								<Link to="/administration" className="btn btn-link">
									<span>Users</span>
								</Link>
							</div>
						</div> */}
						<div className={`administration-item row ${selectedType === 'content' ? 'active' : ''}`}>
							<div className="administration-item-label col-12">
								<Link to="/flexiblecontent" className="btn btn-link">
									<span>Flexible Content</span>
								</Link>
							</div>
						</div>
					</div>
				</ScrollableContainerContent>
			</ScrollableContainer>
		);
	}

	return (
		<ScrollableContainer className="administration-sidebar-container">
			<ScrollableContainerContent />
		</ScrollableContainer>
	);
};

export default AdministrationSidebar;
