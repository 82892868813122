import { Link } from 'react-router-dom';
import './FooterLinks.css';

const FooterLinks = () => {
	return (
		<div className="footer-links">
			<ul>
				<li>
					<a href="https://www.mindfulsms.com/terms-of-use/" target="_blank">
						Terms & Conditions
					</a>
				</li>
				<li>|</li>
				<li>
					<a href="https://www.mindfulsms.com/privacy-policy/" target="_blank">
						Privacy Policy
					</a>
				</li>
				<li>|</li>
				<li>
					<a href="https://www.mindfulsms.com/anti-spam-policy/" target="_blank">
						Anti-Spam Policy
					</a>
				</li>
			</ul>
		</div>
	);
};

export default FooterLinks;
