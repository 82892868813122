import './CustomContentListItem.css';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const CustomContentListItem = ({ item }) => {
	const location = useLocation();
	const [ customcontentId, setCustomContentId ] = useState('');

	useEffect(
		() => {
			setCustomContentId(window.location.pathname.replace('/flexiblecontent', '').replaceAll('/', ''));
		},
		[ location ]
	);

	return (
		<div
			className={`customcontent-item-container ${customcontentId && customcontentId === item.id ? 'active' : ''}`}
			id={item.id}
		>
			<Link to={`/flexiblecontent/${item.id}`}>
				<div className="customcontent-item-wrapper">
					<div className="customcontent-name">
						<span>{item.name}</span>
					</div>
					{/* <div className="customcontent-status">
						<span>{item.status}</span>
					</div> */}
				</div>
			</Link>
		</div>
	);
};

export default CustomContentListItem;
