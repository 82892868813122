import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ZapierDocumentation.css';
import ImageCreateZap from '../../../../../img/zapier-create-zap.jpg';
import ImageTriggerSelectApp from '../../../../../img/zapier-trigger-select-app.jpg';
import ImageTriggerSelectEvent from '../../../../../img/zapier-trigger-select-event.jpg';
import ImageTriggerSelectAccount from '../../../../../img/zapier-trigger-select-account.jpg';
import ImageTriggerCopyApiKey from '../../../../../img/zapier-copy-api-key.jpg';
import ImageTriggerAuthDialog from '../../../../../img/zapier-auth-dialog.jpg';
import ImageTriggerTest from '../../../../../img/zapier-test-trigger.jpg';

const ZapierDocumentation = () => {
	return (
		<div className="container-fluid zapier-documentation">
			<div className="container w-50">
				<div className="form-header-title pt-3">
					<h2>Setup A Zapier Connection</h2>
				</div>
				<p>
					Set up a Zapier Zap to connect to your MindfulSMS account to send your app details to email, SMS,
					CRM or any other application to automate your contact management and communication.
				</p>
				<h4>Step 1:</h4>
				<p>
					Create a Zapier account by going here:
					<Link to="https://zapier.com/sign-up" target="_blank" className="ms-2">
						https://zapier.com/sign-up
					</Link>
				</p>

				<h4>Step 2:</h4>
				<p>Create a new Zap:</p>
				<img src={ImageCreateZap} />

				<h4>Step 3:</h4>
				<p>Select the MindfulSMS App as the "Trigger":</p>
				<img src={ImageTriggerSelectApp} />

				<h4>Step 4:</h4>
				<p>Choose the event type you would like to process:</p>
				<img src={ImageTriggerSelectEvent} />

				<h4>Step 5:</h4>
				<p>Choose the option to "Connect A New Account":</p>
				<img src={ImageTriggerSelectAccount} />

				<h4>Step 6:</h4>
				<p>Login to your MindfulSMS account and copy your Zapier API key from the "Account" settings page:</p>
				<img src={ImageTriggerCopyApiKey} />

				<h4>Step 7:</h4>
				<p>Paste your api key into the Zapier "Allow Access" dialog and click "Yes, Continue":</p>
				<img src={ImageTriggerAuthDialog} />

				<h4>Step 8:</h4>
				<p>
					Test the trigger to see sample or recent lead data and continue to build your Zap by choosing an
					action and following the steps to complete the setup and publish your Zap.
				</p>
				<img src={ImageTriggerTest} />

				<h4>Done!</h4>
				<p className="pb-4">
					You should now have a Zap setup to send new and updated lead details to your other integrated
					systems. If you get stuck or have questions, feel free to contact our support team (<Link
						to="/support"
						target="_blank"
					>
						click here
					</Link>) and we would be glad to help!
				</p>
			</div>
		</div>
	);
};

export default ZapierDocumentation;
