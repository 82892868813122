import { toast } from 'react-toastify';

export const isMobile = () => {
	return window && window.innerWidth <= 767;
};

export const formatCurrency = (value) => {
	value = isNaN(value) ? 0 : value;

	return new Intl.NumberFormat('en-US', {
		style    : 'currency',
		currency : 'USD'
	}).format(value);
};

export const copyToClipboard = (text, messageOnSuccess) => {
	navigator.clipboard.writeText(text).then(
		function() {
			if (messageOnSuccess) {
				//toast.success(messageOnSuccess);
			}
		},
		function(err) {
			console.error('Could not copy text: ', err);
		}
	);
};
