import './BroadcastsList.css';
import BroadcastsListItem from '../broadcastsListItem/BroadcastsListItem';
import { Fragment } from 'react';

const BroadcastsList = ({ items }) => {
	return (
		<div className="broadcasts-list-container">
			<Fragment>
				{items && items.length > 0 ? (
					items.map((item) => <BroadcastsListItem item={item} key={item.id} />)
				) : (
					<div className="no-data">You have no broadcasts</div>
				)}
			</Fragment>
		</div>
	);
};

export default BroadcastsList;
