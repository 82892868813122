import './MainAppContentHeaderQuickActions.css';
import { Link } from 'react-router-dom';
import { actionTypes } from '../../../../../reducer';
import { useStateValue } from '../../../../../StateProvider';
import { useNavigate } from 'react-router';
import { deleteToken } from '../../../../../Token';

const MainAppContentHeaderQuickActions = () => {
	const [ { user, selectedThread }, dispatch ] = useStateValue();
	const navigate = useNavigate();

	const logout = () => {
		deleteToken();
		dispatch({
			type : actionTypes.SET_USER,
			user : null
		});

		dispatch({
			type           : actionTypes.SELECTED_THREAD,
			selectedThread : null
		});

		navigate('/login');
	};

	return (
		<div className="btn-group quick-actions-btn-wrapper">
			<button
				type="button"
				className="btn btn-primary dropdown-toggle"
				data-bs-toggle="dropdown"
				aria-expanded="false"
			>
				Quick Actions<i className="fas fa-chevron-down" />
			</button>
			<ul className="dropdown-menu">
				<li>
					<Link className="dropdown-item" to="/campaigns">
						Create Broadcast
					</Link>
				</li>
				<li>
					<Link className="dropdown-item" to="/tools/keywords">
						Create Keyword
					</Link>
				</li>
				<li>
					<Link className="dropdown-item" to="/contacts">
						Create Contact
					</Link>
				</li>
				<li>
					<Link className="dropdown-item" to="/account#settings">
						Manage Account
					</Link>
				</li>
				<li>
					<hr className="my-2" />
				</li>
				<li>
					<Link className="dropdown-item" to="#" onClick={logout}>
						Log Out
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default MainAppContentHeaderQuickActions;
