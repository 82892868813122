import { useState, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../reducer';
import { UserLogin } from '../../Services';
import { useStateValue } from '../../StateProvider';
import { setToken } from '../../Token';
import AppButton from '../appButton/AppButton';
import smallLogo from '../../img/logo.png';
import './Login.css';

const Login = () => {
	const [ { user }, dispatch ] = useStateValue();
	const navigate = useNavigate();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({
		Email    : '',
		Password : ''
	});

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		setTimeout(async function() {
			const response = await UserLogin(formData);

			if (response && response.token) {
				const { token, ...tempUser } = response;

				if (tempUser.status === 'Canceled' || tempUser.status === 'canceled') {
					toast.error('Your account has been canceled.');
					setIsSaving(false);
					return;
				}

				setToken(token);

				dispatch({
					type : actionTypes.SET_USER,
					user : tempUser
				});

				dispatch({
					type        : actionTypes.SET_PHONE_NUMBER,
					phoneNumber : tempUser.phoneNumbers[0]
				});

				//redirect to main page
				navigate('/');

				setIsSaving(false);
			} else {
				setIsSaving(false);
			}
		}, 500);
	};

	return (
		<div className="app-login">
			<div className="login-form">
				<div className="login-logo">
					<img src={smallLogo} />
				</div>
				<div className="login-title">Login To MindfulSMS</div>
				<form onSubmit={handleSubmit}>
					<div className="mb-3">
						<label htmlFor="emailAddress" className="form-label">
							Email address
						</label>
						<input
							type="email"
							className="form-control form-control-lg"
							id="emailAddress"
							placeholder="Email address"
							name="Email"
							value={formData.Email}
							onChange={handleChange}
							disabled={isSaving}
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="password" className="form-label">
							Password
						</label>
						<input
							type="password"
							className="form-control form-control-lg"
							id="password"
							placeholder="Password"
							name="Password"
							value={formData.Password}
							onChange={handleChange}
							disabled={isSaving}
						/>
					</div>
					<div className="d-grid">
						<AppButton
							type="submit"
							className="primary btn-lg"
							disabled={isSaving}
							showSpinner={isSaving}
							spinnerText="Logging in..."
							children={
								<Fragment>
									<span>Login</span>
								</Fragment>
							}
						/>
					</div>
				</form>
			</div>
			<div className="login-footer">
				<div className="forgot-password">
					<Link to="/forgot-password">Forgot Password</Link>
				</div>
				<hr />
				<div className="sign-up">
					No Account? <Link to="/">Sign Up Now</Link>
				</div>
				<div className="privacy">
					<div>By clicking "Login" I agree to MindfulSMS's</div>
					<div>
						<a href="https://www.mindfulsms.com/terms-of-use" target="_blank">
							Terms & Conditions
						</a>{' '}
						and{' '}
						<a href="https://www.mindfulsms.com/privacy-policy" target="_blank">
							Privacy Policy
						</a>
					</div>
				</div>

				<div className="copyright">
					<span>@ {new Date().getFullYear()} MindfulSMS</span>
				</div>
			</div>
		</div>
	);
};

export default Login;
