import './ContactForm.css';
import { useState, Fragment } from 'react';
import Select from 'react-select';
import { TagsInput } from 'react-tag-input-component';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import AppSpinner from '../../../../../../appSpinner/AppSpinner';

const ContactForm = ({
	formData,
	contactLists,
	customFields,
	selectedContactLists,
	broadcastsMessages,
	contactTags,
	isEdit,
	isLoading,
	isSaving,
	handleChange,
	handleSelectChange,
	handleTagsChange
}) => {
	const getInputType = (customFieldType) => {
		if (customFieldType === 'datetime') {
			return 'datetime-local';
		} else {
			return customFieldType;
		}
	};

	return (
		<Fragment>
			{!isLoading ? (
				<form>
					<div className="form-inputs">
						<div className="form-group mb-3">
							<label htmlFor="phoneNumber">Phone Number</label>
							<input
								type="text"
								id="phoneNumber"
								name="phoneNumber"
								className="form-control"
								disabled={isSaving}
								placeholder="format: 8005130456"
								value={formData.phoneNumber || ''}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="firstName">First Name</label>
							<input
								type="text"
								id="firstName"
								name="firstName"
								className="form-control"
								disabled={isSaving}
								value={formData.firstName || ''}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="LastName">Last Name</label>
							<input
								type="text"
								id="lastName"
								name="lastName"
								className="form-control"
								disabled={isSaving}
								value={formData.lastName || ''}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="email">Email</label>
							<input
								type="text"
								id="email"
								name="email"
								className="form-control"
								disabled={isSaving}
								value={formData.email || ''}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="lists">Lists</label>
							<Select
								name="contactLists"
								id="contactLists"
								isMulti
								value={selectedContactLists}
								isDisabled={isSaving}
								onChange={handleSelectChange}
								options={contactLists}
								className="react-select-component"
								classNamePrefix="react-select"
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="birthDate">Birthday</label>
							<input
								type="date"
								id="birthDate"
								name="birthDate"
								className="form-control"
								disabled={isSaving}
								value={formData.birthDate ? formData.birthDate.substring(0, 10) : ''}
								onChange={handleChange}
							/>
						</div>
						<div className="form-group mb-3">
							<label htmlFor="notes">Contact Notes</label>
							<textarea
								id="notes"
								name="notes"
								className="form-control"
								disabled={isSaving}
								value={formData.notes || ''}
								onChange={handleChange}
								rows="4"
							/>
						</div>
						<div className="form-group mb-4">
							<label htmlFor="formTags">Tags</label>
							<TagsInput value={contactTags} onChange={handleTagsChange} name="formTags" />
						</div>
						<div className="form-group mb-0 py-3 border-top">
							<h5>Custom Fields</h5>
						</div>
						{customFields &&
							customFields.length > 0 &&
							customFields.map((customField, index) => (
								<div className="form-group mb-2" key={customField.id}>
									<label htmlFor={customField.name}>{customField.name}</label>
									{customField.type === 'select' ? (
										<select
											id={`CF_${customField.id}`}
											name={`CF_${customField.id}`}
											className="form-select"
											disabled={isSaving}
											defaultValue={customField.value || ''}
											onChange={handleChange}
										>
											{customField.defaultValue &&
												customField.defaultValue.split('\n').map((selectItem) => (
													<option value={selectItem} key={selectItem}>
														{selectItem}
													</option>
												))}
										</select>
									) : (
										<Fragment>
											<div className="input-group">
												<input
													//type={() => getInputType(customField.type)}
													type={getInputType(customField.type)}
													id={`CF_${customField.id}`}
													name={`CF_${customField.id}`}
													className="form-control w-auto d-inline"
													disabled={isSaving}
													defaultValue={customField.value || ''}
													onChange={handleChange}
												/>
												{customField.type === 'datetime' && (
													<span className="ms-2 mt-2">EST</span>
												)}
											</div>
										</Fragment>
									)}
								</div>
							))}
						<div className="form-group mb-1 mt-4 py-3 border-top">
							<h5>Broadcasts List</h5>
						</div>
						{broadcastsMessages &&
							broadcastsMessages.length > 0 &&
							broadcastsMessages.map((broadcastsMessage, index) => (
								<Fragment>
									{broadcastsMessage.campaign && (
										<div
											className="form-group row mb-0 broadcast-message-link"
											key={broadcastsMessage.id}
										>
											<div className="col-8">
												<Link
													to={`/campaigns/${broadcastsMessage.campaign.id}`}
													className="btn btn-link p-0"
													target="_blank"
												>
													{broadcastsMessage.campaign.name}
												</Link>
											</div>
											<div className="col-4 d-flex justify-content-end">
												<span className="broadcast-message-date">
													<Moment format="MM/DD/YYYY">{broadcastsMessage.dateCreated}</Moment>
												</span>
											</div>
										</div>
									)}
								</Fragment>
							))}
					</div>
				</form>
			) : (
				<AppSpinner />
			)}
		</Fragment>
	);
};

export default ContactForm;
