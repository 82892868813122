import './ResetPassword.css';
import { useState, Fragment } from 'react';
import AppButton from '../../appButton/AppButton';
import smallLogo from '../../../img/logo.png';
import { Link, useSearchParams } from 'react-router-dom';
import { ResetPasswordProcess } from '../../../Services';
import { setToken } from '../../../Token';
import { toast } from 'react-toastify';

const ResetPassword = () => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const [ passwordReseted, setPasswordReseted ] = useState(false);
	const [ searchParams, setSearchParams ] = useSearchParams();

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value.trim();

		if (target === 'password') {
			setPassword(value);
		} else if (target === 'confirmPassword') {
			setConfirmPassword(value);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (password === '') {
			toast.error('Password is required');
			return;
		}

		if (confirmPassword === '') {
			toast.error('Confirm password is required');
			return;
		}

		if (password !== confirmPassword) {
			toast.error('Password and confirm password are different.');
			return;
		}

		setIsSaving(true);

		let userId = searchParams.get('id');
		let resetToken = searchParams.get('token');

		let data = {
			userId      : userId,
			resetToken  : encodeURIComponent(resetToken),
			newPassword : password
		};

		const response = await ResetPasswordProcess(data);

		if (response.error) {
			toast.error('Could not reset your password. Please try again.');
			setIsSaving(false);
			return;
		}

		if (response !== undefined) {
			let token = response.token;
			setToken(token);

			toast.success('Your password was reseted successfully.');
			setPasswordReseted(true);
		}

		setIsSaving(false);
	};

	return (
		<div className="app-login">
			<div className="login-form">
				<div className="login-logo">
					<img src={smallLogo} />
				</div>
				<div className="login-title">Reset Password</div>
				{passwordReseted ? (
					<Fragment>
						<h5 className="text-center">Your password was successfully reseted.</h5>
						<Link to="/login" className="d-block text-center mt-2">
							Login
						</Link>
					</Fragment>
				) : (
					<form onSubmit={handleSubmit}>
						<div className="mb-3">
							<label htmlFor="password" className="form-label">
								New Password
							</label>
							<input
								type="password"
								className="form-control form-control-lg"
								id="password"
								placeholder="Password"
								name="password"
								value={password}
								onChange={handleChange}
								disabled={isSaving}
							/>
						</div>
						<div className="mb-3">
							<label htmlFor="confirmPassword" className="form-label">
								Confirm Password
							</label>
							<input
								type="password"
								className="form-control form-control-lg"
								id="confirmPassword"
								placeholder="Confirm Password"
								name="confirmPassword"
								value={confirmPassword}
								onChange={handleChange}
								disabled={isSaving}
							/>
						</div>
						<div className="d-grid">
							<AppButton
								type="submit"
								className="primary btn-lg"
								disabled={isSaving}
								showSpinner={isSaving}
								//spinnerText="Logging in..."
								children={
									<Fragment>
										<span>Reset</span>
									</Fragment>
								}
							/>
						</div>
					</form>
				)}
			</div>
		</div>
	);
};

export default ResetPassword;
