import { useState, useEffect, Fragment, useRef } from 'react';
import { actionTypes } from '../../../../../reducer';
import { useStateValue } from '../../../../../StateProvider';
import { Link, useNavigate } from 'react-router-dom';
import { GetCustomerPortalSessionUrl } from '../../../../../Services';
import { deleteToken } from '../../../../../Token';
import './Billing.css';
import AppButton from '../../../../appButton/AppButton';
import { toast } from 'react-toastify';

const Billing = ({ type }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isWorking, setIsWorking ] = useState(false);
	const [ { user, selectedThread }, dispatch ] = useStateValue();
	const navigate = useNavigate();

	useEffect(
		() => {
			if (user.status === 'Canceled' || user.status === 'canceled') {
				logout();
			}
		},
		[ user ]
	);

	const logout = () => {
		deleteToken();
		dispatch({
			type : actionTypes.SET_USER,
			user : null
		});

		dispatch({
			type           : actionTypes.SELECTED_THREAD,
			selectedThread : null
		});

		navigate('/login');
	};

	const createCustomerPortalSession = async () => {
		setIsWorking(true);

		const response = await GetCustomerPortalSessionUrl();

		if (response && response.error) {
			setIsWorking(false);
			toast.error(response.error);
			return;
		}

		if (response && response !== '') {
			//window.open(response, '_blank');
			window.location.href = response;
		}

		setIsWorking(false);
	};

	return (
		<div className="account-billing-container">
			<div className="billing-overview">
				<AppButton
					type="button"
					className="primary mt-3"
					disabled={isWorking}
					showSpinner={isWorking}
					spinnerText="Manage Subscription"
					onClick={createCustomerPortalSession}
					children={
						<Fragment>
							<span> Manage Subscription</span>
						</Fragment>
					}
				/>
			</div>
		</div>
	);
};

export default Billing;
