import { Fragment, useState, useEffect, useRef } from 'react';
import AppButton from '../../../../appButton/AppButton';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import MediaPlaceholder from '../../../../placeholders/mediaPlaceholder/MediaPlaceholder';
import ScrollableContainer from '../../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import InboxMediaList from '../../../fileUpload/userMediaList/UserMediaList';
import { GetMediaList, SendMessage, UploadFile } from '../../../../../Services';
import { actionTypes } from '../../../../../reducer';
import { useStateValue } from '../../../../../StateProvider';
import './InboxMessagesFooter.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { isMobile } from '../../../../../common';

const InboxMessagesFooter = ({ callback }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ showUploadFilesDialog, setShowUploadFilesDialog ] = useState(false);
	const [ mediaList, setMediaList ] = useState([]);
	const [ { user, selectedMedia, selectedThread, newMessageSent, reloadInbox }, dispatch ] = useStateValue();
	const [ userCredits, setUserCredits ] = useState(user.credits);
	const messageBodyRef = useRef();
	const uploadFileRef = useRef();

	useEffect(
		() => {
			if (showUploadFilesDialog) {
				getMediaList();
			}
		},
		[ showUploadFilesDialog ]
	);

	const getMediaList = async () => {
		try {
			const response = await GetMediaList();

			if (response) {
				setMediaList(response);
			}
		} catch (error) {}

		setIsLoading(false);
	};

	const onMessageTypeButtonClick = (e) => {
		//remove active class from all elements
		var buttonContainer = document.querySelectorAll('.send-message-action button');

		buttonContainer.forEach(function(item) {
			item.classList.remove('btn-primary');
			item.classList.add('btn-link');
		});

		e.currentTarget.classList.remove('btn-link');
		e.currentTarget.classList.add('btn-primary');
	};

	const toggleUploadFilesDialog = () => {
		setShowUploadFilesDialog((prev) => !prev);
	};

	const attachFiles = () => {
		setShowUploadFilesDialog((prev) => !prev);
	};

	const removeAttachment = (e) => {
		const selectedAttachment = e.currentTarget;
		var id = selectedAttachment.getAttribute('data-id');

		var tempSelectedMediaItems = selectedMedia;

		tempSelectedMediaItems = tempSelectedMediaItems.filter((obj) => obj.id !== id);

		dispatch({
			type          : actionTypes.SELECTED_MEDIA,
			selectedMedia : tempSelectedMediaItems
		});
	};

	const sendMessage = async () => {
		var tempSelectedMedia = [];

		if (selectedMedia !== null && selectedMedia.length > 0) {
			for (let i = 0; i < selectedMedia.length; i++) {
				tempSelectedMedia.push(selectedMedia[i].id);
			}
		}

		var messageData = {
			threadId     : selectedThread.id,
			body         : messageBodyRef.current.value,
			fallbackBody : '',
			attachments  : tempSelectedMedia,
			links        : null
		};

		const response = await SendMessage(messageData);

		if (response && response.error) {
			let errorMessage = response.error;
			if (errorMessage.indexOf("'To'") > -1) {
				errorMessage = errorMessage.replace("'To' ", '');
			}

			toast.error(errorMessage);
			//setIsSaving(false);

			return;
		}

		if (response !== undefined) {
			//clear fields
			messageBodyRef.current.value = '';

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});

			dispatch({
				type           : actionTypes.NEW_MESSAGE_SENT,
				newMessageSent : true
			});

			dispatch({
				type        : actionTypes.RELOAD_INBOX,
				reloadInbox : true
			});

			if (callback) {
				callback();
			}

			if (response.IsSuccessful) {
			} else {
				toast.error(response.Message);
			}
		} else {
			toast.error('Message was not sent.');
		}
	};

	const onMessageBodyKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			sendMessage();
		}
	};

	const onFileUpload = (e) => {
		e.preventDefault();

		let uploadButton = uploadFileRef.current;

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const onFileChange = async (e) => {
		//upload file on server
		let files = uploadFileRef.current.files;

		if (files !== undefined) {
			var data = new FormData();

			for (var i = 0; i !== files.length; i++) {
				data.append('files', files[i]);
			}

			const response = await UploadFile(data);

			if (response !== undefined) {
				getMediaList();

				// dispatch({
				// 	type        : actionTypes.SET_PROJECT_DATA,
				// 	projectData : tempProjectData
				// });
			}
		}
	};

	return (
		<div className="inbox-messages-footer-container">
			<div className="row send-message-text">
				<div className="col-12">
					<textarea
						rows={isMobile() ? '1' : '3'}
						className="form-control"
						placeholder="Write Your Message"
						ref={messageBodyRef}
						disabled={selectedThread === undefined || selectedThread === null}
						onKeyDown={onMessageBodyKeyDown}
					/>
					{selectedMedia !== null &&
					selectedMedia.length > 0 && (
						<div className="send-message-attachments-area">
							{selectedMedia.map((mediaItem) => (
								<div className="attachment-item" key={mediaItem.id} id={mediaItem.id}>
									<Link to={mediaItem.url} target="_blank">
										<span>{mediaItem.name}</span>
									</Link>
									<button
										className="btn btn-link attachment-item-remove"
										data-id={mediaItem.id}
										onClick={removeAttachment}
									>
										<i className="fa-solid fa-xmark p-0 m-0" />
									</button>
								</div>
							))}
						</div>
					)}
					<div className="send-message-actions">
						<div className="send-message-action">
							<AppButton
								className="link"
								children={<i className="fa-solid fa-paperclip mx-2" />}
								onClick={toggleUploadFilesDialog}
								disabled={selectedThread === undefined || selectedThread === null}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="row send-message-area">
				<div className="col-3">
					{/* <div className={`user-credits ${userCredits < 10 ? 'warning' : ''}`}>Credits: {userCredits}</div> */}
				</div>
				<div className="col-9 text-end">
					<AppButton
						spinnerText={`Sending...`}
						children={<span>Send</span>}
						onClick={sendMessage}
						disabled={selectedThread === undefined || selectedThread === null}
					/>
				</div>
			</div>

			{showUploadFilesDialog && (
				<ModalDialog>
					<ModalDialogContent>
						<h4 className="mb-3">Attach files</h4>
						<Fragment>
							{isLoading ? (
								<ScrollableContainer className="media-list-container container">
									<ScrollableContainerContent>
										<MediaPlaceholder />
										<MediaPlaceholder />
										<MediaPlaceholder />
									</ScrollableContainerContent>
								</ScrollableContainer>
							) : (
								<ScrollableContainer className="media-list-container container">
									<ScrollableContainerContent>
										<InboxMediaList mediaList={mediaList} />
									</ScrollableContainerContent>
								</ScrollableContainer>
							)}
						</Fragment>
					</ModalDialogContent>
					<ModalDialogButtons shaded>
						<AppButton children={<span>Attach</span>} onClick={attachFiles} />
						<AppButton children={<span>Upload</span>} onClick={onFileUpload} />
						<AppButton
							className="outline-primary"
							children={<span>Cancel</span>}
							onClick={toggleUploadFilesDialog}
						/>
						<input
							type="file"
							onChange={onFileChange}
							className="d-none"
							id="uploadFile"
							multiple
							ref={uploadFileRef}
						/>
					</ModalDialogButtons>
				</ModalDialog>
			)}
		</div>
	);
};

export default InboxMessagesFooter;
