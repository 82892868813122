import './TablePlaceholder.css';

const TablePlaceholder = () => {
	return (
		// <div className="message message-placeholder">
		// 	<div className="icon placeholder-glow">
		// 		<div className="icon-circle placeholder" />
		// 	</div>
		// 	<div className="content placeholder-glow">
		// 		<span className="placeholder placeholder-lg col-4" />
		// 		<span className="placeholder col-12" />
		// 	</div>
		// </div>
		<div className="table-responsive">
			<table className="table table-borderless">
				<thead className="table-secondary">
					<tr>
						<th scope="col" className="table-column placeholder-glow">
							<span className="placeholder placeholder-lg col-12" />
						</th>
						<th scope="col" className="table-column placeholder-glow">
							<span className="placeholder placeholder-lg col-12" />
						</th>
						<th scope="col" className="table-column placeholder-glow">
							<span className="placeholder placeholder-lg col-12" />
						</th>
						<th scope="col" className="table-column placeholder-glow">
							<span className="placeholder placeholder-lg col-12" />
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
					</tr>
					<tr>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
					</tr>
					<tr>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
					</tr>
					<tr>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
					</tr>
					<tr>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
						<td className="table-column placeholder-glow">
							<span className="placeholder col-12" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default TablePlaceholder;
