import { useState, useEffect, useRef, Fragment } from 'react';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import './UserMediaList.css';

const UserMediaList = ({ mediaList, multiSelect = true }) => {
	const [ selectedMediaItems, setSelectedMediaItems ] = useState([]);
	const [ { selectedMedia }, dispatch ] = useStateValue();

	useEffect(() => {
		if (selectedMedia) {
			setSelectedMediaItems(selectedMedia);
		}
	}, []);

	const onMediaSelect = (e) => {
		const selectedItem = e.currentTarget;

		var tempSelectedMediaItems = selectedMediaItems;
		var isSelected = selectedItem.classList.contains('active');

		if (isSelected) {
			selectedItem.classList.remove('active');

			tempSelectedMediaItems = tempSelectedMediaItems.filter((obj) => obj.id !== selectedItem.id);
		} else {
			//if multiSelect is false, remove selection from all other items
			if (!multiSelect) {
				tempSelectedMediaItems = [];
			}

			selectedItem.classList.add('active');

			tempSelectedMediaItems.push({
				id   : selectedItem.id,
				name : selectedItem.getAttribute('data-name'),
				url  : selectedItem.getAttribute('data-url')
			});
		}

		setSelectedMediaItems(tempSelectedMediaItems);

		dispatch({
			type          : actionTypes.SELECTED_MEDIA,
			selectedMedia : tempSelectedMediaItems
		});
	};

	return (
		<Fragment>
			{mediaList &&
				mediaList.length > 0 &&
				mediaList.map((mediaItem) => (
					<div
						className={`media-item-container row mx-0 ${selectedMediaItems != null &&
						selectedMediaItems.length > 0 &&
						selectedMediaItems.findIndex((obj) => obj.id === mediaItem.id) > -1
							? 'active'
							: ''}`}
						key={mediaItem.id}
						id={mediaItem.id}
						data-name={mediaItem.name}
						data-url={mediaItem.mediaUrl}
						onClick={onMediaSelect}
					>
						<div className="media-item-name col-10">
							<span>{mediaItem.name}</span>
						</div>
						<div className="media-item-checked col-2 text-end">
							<i className="fa-solid fa-check" />
						</div>
					</div>
				))}
		</Fragment>
	);
};

export default UserMediaList;
