import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ContactsSidebar from './contactsSidebar/ContactsSidebar';
import ContactsContentArea from './contactsContentArea/ContactsContentArea';
import MainAppContentHeader from '../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import './Contacts.css';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import Footer from '../footer/Footer';

const Contacts = ({ type, showSidebar = true, title = 'Contacts' }) => {
	return (
		<div className="app-contacts-container panel">
			<ScrollableContainer className="contacts-content-container">
				<ScrollableContainerHeader>
					{type === 'custom-fields' ? (
						<MainAppContentHeader title={title} showGoBackLink={true} goBackUrl="/tools" />
					) : (
						<MainAppContentHeader title={title} />
					)}
				</ScrollableContainerHeader>
				<ScrollableContainerContent>
					{showSidebar && <ContactsSidebar type={type} />}
					<ContactsContentArea type={type} showSidebar={showSidebar} />
					<Footer />
				</ScrollableContainerContent>
			</ScrollableContainer>
		</div>
	);
};

export default Contacts;
