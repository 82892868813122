import './SidebarCloseButton.css';
import { Link } from 'react-router-dom';

const SidebarCloseButton = ({ onSidebarClose, redirect }) => {
	const handleCloseSidebar = () => {
		if (onSidebarClose) {
			onSidebarClose();
		}
	};

	return (
		<Link
			to={redirect ? redirect : '#'}
			className="sidebar-collapse-button"
			onClick={handleCloseSidebar}
			title="Close"
		>
			<i className="fa-solid fa-angles-right" />
		</Link>
	);
};

export default SidebarCloseButton;
