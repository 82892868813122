import { useState, useEffect, useRef } from 'react';
import { Fragment } from 'react';
import { actionTypes } from '../../../../../../reducer';
import AppButton from '../../../../../appButton/AppButton';
import { useStateValue } from '../../../../../../StateProvider';
import { DeleteMessage } from '../../../../../../Services';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import './MessageDetails.css';
import { Link } from 'react-router-dom';

const MessageDetails = ({ message }) => {
	const [ { selectedThread, reloadInbox }, dispatch ] = useStateValue();
	const messageRef = useRef();
	const [ showElement, setShowElement ] = useState(true);

	const deleteMessage = async (e) => {
		if (window.confirm('Are you sure you want to delete the message?')) {
			var messageId = e.currentTarget.getAttribute('data-id');

			const response = await DeleteMessage(messageId);

			if (response !== undefined) {
				dispatch({
					type        : actionTypes.RELOAD_INBOX,
					reloadInbox : true
				});

				setShowElement(false);
			}
		}
	};

	const isFileImage = (string) => {
		let imageTypes = [ 'image/png', 'image/jpeg', 'image/svg+xml', 'image/gif' ];
		let isImage = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(string) || imageTypes.includes(string);
		//console.log(url + ': ' + isImage);

		return isImage;
	};

	const setFileIcon = (string) => {
		if (string.indexOf('.pdf') > -1) {
			return <i className="fa-solid fa-file-pdf" />;
		} else if (string.indexOf('.doc') > -1 || string.indexOf('.docx') > -1) {
			return <i className="fa-solid fa-file-word" />;
		} else if (string.indexOf('.xls') > -1 || string.indexOf('.xlsx') > -1) {
			return <i className="fa-solid fa-file-excel" />;
		} else if (string.indexOf('.csv') > -1) {
			return <i className="fa-solid fa-file-csv" />;
		} else {
			return <i className="fa-solid fa-file" />;
		}
	};

	return (
		<Fragment>
			{showElement && (
				<div className={`message-container ${message.direction}`} ref={messageRef}>
					<div className="message-body">
						<span>{message.body}</span>
						{message.externalMessageMedia &&
						message.externalMessageMedia.length > 0 && (
							<div className="message-attachments">
								{message.externalMessageMedia.map((mediaItem) => (
									<div className="message-attachment-item" key={mediaItem.id} id={mediaItem.id}>
										{isFileImage(
											message.direction === 'inbound' ? mediaItem.mediaType : mediaItem.mediaUrl
										) ? (
											<a href={mediaItem.mediaUrl} target="_blank" className="image">
												<img src={mediaItem.mediaUrl} />
											</a>
										) : (
											<a href={mediaItem.mediaUrl} target="_blank" className="file">
												{setFileIcon(mediaItem.mediaUrl)}
												<span>{mediaItem.name ? mediaItem.name : 'file'}</span>
											</a>
										)}
									</div>
								))}
							</div>
						)}
						<span className="delete-message">
							<button className="btn btn-link p-0" data-id={message.id} onClick={deleteMessage}>
								<i className="fa-solid fa-trash" />
							</button>
						</span>
					</div>
					<div className="message-time">
						<Fragment>
							<span>
								<Fragment>
									{message.campaign ? (
										'Broadcast '
									) : message.keyword ? (
										'Keyword Response '
									) : message.autoresponder ? (
										'Drip Campaign Response '
									) : (
										''
									)}
									<Moment format="MM/DD/YYYY hh:mm a">{message.dateCreated}</Moment>
									{' EST'}
								</Fragment>
							</span>
						</Fragment>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default MessageDetails;
