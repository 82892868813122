export const getToken = () => {
	return window.localStorage.getItem('tokenId');
};

export const setToken = (token) => {
	window.localStorage.setItem('tokenId', token);
};

export const deleteToken = () => {
	window.localStorage.removeItem('tokenId');
};
