import { useState, useEffect, Fragment } from 'react';
import ContactsListHeaderPlaceholder from '../../../placeholders/contactsListHeaderPlaceholder/ContactsListHeaderPlaceholder';
import TablePlaceholder from '../../../placeholders/tablePlaceholder/TablePlaceholder';
import Contacts from './contactsContent/contacts/Contacts';
import ContactLists from './contactsContent/contactLists/ContactLists';
import CustomFields from './contactsContent/customFields/CustomFields';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ContactsHeader from './contactsHeader/contacts/ContactsHeader';
import ContactListsHeader from './contactsHeader/contactLists/ContactListsHeader';
import CustomFieldsHeader from './contactsHeader/customFields/CustomFieldsHeader';
import { GetContacts, GetContactLists, GetCustomFields } from '../../../../Services';
import { useParams, useLocation } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import './ContactsContentArea.css';

const ContactsContentArea = ({ type, showSidebar = true }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ contacts, setContacts ] = useState([]);
	const [ contactLists, setContactLists ] = useState([]);
	const [ customFields, setCustomFields ] = useState([]);
	const [ { searchQuery, isContactsSearch }, dispatch ] = useStateValue();
	const { id } = useParams();
	const location = useLocation();

	useEffect(
		() => {
			if (type === 'lists') {
				getContactLists(searchQuery);
			} else if (type === 'custom-fields') {
				getCustomFields(searchQuery);
			} else {
				getContacts(searchQuery);
			}
		},
		[ id, location, searchQuery ]
	);

	const getContacts = async (query) => {
		try {
			let data = { query: query, isContactsSearch: isContactsSearch };
			const response = await GetContacts(data);

			if (response) {
				setContacts(response);
			}
		} catch (error) {}

		setIsLoading(false);
	};

	const getContactLists = async (query) => {
		try {
			let data = { query: query };
			const response = await GetContactLists(data);

			if (response) {
				setContactLists(response);
			}
		} catch (error) {}

		setIsLoading(false);
	};

	const getCustomFields = async (query) => {
		try {
			let data = { query: query };
			const response = await GetCustomFields(data);

			if (response) {
				//show default first
				response.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));

				setCustomFields(response);
			}
		} catch (error) {}

		setIsLoading(false);
	};

	return (
		<ScrollableContainer className={`contacts-content-area ${!showSidebar && 'p-0'}`}>
			{isLoading ? (
				<Fragment>
					<ScrollableContainerHeader>
						<ContactsListHeaderPlaceholder />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<TablePlaceholder />
					</ScrollableContainerContent>
				</Fragment>
			) : (
				<Fragment>
					<ScrollableContainerHeader>
						{type === 'lists' ? (
							<ContactListsHeader callback={() => getContactLists(searchQuery)} active={true} />
						) : type === 'custom-fields' ? (
							<CustomFieldsHeader callback={() => getCustomFields(searchQuery)} active={true} />
						) : (
							<ContactsHeader callback={() => getContacts(searchQuery)} active={true} />
						)}
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						{type === 'lists' ? (
							<ContactLists lists={contactLists} callback={() => getContactLists(searchQuery)} />
						) : // <ContactLists id={id} lists={contactLists} callback={getContactLists} />
						type === 'custom-fields' ? (
							<CustomFields fields={customFields} callback={() => getCustomFields(searchQuery)} />
						) : (
							<Contacts contacts={contacts} callback={() => getContacts(searchQuery)} />
						)}
					</ScrollableContainerContent>
				</Fragment>
			)}
		</ScrollableContainer>
	);
};

export default ContactsContentArea;
