import './WebFormCodeDialog.css';
import { useState, useEffect, useRef } from 'react';
import { useStateValue } from '../../../../StateProvider';
import reducer from '../../../../reducer';
import AppButton from '../../../appButton/AppButton';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { GetWebFormCode } from '../../../../Services';
const WebFormCodeDialog = ({ webForm, onClose }) => {
	const [ webFormCode, setWebFormCode ] = useState('');
	const [ { phoneNumber }, dispatch ] = useStateValue();
	const codeRef = useRef();

	useEffect(
		() => {
			getCode();
		},
		[ webForm ]
	);

	const getCode = async () => {
		let tempFormData = {};
		tempFormData.id = webForm.id;
		tempFormData.phoneNumberId = phoneNumber.id;

		const response = await GetWebFormCode(tempFormData);

		if (response !== undefined) {
			setWebFormCode(response);
		}
	};

	const handleCopy = () => {
		navigator.clipboard.writeText(codeRef.current.value);
	};

	const handleOnCancel = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<ModalDialog>
			<ModalDialogContent>
				<h4 className="mb-3">Get code</h4>
				<div className="form-inputs">
					<div className="form-group mb-3">
						<label htmlFor="name">HTML</label>
						<textarea className="form-control" defaultValue={webFormCode} rows={8} ref={codeRef} />
					</div>
				</div>
			</ModalDialogContent>
			<ModalDialogButtons shaded>
				<AppButton className="primary" children={<span>Copy</span>} onClick={handleCopy} />
				<AppButton className="outline-primary" children={<span>Cancel</span>} onClick={handleOnCancel} />
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default WebFormCodeDialog;
