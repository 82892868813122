import './ScrollableContainer.css';

const ScrollableContainer = ({ className = '', children }) => {
	return (
		<div className={`app-scrollable-container ${className}`}>
			{children}
		</div>
	);
};

export default ScrollableContainer;
