import KeywordsContentArea from './keywordsContentArea/KeywordsContentArea';
import { Route, Routes } from 'react-router';
import './Keywords.css';

const Keywords = () => {
	return (
		<div className="app-keywords-container panel">
			<Routes>
				<Route path="/:id" element={<KeywordsContentArea />} />
				<Route path="/add" element={<KeywordsContentArea />} />
				<Route path="/*" element={<KeywordsContentArea />} />
			</Routes>
		</div>
	);
};

export default Keywords;
