import { Fragment, useEffect, useState, useRef } from 'react';
import './CustomContentForm.css';
import AppButton from '../../../../appButton/AppButton';
import { useStateValue } from '../../../../../StateProvider';
import { actionTypes } from '../../../../../reducer';
import { toast } from 'react-toastify';
import ScrollableContainer from '../../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerFooter from '../../../../scrollableContainer/scrollableContainerFooter/ScrollableContainerFooter';
import {
	GetCustomContent,
	AddCustomContent,
	EditCustomContent,
	DeleteCustomContent,
	GetBillingPlans
} from '../../../../../Services';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import FileUploadDialog from '../../../fileUpload/FileUploadDialog';
import CodeMirror from '@uiw/react-codemirror';
import Select from 'react-select';
import AppLinkGoBack from '../../../../appLinkGoBack/AppLinkGoBack';

const CustomContentForm = ({ id, showForm }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ { user, selectedMedia, reloadCustomContent }, dispatch ] = useStateValue();
	const [ formData, setFormData ] = useState({});
	const [ htmlContent, setHtmlContent ] = useState('');
	const [ iconUrl, setIconUrl ] = useState('');
	const [ plans, setPlans ] = useState([]);
	const [ selectedPlans, setSelectedPlans ] = useState([]);
	const [ showUploadFilesDialog, setShowUploadFilesDialog ] = useState(false);
	const refCustomContentContainer = useRef();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(
		() => {
			getCustomContent();
		},
		[ location ]
	);

	useEffect(
		() => {
			if (!showForm && refCustomContentContainer.current) {
				refCustomContentContainer.current.innerHTML = htmlContent;
			}
		},
		[ htmlContent ]
	);

	const getCustomContent = async () => {
		let tempPlans = await getPlans();

		if (id) {
			setIsEdit(true);

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});

			const response = await GetCustomContent(id);

			if (response) {
				let tempFormData = response;

				//icon
				let tempIconData = [];
				if (tempFormData.icon) {
					tempIconData.push({
						url : tempFormData.icon
					});
				}

				//plans
				let tempCustomContentPlans = [];
				if (response.plans !== undefined && response.plans.length > 0) {
					for (let i = 0; i < response.plans.length; i++) {
						tempCustomContentPlans.push({
							value : response.plans[i].externalPlanId,
							label : getPlanNameById(tempPlans, response.plans[i].externalPlanId)
						});
					}
				}

				dispatch({
					type          : actionTypes.SELECTED_MEDIA,
					selectedMedia : tempIconData
				});

				setHtmlContent(tempFormData.content);
				setIconUrl(tempFormData.icon);
				setSelectedPlans(tempCustomContentPlans);
				setFormData(tempFormData);
			}
		} else {
			setFormData({
				name    : '',
				type    : '',
				content : ''
			});

			dispatch({
				type          : actionTypes.SELECTED_MEDIA,
				selectedMedia : null
			});

			setHtmlContent('');
			setIconUrl('');
			setSelectedPlans([]);
			setIsEdit(false);
		}

		setIsLoading(false);
	};

	const getPlans = async () => {
		const response = await GetBillingPlans();

		if (response !== undefined) {
			let tempPlans = [];
			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					tempPlans.push({ value: response[i].id, label: response[i].name });
				}
			}

			setPlans(tempPlans);

			return tempPlans;
		}
	};

	const getPlanNameById = (plans, planId) => {
		if (plans) {
			let plan = plans.find((x) => x.value === planId);

			return plan ? plan.label : '';
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleContentChange = (value) => {
		setHtmlContent(value);
	};

	const handleSelectChange = (option, action) => {
		if (action.name === 'customContentPlans') {
			setSelectedPlans(option);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempFormData = formData;
		tempFormData.content = htmlContent;
		tempFormData.icon = iconUrl;

		//prepare plans for POST action
		let tempCustomContentPlans = [];

		if (selectedPlans) {
			for (let i = 0; i < selectedPlans.length; i++) {
				tempCustomContentPlans.push(selectedPlans[i].value);
			}
		}

		tempFormData.planIds = tempCustomContentPlans;

		let response = null;

		if (id) {
			response = await EditCustomContent(id, tempFormData);
		} else {
			response = await AddCustomContent(tempFormData);
		}

		if (response && response.error) {
			toast.error(response.error);
			setIsSaving(false);

			return;
		}

		if (response !== undefined) {
			dispatch({
				type                : actionTypes.RELOAD_CUSTOMCONTENT,
				reloadCustomContent : true
			});

			if (!isEdit) {
				navigate('/flexiblecontent/' + response);
			}
		} else {
			//toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const deleteCustomContent = async () => {
		if (window.confirm('Are you sure you want to delete the flexible content item?')) {
			const response = await DeleteCustomContent(id);

			if (response !== undefined) {
				dispatch({
					type                : actionTypes.RELOAD_CUSTOMCONTENT,
					reloadCustomContent : true
				});

				navigate('/flexiblecontent');
			}
		}
	};

	const toggleUploadFilesDialog = () => {
		setShowUploadFilesDialog((prev) => !prev);

		if (selectedMedia !== null && selectedMedia.length > 0) {
			setIconUrl(selectedMedia[0].url);
		}
	};

	const removeAttachment = () => {
		setIconUrl('');
	};

	return (
		<Fragment>
			{!isLoading ? (
				<Fragment>
					{showForm ? (
						<ScrollableContainer className="customcontent-form-container">
							<ScrollableContainerHeader>
								<div className="form-header-title row mx-0">
									<div className="col-12 ps-0">
										<AppLinkGoBack redirect="/flexiblecontent" />
										<span>{id ? 'Flexible Content Details' : 'Create Flexible Content'}</span>
									</div>
								</div>
							</ScrollableContainerHeader>
							<ScrollableContainerContent>
								<form>
									<div className="form-inputs">
										<div className="form-group mb-3">
											<label htmlFor="name">Name</label>
											<input
												type="text"
												id="name"
												name="name"
												className="form-control"
												value={formData.name}
												disabled={isLoading || isSaving}
												onChange={handleChange}
											/>
										</div>
										<div className="form-group mb-3">
											<label htmlFor="content">Content</label>
											<CodeMirror
												value={htmlContent}
												height="250px"
												onChange={handleContentChange}
												className="html-editor"
											/>
										</div>
										<div className="form-group mb-0">
											<label htmlFor="icon">Icon</label>
										</div>
										<div className="form-group mb-4">
											{iconUrl && (
												<Fragment>
													<div className="icon-preview">
														<img alt="icon" src={iconUrl} />
														<AppButton
															children={<i className="fa-solid fa-xmark p-0 m-0" />}
															className="link attachment-item-remove"
															onClick={removeAttachment}
															disabled={isLoading || isSaving}
														/>
													</div>
												</Fragment>
											)}
											<AppButton
												children={<span>{iconUrl ? 'Change' : 'Upload'}</span>}
												onClick={toggleUploadFilesDialog}
												disabled={isLoading || isSaving}
											/>
										</div>
										<div className="form-group mb-3">
											<label htmlFor="customContentPlans">Plans</label>
											<Select
												name="customContentPlans"
												id="customContentPlans"
												isMulti={true}
												value={selectedPlans}
												isDisabled={isLoading || isSaving}
												onChange={handleSelectChange}
												options={plans}
												className="react-select-component"
												classNamePrefix="react-select"
											/>
										</div>
										<div className="form-group mb-0">
											<label htmlFor="hideFromNavigation">Hide From Navigation</label>
										</div>
										<div className="form-group form-switch mb-3">
											<input
												className="form-check-input"
												type="checkbox"
												role="switch"
												id="hideFromNavigation"
												name="hideFromNavigation"
												checked={formData.hideFromNavigation}
												disabled={isLoading || isSaving}
												onChange={handleChange}
											/>
										</div>
									</div>
								</form>
							</ScrollableContainerContent>
							<ScrollableContainerFooter>
								<div className="container-buttons">
									<button
										type="button"
										className="btn btn-primary"
										onClick={handleSubmit}
										disabled={isLoading || isSaving}
									>
										{!isSaving ? (
											<Fragment>
												<span>Save</span>
											</Fragment>
										) : (
											<Fragment>
												<span>Saving...</span>
											</Fragment>
										)}
									</button>
									{isEdit && (
										<AppButton
											className="danger"
											children={<span>Delete</span>}
											onClick={deleteCustomContent}
										/>
									)}
								</div>
							</ScrollableContainerFooter>
						</ScrollableContainer>
					) : (
						<ScrollableContainer>
							<ScrollableContainerContent>
								<div ref={refCustomContentContainer} />
							</ScrollableContainerContent>
						</ScrollableContainer>
					)}
				</Fragment>
			) : (
				<div className="text-center p-3">{/* <Spinner /> */}</div>
			)}
			{showUploadFilesDialog && <FileUploadDialog toggleDialog={toggleUploadFilesDialog} multiSelect={false} />}
		</Fragment>
	);
};

export default CustomContentForm;
