import './MainApp.css';
import LeftNavigation from './leftNavigation/LeftNavigation';
import MainAppContentArea from './mainAppContentArea/MainAppContentArea';
import { Route, Routes } from 'react-router';
import Inbox from './inbox/Inbox';
import Contacts from './contacts/Contacts';
import Broadcasts from './broadcasts/Broadcasts';
import Autoresponders from './autoresponders/Autoresponders';
import Keywords from './keywords/Keywords';
import WebForms from './webForms/WebForms';
import Administration from '../administration/Administration';
import Account from './account/Account';
import Analytics from './analytics/Analytics';
import CustomContent from './customContent/CustomContent';
import Dashboard from './dashboard/Dashboard';
import Tools from './tools/Tools';
import ContactsContentArea from './../mainApp/contacts/contactsContentArea/ContactsContentArea';
import AccountIntegration from './account/accountContentArea/accountIntegration/AccountIntegration';
import Logs from '../administration/logs/Logs';

const MainApp = () => {
	return (
		<div className="app-container">
			<LeftNavigation />
			<MainAppContentArea>
				{/* an example on how to handle routing of the main app content area content */}
				<Routes>
					<Route path="/inbox/*" element={<Inbox />} />
					<Route path="/tools/autoresponders/*" element={<Autoresponders />} />
					<Route path="/tools/keywords/*" element={<Keywords />} />
					<Route path="/tools/webforms/*" element={<WebForms />} />
					<Route
						path="/tools/customfields/*"
						element={<Contacts type="custom-fields" showSidebar={false} title="Custom Fields" />}
					/>
					<Route path="/tools/integration/*" element={<AccountIntegration />} />
					<Route path="/tools/*" element={<Tools />} />
					<Route path="/campaigns/*" element={<Broadcasts />} />
					<Route path="/contacts/lists/*" exact element={<Contacts type="lists" />} />
					<Route path="/contacts" element={<Contacts type="contacts" />} />
					<Route path="/account/*" element={<Account />} />
					<Route path="/analytics/*" element={<Analytics />} />
					<Route path="/administration/log/*" element={<Logs />} />
					<Route path="/administration/*" element={<Administration />} />
					{/* <Route path="/flexiblecontent/page/*" element={<CustomContent showForm={false} />} /> */}
					<Route path="/flexiblecontent/*" element={<CustomContent />} />
					<Route path="/" element={<Dashboard />} />
				</Routes>
				{/* <Footer /> */}
			</MainAppContentArea>
		</div>
	);
};

export default MainApp;
