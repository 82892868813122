import ProfileIcon from '../../profileIcon/ProfileIcon';
import './LeftNavigation.css';
import LeftNavigationHeader from './leftNavigationHeader/LeftNavigationHeader';
import LeftNavigationLinks from './leftNavigationLinks/LeftNavigationLinks';
import { useRef, useState } from 'react';
import { isMobile } from '../../../common';
import { Fragment } from 'react';
import smallLogo from '../../../img/logo.png';
import MainAppContentHeaderQuickActions from '../mainAppContentArea/mainAppContentHeader/mainAppContentHeaderQuickActions/MainAppContentHeaderQuickActions';
import { useStateValue } from '../../../StateProvider';
import { Link } from 'react-router-dom';

const LeftNavigation = () => {
	const [ collapsed, setCollapsed ] = useState(false);
	const leftNavHeaderRef = useRef();
	const collapseButtonIconRef = useRef();
	const navMenuOpenerMobileRef = useRef();
	const navMenuMobileRef = useRef();
	const [ { user }, dispatch ] = useStateValue();

	const toggleLeftNavBar = () => {
		leftNavHeaderRef.current.classList.toggle('collapsed');
		collapseButtonIconRef.current.classList.toggle('collapsed');
		if (collapseButtonIconRef.current.classList.contains('fa-angles-left')) {
			collapseButtonIconRef.current.classList.remove('fa-angles-left');
			collapseButtonIconRef.current.classList.add('fa-angles-right');
			setCollapsed(false);
		} else {
			collapseButtonIconRef.current.classList.remove('fa-angles-right');
			collapseButtonIconRef.current.classList.add('fa-angles-left');
			setCollapsed(true);
		}
	};

	const toggleLeftNavBarMobile = () => {
		navMenuMobileRef.current.classList.toggle('collapsed');
		// navMenuOpenerMobileRef.current.classList.toggle('collapsed');

		if (navMenuOpenerMobileRef.current.classList.contains('fa-times')) {
			navMenuOpenerMobileRef.current.classList.remove('fa-times');
			navMenuOpenerMobileRef.current.classList.add('fa-bars');
			setCollapsed(false);
		} else {
			navMenuOpenerMobileRef.current.classList.remove('fa-bars');
			navMenuOpenerMobileRef.current.classList.add('fa-times');
			setCollapsed(true);
		}
	};

	const handleNavLinkClickMobile = () => {
		if (isMobile()) {
			toggleLeftNavBarMobile();
		}
	};

	return (
		<Fragment>
			{isMobile() ? (
				<Fragment>
					<div className="nav-mobile">
						<button onClick={toggleLeftNavBarMobile} className="nav-mobile-button">
							<i className="fas fa-bars" ref={navMenuOpenerMobileRef} />
						</button>
						<img className="nav-mobile-logo" src={smallLogo} />
						<div className="flex-fill-remaining-space" />
						{/* {user.isAdmin &&
						window.location.pathname === '/account' && (
							<Link to="/administration" className="admin-link-mobile">
								<i className="fas fa-user-cog" />
							</Link>
						)} */}
						<MainAppContentHeaderQuickActions />
					</div>
					<div className="nav-menu-mobile-wrapper collapsed" ref={navMenuMobileRef}>
						<LeftNavigationHeader collapsed={collapsed} />
						<LeftNavigationLinks onLinkClick={handleNavLinkClickMobile} />
						<ProfileIcon onLinkClick={handleNavLinkClickMobile} />
					</div>
				</Fragment>
			) : (
				<div className="app-left-nav-container" ref={leftNavHeaderRef}>
					<button className="app-left-nav-header-collapse-button" onClick={toggleLeftNavBar}>
						<i className="fa-solid fa-angles-left" ref={collapseButtonIconRef} />
					</button>
					<LeftNavigationHeader collapsed={collapsed} />
					<LeftNavigationLinks />
					<ProfileIcon />
				</div>
			)}
		</Fragment>
	);
};

export default LeftNavigation;
