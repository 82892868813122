import './AutoresponderPlaceholder.css';

const AutoresponderPlaceholder = () => {
	return (
		<div className="autoresponder autoresponder-placeholder">
			<div className="content placeholder-glow">
				<span className="placeholder placeholder-lg col-8" />
				<span className="placeholder placeholder-lg col-4" />
			</div>
		</div>
	);
};

export default AutoresponderPlaceholder;
