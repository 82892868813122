import { Link } from 'react-router-dom';
import './KeywordsSidebarHeader.css';
import SearchListInput from '../../../searchListInput/SearchListInput';

const KeywordsSidebarHeader = ({ items, callback }) => {
	return (
		<div className="keywords-sidebar-header-container">
			<div className="keywords-sidebar-header-search">
				<SearchListInput callback={callback} />
			</div>
			<div className="keywords-sidebar-header-add-button">
				<Link to="/tools/keywords/add" className="btn btn-primary btn-lg">
					<span>New</span>
				</Link>
			</div>
		</div>
	);
};

export default KeywordsSidebarHeader;
