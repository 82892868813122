import { useState, useEffect, Fragment } from 'react';
import KeywordsSidebarHeaderPlaceholder from '../../../placeholders/keywordsSidebarHeaderPlaceholder/KeywordsSidebarHeaderPlaceholder';
import KeywordPlaceholder from '../../../placeholders/keywordPlaceholder/KeywordPlaceholder';
import KeywordsList from './keywordsList/KeywordsList';
import ScrollableContainer from '../../../scrollableContainer/ScrollableContainer';
import ScrollableContainerContent from '../../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import ScrollableContainerHeader from '../../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import KeywordsSidebarHeader from './keywordsSidebarHeader/KeywordsSidebarHeader';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import { GetKeywords } from '../../../../Services';
import './KeywordsSidebar.css';

const KeywordsSidebar = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ keywords, setKeywords ] = useState([]);
	const [ { reloadKeywords, searchQuery }, dispatch ] = useStateValue();

	useEffect(() => {
		dispatch({
			type        : actionTypes.SEARCH_QUERY,
			searchQuery : null
		});
	}, []);

	useEffect(
		() => {
			getKeywords(searchQuery);

			let keywordsInterval = setInterval(() => {
				getKeywords(searchQuery);
			}, 5000);

			return () => {
				clearInterval(keywordsInterval);
			};
		},
		[ searchQuery ]
	);

	useEffect(
		() => {
			if (reloadKeywords) {
				getKeywords(searchQuery);

				dispatch({
					type           : actionTypes.RELOAD_KEYWORDS,
					reloadKeywords : false
				});
			}
		},
		[ reloadKeywords ]
	);

	const getKeywords = async (query) => {
		//console.log('keyword reloaded');
		let data = { query: query };
		const items = await GetKeywords(data);

		if (items) {
			//sort by date desc
			items.sort((a, b) => {
				let dateA = new Date(a.dateCreated),
					dateB = new Date(b.dateCreated);
				return dateB - dateA;
			});

			setKeywords(items);
		}

		setIsLoading(false);
	};

	return (
		<ScrollableContainer className="keywords-sidebar-container">
			{isLoading ? (
				<Fragment>
					<ScrollableContainerHeader>
						<KeywordsSidebarHeaderPlaceholder />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<KeywordPlaceholder />
						<KeywordPlaceholder />
						<KeywordPlaceholder />
						<KeywordPlaceholder />
						<KeywordPlaceholder />
					</ScrollableContainerContent>
				</Fragment>
			) : (
				<Fragment>
					<ScrollableContainerHeader>
						<KeywordsSidebarHeader items={keywords} callback={getKeywords} />
					</ScrollableContainerHeader>
					<ScrollableContainerContent>
						<KeywordsList items={keywords} />
					</ScrollableContainerContent>
				</Fragment>
			)}
		</ScrollableContainer>
	);
};

export default KeywordsSidebar;
