import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { GetCustomContentItems, GetPlanSubscription, GetUnreadMessages } from '../../../../Services';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import './LeftNavigationLinks.css';
import { Fragment } from 'react';
import IconDashboard from '../../../../img/house_icon.png';
import IconInbox from '../../../../img/inbox_icon.png';
import IconBroadcasts from '../../../../img/paper_airplane_icon.png';
import IconContacts from '../../../../img/contact_book_icon.png';
import IconTools from '../../../../img/lightning_icon.png';
import IconAnalytics from '../../../../img/chart_icon.png';
import IconAdministration from '../../../../img/settings_icon.png';

const LeftNavigationLinks = ({ onLinkClick }) => {
	const className = 'app-left-nav-link';
	const activeClassName = 'app-left-nav-link active';
	const [ { user, reloadCustomContent, reloadInbox }, dispatch ] = useStateValue();
	const [ customContent, setCustomContent ] = useState([]);
	const [ totalUnread, setTotalUnread ] = useState(0);
	const [ supportedCustomContentItems, setSupportedCustomContentItems ] = useState([]);

	useEffect(
		() => {
			getUnreadMessages();
		},
		[ reloadInbox ]
	);

	useEffect(
		() => {
			getUnreadMessages();
			getCustomContent(null);
		},
		[ reloadCustomContent ]
	);

	const getCustomContent = async (query) => {
		let data = { query: query };
		const items = await GetCustomContentItems(data);

		if (items) {
			//sort by date desc
			items.sort((a, b) => {
				let dateA = new Date(a.dateCreated),
					dateB = new Date(b.dateCreated);
				return dateB - dateA;
			});

			setCustomContent(items);

			//check user plans
			let tempSupportedCustomContentItems = [];

			if (user && user.plans) {
				let activePlan = user.plans.find((x) => x.status === 'Active');

				if (activePlan && activePlan.externalId && activePlan.externalId !== '') {
					const subscription = await GetPlanSubscription(activePlan.externalId);

					if (subscription && subscription.error) {
						//toast.error(subscription.error);

						return;
					}

					if (subscription && subscription.items) {
						for (let i = 0; i < subscription.items.length; i++) {
							let subscriptionProductId = subscription.items[i].productId;

							for (let j = 0; j < items.length; j++) {
								let customContentItem = items[j];

								if (customContentItem.plans && customContentItem.plans.length > 0) {
									for (let k = 0; k < customContentItem.plans.length; k++) {
										let customContentPlan = customContentItem.plans[k];

										if (
											customContentPlan.externalPlanId &&
											customContentPlan.externalPlanId == subscriptionProductId
										) {
											//found product, add it to the valid list
											tempSupportedCustomContentItems.push(customContentItem);
										}
									}
								}
							}
						}
					}
				}
			}

			setSupportedCustomContentItems(tempSupportedCustomContentItems);
		}
	};

	const getUnreadMessages = async () => {
		const response = await GetUnreadMessages();

		if (response) {
			setTotalUnread(response);
		} else {
			setTotalUnread(0);
		}
	};

	const handleLinkClick = () => {
		if (onLinkClick) {
			onLinkClick();
		}
	};

	return (
		<div className="app-left-nav-links">
			<NavLink
				to="/"
				className={({ isActive }) => (isActive ? activeClassName : className)}
				onClick={handleLinkClick}
			>
				<div className="app-left-nav-link-icon">
					<img src={IconDashboard} />
				</div>
				<span className="app-left-nav-link-text">Dashboard</span>
				<span className="app-left-nav-link-arrow">
					<i className="fa-solid fa-chevron-right" />
				</span>
			</NavLink>
			<NavLink
				to="/inbox"
				className={({ isActive }) => (isActive ? activeClassName : className)}
				onClick={handleLinkClick}
			>
				<div className="app-left-nav-link-icon">
					<img src={IconInbox} />
				</div>
				<span className="app-left-nav-link-text">Inbox</span>
				<Fragment>
					{totalUnread && totalUnread !== '' && totalUnread !== 0 ? (
						<span className="app-left-nav-link-unread-messages">{totalUnread}</span>
					) : (
						''
					)}
				</Fragment>
				<span className="app-left-nav-link-arrow">
					<i className="fa-solid fa-chevron-right" />
				</span>
			</NavLink>
			<NavLink
				to="/campaigns"
				className={({ isActive }) => (isActive ? activeClassName : className)}
				onClick={handleLinkClick}
			>
				<div className="app-left-nav-link-icon">
					<img src={IconBroadcasts} />
				</div>
				<span className="app-left-nav-link-text">Broadcasts</span>
				<span className="app-left-nav-link-arrow">
					<i className="fa-solid fa-chevron-right" />
				</span>
			</NavLink>
			<NavLink
				to="/contacts"
				className={({ isActive }) => (isActive ? activeClassName : className)}
				onClick={handleLinkClick}
			>
				<div className="app-left-nav-link-icon">
					<img src={IconContacts} />
				</div>
				<span className="app-left-nav-link-text">Contacts</span>
				<span className="app-left-nav-link-arrow">
					<i className="fa-solid fa-chevron-right" />
				</span>
			</NavLink>
			<NavLink
				strict="true"
				to="/tools"
				className={({ isActive }) => (isActive ? activeClassName : className)}
				onClick={handleLinkClick}
			>
				<div className="app-left-nav-link-icon">
					<img src={IconTools} />
				</div>
				<span className="app-left-nav-link-text">Tools</span>
				<span className="app-left-nav-link-arrow">
					<i className="fa-solid fa-chevron-right" />
				</span>
			</NavLink>
			<NavLink
				to="/analytics"
				className={({ isActive }) => (isActive ? activeClassName : className)}
				onClick={handleLinkClick}
			>
				<div className="app-left-nav-link-icon">
					<img src={IconAnalytics} />
				</div>
				<span className="app-left-nav-link-text">Analytics</span>
				<span className="app-left-nav-link-arrow">
					<i className="fa-solid fa-chevron-right" />
				</span>
			</NavLink>
			{supportedCustomContentItems &&
				supportedCustomContentItems.length > 0 &&
				supportedCustomContentItems.map((item, index) => (
					<Fragment key={item.id}>
						{!item.hideFromNavigation && (
							<NavLink
								key={item.id}
								to={`/flexiblecontent/page/${item.id}`}
								className={({ isActive }) => (isActive ? activeClassName : className)}
								onClick={handleLinkClick}
							>
								<div className="app-left-nav-link-icon">
									{item.icon && <img alt={item} src={item.icon} height="24" />}
								</div>
								<span className="app-left-nav-link-text">{item.name}</span>
								<span className="app-left-nav-link-arrow">
									<i className="fa-solid fa-chevron-right" />
								</span>
							</NavLink>
						)}
					</Fragment>
				))}
			{user.isAdmin && (
				<NavLink
					to="/administration"
					className={({ isActive }) => (isActive ? activeClassName : className)}
					onClick={handleLinkClick}
				>
					<div className="app-left-nav-link-icon">
						<img src={IconAdministration} />
					</div>
					<span className="app-left-nav-link-text">Administration</span>
					<span className="app-left-nav-link-arrow">
						<i className="fa-solid fa-chevron-right" />
					</span>
				</NavLink>
			)}
		</div>
	);
};

export default LeftNavigationLinks;
