import { useState, useEffect, Fragment, useRef } from 'react';
import { actionTypes } from '../../../../../reducer';
import { useStateValue } from '../../../../../StateProvider';
import {
	AddContactAndStartThread,
	DeleteMessageThread,
	GetContacts,
	GetMessageThread,
	StartMessageThreadIfNecessary
} from '../../../../../Services';
import AppButton from '../../../../appButton/AppButton';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import './InboxMessagesHeader.css';
import { isMobile } from '../../../../../common';
import { Link } from 'react-router-dom';
import AppLinkGoBack from '../../../../appLinkGoBack/AppLinkGoBack';

const InboxMessagesHeader = ({ onNewContact, onContactSelect, onToggleContactSidebar }) => {
	const [ { isNewThread, selectedThread, phoneNumber, isContactsSearch }, dispatch ] = useStateValue();
	const newContactInputRef = useRef();
	const [ contacts, setContacts ] = useState(0);
	const USPhoneRegex = /^\d{10}$/;
	const phoneRegex = /^\d+/g;

	useEffect(
		() => {
			if (isNewThread) {
				newContactInputRef.current.focus();
			}
		},
		[ isNewThread, selectedThread ]
	);

	const onNewContactKeyPress = async (e) => {
		let inputValue = e.target.value;

		if (!inputValue || inputValue === '') return;

		switch (e.key) {
			case 'Enter':
			case 'Tab':
				//remove all special chars from number
				inputValue = inputValue
					.replaceAll('(', '')
					.replaceAll(')', '')
					.replaceAll('+1', '')
					.replaceAll('+', '')
					.replaceAll('-', '')
					.replaceAll('_', '')
					.replaceAll(' ', '')
					.trim();

				let isValidPhoneNumber = false;

				const isPhoneNumber = phoneRegex.test(inputValue);

				// if (inputValue !== '') {
				// 	if (formUser.country === 'United States') {
				// 		if (USPhoneRegex.test(inputValue)) {
				// 			isValidPhoneNumber = true;
				// 		}
				// 	} else if (isPhoneNumber) {
				// 		isValidPhoneNumber = true;
				// 	}
				// }

				if (isPhoneNumber) {
					isValidPhoneNumber = true;
				}

				if (!isValidPhoneNumber) {
					toast.error('Please enter a valid phone number');
					return;
				}

				let data = {
					phoneNumberId      : phoneNumber.id,
					contactPhoneNumber : e.target.value
				};

				let response = await AddContactAndStartThread(data);

				if (response !== undefined) {
					//get thread object
					let threadResponse = await GetMessageThread(response);

					if (threadResponse !== undefined) {
						dispatch({
							type           : actionTypes.SELECTED_THREAD,
							selectedThread : threadResponse
						});
					}

					dispatch({
						type        : actionTypes.IS_NEW_THREAD,
						isNewThread : false
					});

					if (onNewContact) {
						onNewContact();
					}
				} else {
					toast.error(response.Message);
				}
			case 'Escape':
				cancelNewContact();
		}
		if (e.key === 'Enter') {
		} else {
			console.log(e.key);
		}
	};

	const getContacts = async (query) => {
		let data = { query: query, isContactsSearch: false };
		const response = await GetContacts(data);

		if (response !== undefined) {
			let tempContacts = [];
			if (response.length > 0) {
				for (let i = 0; i < response.length; i++) {
					tempContacts.push({
						value : response[i].id,
						label : response[i].fullName + ' ' + response[i].phoneNumberFriendly
					});
				}
			}
			setContacts(tempContacts);

			return tempContacts;
		}
	};

	const loadOptions = (inputValue) => {
		inputValue = inputValue
			.replaceAll('(', '')
			.replaceAll(')', '')
			.replaceAll('+1', '')
			.replaceAll('+', '')
			.replaceAll('-', '')
			.replaceAll('_', '')
			.replaceAll(' ', '')
			.trim();

		return getContacts(inputValue);
	};

	const handleSelectChange = async (option, action) => {
		if (action.name === 'contactsSelect') {
			if (action.action === 'select-option') {
				let contactId = option.value;

				let data = {};
				data.phoneNumber = {};
				data.phoneNumber.id = phoneNumber.id;
				data.contact = {};
				data.contact.id = contactId;

				//select contact and thread
				let threadId = await StartMessageThreadIfNecessary(data);

				if (threadId !== undefined) {
					let thread = await GetMessageThread(threadId);

					if (thread !== undefined) {
						dispatch({
							type           : actionTypes.SELECTED_THREAD,
							selectedThread : thread
						});

						// // set all messages as read
						// let setReadResponse = await SetThreadMessagesRead(thread.id);
					}
				}

				dispatch({
					type        : actionTypes.RELOAD_INBOX,
					reloadInbox : true
				});

				dispatch({
					type        : actionTypes.IS_NEW_THREAD,
					isNewThread : false
				});

				dispatch({
					type          : actionTypes.SELECTED_MEDIA,
					selectedMedia : null
				});
			}
		}
	};

	const cancelNewContact = () => {
		dispatch({
			type        : actionTypes.IS_NEW_THREAD,
			isNewThread : false
		});
	};

	const deleteMessageThread = async (e) => {
		if (window.confirm('Are you sure you want to delete the conversation?')) {
			const response = await DeleteMessageThread(selectedThread.id);

			if (response !== undefined) {
				dispatch({
					type           : actionTypes.SELECTED_THREAD,
					selectedThread : null
				});

				dispatch({
					type        : actionTypes.RELOAD_INBOX,
					reloadInbox : true
				});

				// if (onThreadDelete) {
				// 	onThreadDelete();
				// }
			}
		}
	};

	const showThreads = () => {
		dispatch({
			type           : actionTypes.SELECTED_THREAD,
			selectedThread : null
		});
	};

	return (
		<div className="inbox-messages-header-container row">
			{isNewThread ? (
				<div className="col-12 inbox-messages-header-title">
					<AsyncSelect
						name="contactsSelect"
						cacheOptions
						loadOptions={loadOptions}
						defaultOptions
						className="contacts-select"
						classNamePrefix="contacts"
						options={contacts}
						placeholder="format: 8005130456"
						ref={newContactInputRef}
						noOptionsMessage={() => null}
						loadingMessage={() => null}
						openMenuOnClick={false}
						onChange={handleSelectChange}
						onKeyDown={onNewContactKeyPress}
					/>
					<AppButton className="link btn-cancel" onClick={cancelNewContact} children={<span>Cancel</span>} />
				</div>
			) : (
				<Fragment>
					<div className="col-md-6 col-8 inbox-messages-header-title">
						{selectedThread && (
							<span>
								<AppLinkGoBack onClick={showThreads} />
								{selectedThread.contact &&
								selectedThread.contact.fullName &&
								selectedThread.contact.fullName.trim() !== '' ? (
									selectedThread.contact.fullName
								) : (
									selectedThread.contact.phoneNumberFriendly
								)}
							</span>
						)}
					</div>
					<div className="col-md-6 col-4 text-end inbox-messages-header-action-buttons">
						<button className="btn btn-link" onClick={onContactSelect}>
							<i className="fa-solid fa-address-card" />
						</button>
						<button className="btn btn-link" onClick={deleteMessageThread}>
							<i className="fa-solid fa-trash" />
						</button>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default InboxMessagesHeader;
