import { Fragment } from 'react';
import Loader from '../loader/Loader';
import './AppButton.css';

const AppButton = ({
	className = 'primary',
	type = 'button',
	disabled = false,
	showSpinner = false,
	title = '',
	spinnerText,
	onClick,
	children
}) => {
	return (
		<button
			type={type}
			className={`btn btn-${className}`}
			disabled={disabled || showSpinner}
			onClick={onClick}
			title={title}
		>
			{!showSpinner ? (
				<Fragment>{children}</Fragment>
			) : (
				<Fragment>
					<div className="loading">
						<Loader />
						{spinnerText &&
						spinnerText !== '' && (
							<Fragment>
								<span>{spinnerText}</span>
							</Fragment>
						)}
					</div>
				</Fragment>
			)}
		</button>
	);
};

export default AppButton;
