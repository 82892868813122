import './AutorespondersListItem.css';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const AutorespondersListItem = ({ item }) => {
	const location = useLocation();
	const [ autoresponderId, setAutoresponderId ] = useState('');
	const [ listItemNote, setListItemNote ] = useState('');
	const [ listItemNoteLists, setListItemNoteLists ] = useState('');

	useEffect(
		() => {
			setAutoresponderId(window.location.pathname.replace('/tools/autoresponders', '').replaceAll('/', ''));
		},
		[ location ]
	);

	useEffect(
		() => {
			let label = '';

			if (item.type === 'Immediate') {
				label = 'Sends immediately';
			} else if (item.type === 'Delay') {
				if (item.delayAmount && item.delayInterval) {
					label = `Delay ${item.delayAmount} ${item.delayInterval}`;
				}
			} else {
				if (item.delayAmount && item.delayInterval && item.customTimeFrame && item.customFieldName) {
					label = `${item.delayAmount} ${item.delayInterval.toLowerCase()} ${item.customTimeFrame.toLowerCase()} [${item.customFieldName}]`;

					if (item.autoresponderLists && item.autoresponderLists.length > 0) {
						label += ' for people in: ';
						let labelLists = '';

						for (let i = 0; i < item.autoresponderLists.length; i++) {
							if (item.autoresponderLists[i].contactList) {
								labelLists += item.autoresponderLists[i].contactList.name + ', ';
							}
						}

						labelLists = labelLists.substring(0, labelLists.length - 2);

						//label
						setListItemNoteLists(labelLists);
					}
				}
			}

			setListItemNote(label);
		},
		[ item ]
	);

	return (
		<div
			className={`autoresponder-item-container ${autoresponderId && autoresponderId === item.id ? 'active' : ''}`}
			id={item.id}
		>
			<Link to={`/tools/autoresponders/${item.id}`}>
				<div className="autoresponder-item-wrapper">
					<div className="autoresponder-name">
						<span>{item.name}</span>
					</div>
					<div className="autoresponder-sending-note">
						<div>
							<div>{listItemNote}</div>
							<div>{listItemNoteLists}</div>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default AutorespondersListItem;
