import './Dashboard.css';
import { useState } from 'react';
import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import { useStateValue } from '../../../StateProvider';
import ManageBroadcastsIcon from '../../../img/add_message_icon.png';
import ManageKeywordsIcon from '../../../img/mobile_icon.png';
import ManageWebformsIcon from '../../../img/form_icon.png';
import CreateContactsIcon from '../../../img/add_contact_icon.png';
import ManageListsIcon from '../../../img/contact_book_icon.png';
import MainAppContentHeader from '../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import Footer from '../footer/Footer';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { GetCustomerPortalSessionUrl } from '../../../Services';
import AppButton from '../../appButton/AppButton';

const Dashboard = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isWorking, setIsWorking ] = useState(false);

	const createCustomerPortalSession = async () => {
		setIsWorking(true);

		const response = await GetCustomerPortalSessionUrl();

		if (response && response.error) {
			setIsWorking(false);
			toast.error(response.error);
			return;
		}

		if (response && response !== '') {
			//window.open(response, '_blank');
			window.location.href = response;
		}

		setIsWorking(false);
	};

	return (
		<div className="app-dashboard-container panel">
			<ScrollableContainer className="dashboard-content-container">
				<ScrollableContainerHeader>
					<MainAppContentHeader title="Dashboard" />
					<div className="dashboard-welcome-note row mx-0">
						<span className="px-0">
							Hi <strong>{user.firstName}</strong>
						</span>
					</div>
				</ScrollableContainerHeader>
				<ScrollableContainerContent>
					<div className="dashboard-group-title">Activity</div>
					<div className="dashboard-group">
						<div className="dashboard-group-item">
							<div className="dashboard-group-item-title">N/A</div>
							<div className="dashboard-group-item-subtitle">Unread Messages</div>
						</div>
						<div className="dashboard-group-item">
							<div className="dashboard-group-item-title">N/A</div>
							<div className="dashboard-group-item-subtitle">Messages Sent</div>
						</div>
						<div className="dashboard-group-item">
							<div className="dashboard-group-item-title">{user.credits}</div>
							<div className="dashboard-group-item-subtitle">Credits Remaining</div>
							<div className="dashboard-group-item-note">
								<AppButton
									className="link"
									onClick={createCustomerPortalSession}
									disabled={isWorking}
									showSpinner={isWorking}
									spinnerText="(Get More Credits)"
								>
									(Get More Credits)
								</AppButton>
							</div>
						</div>
					</div>
					<div className="dashboard-group-title">Actions</div>
					<div className="dashboard-group">
						<Link to="/campaigns" className="dashboard-group-item-link">
							<div className="dashboard-group-item">
								<div className="dashboard-group-item-icon">
									<img src={ManageBroadcastsIcon} />
								</div>
								<div className="dashboard-group-item-subtitle">Send Group Broadcast</div>
							</div>
						</Link>
						<Link to="/tools/keywords" className="dashboard-group-item-link">
							<div className="dashboard-group-item">
								<div className="dashboard-group-item-icon">
									<img src={ManageKeywordsIcon} />
								</div>
								<div className="dashboard-group-item-subtitle">Manage Keywords</div>
							</div>
						</Link>
						<Link to="/tools/webforms" className="dashboard-group-item-link">
							<div className="dashboard-group-item">
								<div className="dashboard-group-item-icon">
									<img src={ManageWebformsIcon} />
								</div>
								<div className="dashboard-group-item-subtitle">Manage Webforms</div>
							</div>
						</Link>
					</div>
					<div className="dashboard-group-title">Contacts</div>
					<div className="dashboard-group">
						<Link to="/contacts" className="dashboard-group-item-link">
							<div className="dashboard-group-item">
								<div className="dashboard-group-item-icon">
									<img src={CreateContactsIcon} />
								</div>
								<div className="dashboard-group-item-subtitle">Create Contact</div>
							</div>
						</Link>
						<Link to="/contacts/lists" className="dashboard-group-item-link">
							<div className="dashboard-group-item">
								<div className="dashboard-group-item-icon">
									<img src={ManageListsIcon} />
								</div>
								<div className="dashboard-group-item-subtitle">Manage Lists</div>
							</div>
						</Link>
					</div>
					<Footer />
				</ScrollableContainerContent>
			</ScrollableContainer>
		</div>
	);
};

export default Dashboard;
