import BroadcastsContentArea from './broadcastsContentArea/BroadcastsContentArea';
import { Route, Routes } from 'react-router';
import './Broadcasts.css';

const Broadcasts = () => {
	return (
		<div className="app-broadcasts-container panel">
			<Routes>
				<Route path="/:id" element={<BroadcastsContentArea />} />
				<Route path="/add" element={<BroadcastsContentArea />} />
				<Route path="/*" element={<BroadcastsContentArea />} />
			</Routes>
		</div>
	);
};

export default Broadcasts;
