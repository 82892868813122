import { Link } from 'react-router-dom';
import './BroadcastsSidebarHeader.css';
import SearchListInput from '../../../searchListInput/SearchListInput';

const BroadcastsSidebarHeader = ({ items, callback }) => {
	return (
		<div className="broadcasts-sidebar-header-container">
			<div className="broadcasts-sidebar-header-search">
				<SearchListInput callback={callback} />
			</div>
			<div className="broadcasts-sidebar-header-add-button">
				<Link to="/campaigns/add" className="btn btn-primary btn-lg">
					<span>New</span>
				</Link>
			</div>
		</div>
	);
};

export default BroadcastsSidebarHeader;
