import './KeywordsListItem.css';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const KeywordsListItem = ({ item }) => {
	const location = useLocation();
	const [ keywordId, setKeywordId ] = useState('');

	useEffect(
		() => {
			setKeywordId(window.location.pathname.replace('/tools/keywords', '').replaceAll('/', ''));
		},
		[ location ]
	);

	return (
		<div className={`keyword-item-container ${keywordId && keywordId === item.id ? 'active' : ''}`} id={item.id}>
			<Link to={`/tools/keywords/${item.id}`}>
				<div className="keyword-item-wrapper">
					<div className="keyword-name">
						<span>{item.name}</span>
					</div>
					{/* <div className="keyword-status">
						<span>{item.status}</span>
					</div> */}
				</div>
			</Link>
		</div>
	);
};

export default KeywordsListItem;
