export const initialState = {
	user                 : null,
	phoneNumber          : null,
	selectedThread       : null,
	selectedMedia        : null,
	isNewThread          : false,
	newMessageSent       : false,
	reloadInbox          : false,
	selectedCampaign     : null,
	isNewCampaign        : false,
	reloadBroadcasts     : false,
	reloadAutoresponders : false,
	reloadKeywords       : false,
	reloadWebForms       : false,
	selectedPlan         : null,
	searchQuery          : null,
	searchInboxType      : null,
	reloadCustomContent  : null,
	isContactsSearch     : false,
	selectedBroadcast    : null
};

export const actionTypes = {
	SET_USER              : 'SET_USER',
	SET_PHONE_NUMBER      : 'SET_PHONE_NUMBER',
	SELECTED_THREAD       : 'SELECTED_THREAD',
	SELECTED_MEDIA        : 'SELECTED_MEDIA',
	IS_NEW_THREAD         : 'IS_NEW_THREAD',
	NEW_MESSAGE_SENT      : 'NEW_MESSAGE_SENT',
	RELOAD_INBOX          : 'RELOAD_INBOX',
	SELECTED_CAMPAIGN     : 'SELECTED_CAMPAIGN',
	IS_NEW_CAMPAIGN       : 'IS_NEW_CAMPAIGN',
	RELOAD_BROADCASTS     : 'RELOAD_BROADCASTS',
	RELOAD_AUTORESPONDERS : 'RELOAD_AUTORESPONDERS',
	RELOAD_KEYWORDS       : 'RELOAD_KEYWORDS',
	RELOAD_WEBFORMS       : 'RELOAD_WEBFORMS',
	SELECTED_PLAN         : 'SELECTED_PLAN',
	SEARCH_QUERY          : 'SEARCH_QUERY',
	SEARCH_INBOX_TYPE     : 'SEARCH_INBOX_TYPE',
	RELOAD_CUSTOMCONTENT  : 'RELOAD_CUSTOMCONTENT',
	IS_CONTACTS_SEARCH    : 'IS_CONTACTS_SEARCH',
	SELECTED_BROADCAST    : 'SELECTED_BROADCAST'
};

const reducer = (state, action) => {
	console.log(action);
	switch (action.type) {
		case actionTypes.SET_USER:
			return {
				...state,
				user : action.user
			};
		case actionTypes.SET_PHONE_NUMBER:
			return {
				...state,
				phoneNumber : action.phoneNumber
			};
		case actionTypes.SELECTED_THREAD:
			return {
				...state,
				selectedThread : action.selectedThread
			};
		case actionTypes.SELECTED_MEDIA:
			return {
				...state,
				selectedMedia : action.selectedMedia
			};
		case actionTypes.IS_NEW_THREAD:
			return {
				...state,
				isNewThread : action.isNewThread
			};
		case actionTypes.NEW_MESSAGE_SENT:
			return {
				...state,
				newMessageSent : action.newMessageSent
			};
		case actionTypes.RELOAD_INBOX:
			return {
				...state,
				reloadInbox : action.reloadInbox
			};
		case actionTypes.SELECTED_CAMPAIGN:
			return {
				...state,
				selectedCampaign : action.selectedCampaign
			};
		case actionTypes.IS_NEW_CAMPAIGN:
			return {
				...state,
				isNewCampaign : action.isNewCampaign
			};
		case actionTypes.RELOAD_BROADCASTS:
			return {
				...state,
				reloadBroadcasts : action.reloadBroadcasts
			};
		case actionTypes.RELOAD_AUTORESPONDERS:
			return {
				...state,
				reloadAutoresponders : action.reloadAutoresponders
			};
		case actionTypes.RELOAD_WEBFORMS:
			return {
				...state,
				reloadWebForms : action.reloadWebForms
			};
		case actionTypes.SELECTED_PLAN:
			return {
				...state,
				selectedPlan : action.selectedPlan
			};
		case actionTypes.SEARCH_QUERY:
			return {
				...state,
				searchQuery : action.searchQuery
			};
		case actionTypes.SEARCH_INBOX_TYPE:
			return {
				...state,
				searchInboxType : action.searchInboxType
			};
		case actionTypes.RELOAD_KEYWORDS:
			return {
				...state,
				reloadKeywords : action.reloadKeywords
			};
		case actionTypes.RELOAD_CUSTOMCONTENT:
			return {
				...state,
				reloadCustomContent : action.reloadCustomContent
			};
		case actionTypes.IS_CONTACTS_SEARCH:
			return {
				...state,
				isContactsSearch : action.isContactsSearch
			};
		case actionTypes.SELECTED_BROADCAST:
			return {
				...state,
				selectedBroadcast : action.selectedBroadcast
			};
		default:
			return state;
	}
};

export default reducer;
