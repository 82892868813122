import './WebFormPlaceholder.css';

const WebFormPlaceholder = () => {
	return (
		<div className="webform webform-placeholder">
			<div className="content placeholder-glow">
				<span className="placeholder placeholder-lg col-8" />
				<span className="placeholder placeholder-lg col-4" />
			</div>
		</div>
	);
};

export default WebFormPlaceholder;
