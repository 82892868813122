import './LeftNavigationHeader.css';
import IconPhone from '../../../../img/phone_icon.png';
import Logo from '../../../logo/Logo';
import { useStateValue } from '../../../../StateProvider';

const LeftNavigationHeader = ({ collapsed }) => {
	const [ { user, phoneNumber }, dispatch ] = useStateValue();

	return (
		<div className="app-left-nav-header">
			<Logo collapsed={collapsed} />
			<div className="app-left-nav-header-text">Your Main Texting Number</div>
			<div className="app-left-nav-header-number">
				<img src={IconPhone} />
				<span>{phoneNumber ? phoneNumber.friendlyName : ''}</span>
			</div>
			<hr className="app-left-nav-separator" />
		</div>
	);
};

export default LeftNavigationHeader;
