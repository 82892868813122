import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../login/Login';
import MainApp from '../mainApp/MainApp';
import { useStateValue } from '../../StateProvider';
import SubmitThankYouPage from '../submitThankYouPage/SubmitThankYouPage';
import ZapierDocumentation from '../mainApp/account/accountContentArea/accountIntegration/ZapierDocumentation';
import ForgotPassword from '../login/forgotPassword/ForgotPassword';
import ResetPassword from '../login/resetPassword/ResetPassword';

const AppRouter = () => {
	const [ { user }, dispatch ] = useStateValue();

	return (
		<Fragment>
			<Routes>
				<Route path="/thank-you" element={<SubmitThankYouPage />} />
				<Route path="/zapier" element={<ZapierDocumentation />} />
				{user ? user.status === 'Canceled' ? (
					<Route path="/*" element={<Login />} />
				) : (
					<Route path="/*" element={<MainApp />} />
				) : (
					<Fragment>
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset-password/*" element={<ResetPassword />} />
						<Route path="*" element={<Login />} />
					</Fragment>
				)}
			</Routes>
		</Fragment>
	);
};

export default AppRouter;
