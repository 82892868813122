import './SubmitThankYouPage.css';

const SubmitThankYouPage = () => {
	return (
		<div className="app-submit-thank-you-container panel">
			<div className="submit-thank-you-title">
				<span>Thank You!</span>
			</div>
			<div className="submit-thank-you-content">
				<span>We’ll respond to your request shortly!</span>
			</div>
		</div>
	);
};

export default SubmitThankYouPage;
