import { Fragment, useEffect, useState } from 'react';
import './AccountSettings.css';
import {
	GetAccount,
	UpdateAccountCompanyInfo,
	UpdateAccountPersonalInfo,
	UpdateAccountSettings
} from '../../../../../Services';
import { useStateValue } from '../../../../../StateProvider';
import { actionTypes } from '../../../../../reducer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import AppButton from '../../../../appButton/AppButton';

const AccountSettings = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ personalInfoEdit, setPersonalInfoEdit ] = useState(false);
	const [ companyInfoEdit, setCompanyInfoEdit ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();
	const phoneRegex = /^\d+/g;
	const callForwardingNumberRegex = /^\d+/g;
	const [ formUser, setFormUser ] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		getAccount();
	}, []);

	const getAccount = async () => {
		const response = await GetAccount();

		if (response) {
			const tempUser = response;

			dispatch({
				type : actionTypes.SET_USER,
				user : tempUser
			});

			tempUser.password = '';
			setFormUser(tempUser);

			setIsLoading(false);
		} else {
			navigate('/login');
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		let value = e.target.value;

		if (target === 'phoneNumber' || target === 'callForwardingPhoneNumber') {
			value = e.target.value.replace(/[^0-9]+/g, '');
		}

		setFormUser({
			...formUser,
			[target] : value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let isValid = false;

		const isPhoneNumber = phoneRegex.test(formUser.phoneNumber);

		if (formUser.phoneNumber !== '' && isPhoneNumber) {
			isValid = true;
		}

		if (!isValid) {
			toast.error('Please enter a valid phone number to proceed');
			return;
		}

		if (formUser.email.trim() === '') {
			toast.error('Email address is required');
			return;
		}

		if (formUser.firstName.trim() === '') {
			toast.error('First name is required');
			return;
		}

		if (formUser.lastName.trim() === '') {
			toast.error('Last name is required');
			return;
		}

		setIsSaving(true);

		let data = {
			firstName   : formUser.firstName,
			lastName    : formUser.lastName,
			email       : formUser.email,
			password    : formUser.password,
			phoneNumber : formUser.phoneNumber
			//areaCode    : formUser.areaCode
		};

		const response = await UpdateAccountSettings(user.id, data);

		if (response && response.error) {
			toast.error(response.error);
			setIsSaving(false);

			return;
		}

		if (response !== undefined) {
			const userDetailsResponse = await GetAccount();

			if (userDetailsResponse) {
				const tempUser = userDetailsResponse;

				dispatch({
					type : actionTypes.SET_USER,
					user : tempUser
				});
			} else {
				navigate('/login');
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handlePersonalInfoEdit = () => {
		setPersonalInfoEdit(true);
	};

	const handlePersonalInfoEditCancel = () => {
		setPersonalInfoEdit(false);
	};

	const handlePersonalInfoSave = async (e) => {
		e.preventDefault();

		let isValid = false;

		const isPhoneNumber = phoneRegex.test(formUser.phoneNumber);

		if (formUser.phoneNumber !== '' && isPhoneNumber) {
			isValid = true;
		}

		if (!isValid) {
			toast.error('Please enter a valid phone number to proceed');
			return;
		}

		if (formUser.firstName.trim() === '') {
			toast.error('First name is required');
			return;
		}

		if (formUser.lastName.trim() === '') {
			toast.error('Last name is required');
			return;
		}

		if (formUser.email.trim() === '') {
			toast.error('Email address is required');
			return;
		}

		if (formUser.callForwardingPhoneNumber && formUser.callForwardingPhoneNumber.trim() !== '') {
			const isCallForwardingNumberValid = callForwardingNumberRegex.test(formUser.callForwardingPhoneNumber);

			if (!isCallForwardingNumberValid) {
				toast.error('Please enter a valid call forwarding number to proceed');
				return;
			}
		}

		setIsSaving(true);

		let data = {
			firstName                 : formUser.firstName,
			lastName                  : formUser.lastName,
			email                     : formUser.email,
			password                  : formUser.password,
			phoneNumber               : formUser.phoneNumber,
			callForwardingPhoneNumber : formUser.callForwardingPhoneNumber
		};

		const response = await UpdateAccountPersonalInfo(user.id, data);

		if (response && response.error) {
			toast.error(response.error);
			setIsSaving(false);

			return;
		}

		if (response !== undefined) {
			const userDetailsResponse = await GetAccount();

			if (userDetailsResponse) {
				const tempUser = userDetailsResponse;

				dispatch({
					type : actionTypes.SET_USER,
					user : tempUser
				});
			} else {
				navigate('/login');
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
		setPersonalInfoEdit(false);
	};

	const handleCompanyInfoEdit = () => {
		setCompanyInfoEdit(true);
	};

	const handleCompanyInfoEditCancel = () => {
		setCompanyInfoEdit(false);
	};

	const handleCompanyInfoSave = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let data = {
			companyName     : formUser.companyName,
			companyAddress  : formUser.companyAddress,
			companyCity     : formUser.companyCity,
			companyState    : formUser.companyState,
			companyZip      : formUser.companyZip,
			companyTimezone : formUser.companyTimezone
		};

		const response = await UpdateAccountCompanyInfo(user.id, data);

		if (response && response.error) {
			toast.error(response.error);
			setIsSaving(false);

			return;
		}

		if (response !== undefined) {
			const userDetailsResponse = await GetAccount();

			if (userDetailsResponse) {
				const tempUser = userDetailsResponse;

				dispatch({
					type : actionTypes.SET_USER,
					user : tempUser
				});
			} else {
				navigate('/login');
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
		setCompanyInfoEdit(false);
	};

	return (
		<div className="account-settings-container">
			{!isLoading ? (
				<form onSubmit={handleSubmit}>
					<div className="form-section">
						<div className="row form-section-header">
							<div className="col-6 form-section-title">
								<span>Personal Information</span>
							</div>
							<div className="col-6 form-section-action">
								{personalInfoEdit ? (
									<Fragment>
										<AppButton
											onClick={handlePersonalInfoSave}
											children={
												<Fragment>
													<span>Save</span>
												</Fragment>
											}
										/>
										<AppButton
											className="btn-link ms-2"
											onClick={handlePersonalInfoEditCancel}
											children={
												<Fragment>
													<span>Cancel</span>
												</Fragment>
											}
										/>
									</Fragment>
								) : (
									<AppButton
										className="btn-link"
										onClick={handlePersonalInfoEdit}
										children={
											<Fragment>
												<i className="far fa-edit" />
												<span>Edit Info</span>
											</Fragment>
										}
									/>
								)}
							</div>
						</div>
						<div className="row form-section-fields">
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="firstName">First name</label>
									<input
										type="text"
										id="firstName"
										name="firstName"
										className="form-control"
										disabled={isLoading || isSaving || !personalInfoEdit}
										defaultValue={formUser.firstName}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="lastName">Last name</label>
									<input
										type="text"
										id="lastName"
										name="lastName"
										className="form-control"
										disabled={isLoading || isSaving || !personalInfoEdit}
										defaultValue={formUser.lastName}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="row form-section-fields">
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="email">Email</label>
									<input
										type="email"
										id="email"
										name="email"
										className="form-control"
										disabled={isLoading || isSaving || !personalInfoEdit}
										defaultValue={formUser.email}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="password">Password</label>
									<input
										type="password"
										id="password"
										name="password"
										className="form-control"
										disabled={isLoading || isSaving || !personalInfoEdit}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="row form-section-fields">
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="phoneNumber">Phone</label>
									<input
										onChange={handleChange}
										id="phoneNumber"
										name="phoneNumber"
										type="tel"
										disabled={isLoading || isSaving || !personalInfoEdit}
										defaultValue={formUser.phoneNumber}
										className="form-control"
										placeholder="Phone (format: 8005130456)"
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="callForwardingPhoneNumber">Call Forwarding Number</label>
									<input
										onChange={handleChange}
										id="callForwardingPhoneNumber"
										name="callForwardingPhoneNumber"
										type="tel"
										disabled={isLoading || isSaving || !personalInfoEdit}
										defaultValue={formUser.callForwardingPhoneNumber}
										className="form-control"
										placeholder="format: 8005130456"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="form-section">
						<div className="row form-section-header">
							<div className="col-6 form-section-title">
								<span>Company Information</span>
							</div>
							<div className="col-6 form-section-action">
								{companyInfoEdit ? (
									<Fragment>
										<AppButton
											onClick={handleCompanyInfoSave}
											children={
												<Fragment>
													<span>Save</span>
												</Fragment>
											}
										/>
										<AppButton
											className="btn-link ms-2"
											onClick={handleCompanyInfoEditCancel}
											children={
												<Fragment>
													<span>Cancel</span>
												</Fragment>
											}
										/>
									</Fragment>
								) : (
									<AppButton
										className="btn-link"
										onClick={handleCompanyInfoEdit}
										children={
											<Fragment>
												<i className="far fa-edit" />
												<span>Edit Info</span>
											</Fragment>
										}
									/>
								)}
							</div>
						</div>
						<div className="row form-section-fields">
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="companyName">Company name</label>
									<input
										type="text"
										id="companyName"
										name="companyName"
										className="form-control"
										disabled={isLoading || isSaving || !companyInfoEdit}
										defaultValue={formUser.companyName}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="companyAddress">Address</label>
									<input
										type="text"
										id="companyAddress"
										name="companyAddress"
										className="form-control"
										disabled={isLoading || isSaving || !companyInfoEdit}
										defaultValue={formUser.companyAddress}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="row form-section-fields">
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="companyCity">City</label>
									<input
										type="text"
										id="companyCity"
										name="companyCity"
										className="form-control"
										disabled={isLoading || isSaving || !companyInfoEdit}
										defaultValue={formUser.companyCity}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="companyState">State/Province</label>
									<input
										type="text"
										id="companyState"
										name="companyState"
										className="form-control"
										disabled={isLoading || isSaving || !companyInfoEdit}
										defaultValue={formUser.companyState}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="row form-section-fields">
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="companyZip">Zip/Postal</label>
									<input
										type="text"
										id="companyZip"
										name="companyZip"
										className="form-control"
										disabled={isLoading || isSaving || !companyInfoEdit}
										defaultValue={formUser.companyZip}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="col-12 col-md-6 form-section-field">
								<div className="form-group">
									<label htmlFor="companyTimezone">Timezone</label>
									<input
										type="text"
										id="companyTimezone"
										name="companyTimezone"
										className="form-control"
										disabled={isLoading || isSaving || !companyInfoEdit}
										defaultValue={formUser.companyTimezone}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="form-inputs">
						<div className="form-group mb-3">
							<label htmlFor="password">Password</label>
							<input
								type="password"
								id="password"
								name="password"
								className="form-control"
								disabled={isLoading || isSaving}
								defaultValue={formUser.password}
								onChange={handleChange}
							/>
						</div>

						<div className="form-group mb-4">
							<label htmlFor="areaCode">Phone Area Code</label>
							<input
								type="number"
								id="areaCode"
								name="areaCode"
								className="form-control"
								disabled={isLoading || isSaving}
								defaultValue={formUser.areaCode}
								onChange={handleChange}
								min={200}
								max={999}
							/>
						</div>
					</div>
					<div className="container-buttons">
						<button type="submit" className="btn btn-primary" disabled={isLoading || isSaving}>
							{!isSaving ? (
								<Fragment>
									<span>Save</span>
								</Fragment>
							) : (
								<Fragment>
									<span>Saving...</span>
								</Fragment>
							)}
						</button>

						{user &&
						user.Status === 'Active' && (
							<Fragment>
								<button
									type="button"
									className="btn btn-outline-danger"
									disabled={isLoading || isSaving}
									onClick={() => navigate('/account/cancel')}
								>
									<i className="fas fa-power-off" />
									<span>Cancel Subscription</span>
								</button>
							</Fragment>
						)}
					</div> */}
				</form>
			) : (
				<div className="text-center p-3">{/* <Spinner /> */}</div>
			)}
		</div>
	);
};

export default AccountSettings;
