import { Fragment } from 'react';
import './AccountContentArea.css';
import AccountSettings from './accountSettings/AccountSettings';
import Billing from './billing/Billing';
import PlanChange from './billing/planChange/PlanChange';

const AccountContentArea = ({ type }) => {
	return (
		<Fragment>
			{type === 'settings' ? (
				<AccountSettings />
			) : type === 'billing' ? (
				<Billing />
			) : type === 'change-plan' ? (
				<PlanChange />
			) : (
				''
			)}
		</Fragment>
	);
};

export default AccountContentArea;
