import './CustomFields.css';
import { useState, Fragment } from 'react';
import Moment from 'react-moment';
import CustomFieldFormDialog from '../../contactsFormDialog/customFields/CustomFieldFormDialog';
import { DeleteCustomField } from '../../../../../../Services';
import { toast } from 'react-toastify';
import AppButton from '../../../../../appButton/AppButton';

const CustomFields = ({ fields, callback }) => {
	const [ showCustomFieldDetailsDialog, setShowCustomFieldDetailsDialog ] = useState(false);
	const [ customFieldId, setCustomFieldId ] = useState(0);

	const toggleCustomFieldDetailsDialog = () => {
		setShowCustomFieldDetailsDialog((prev) => !prev);
	};

	const addCustomField = (e) => {
		setShowCustomFieldDetailsDialog((prev) => !prev);

		setCustomFieldId(0);
	};

	const editCustomField = (e) => {
		setShowCustomFieldDetailsDialog((prev) => !prev);

		var selectedCustomFieldId = e.currentTarget.getAttribute('data-custom-field-list-id');
		if (!selectedCustomFieldId) {
			selectedCustomFieldId = 0;
		}

		setCustomFieldId(selectedCustomFieldId);
	};

	const deleteCustomField = async (e) => {
		if (window.confirm('Are you sure you want to delete the custom field?')) {
			var selectedCustomFieldId = e.currentTarget.getAttribute('data-custom-field-list-id');

			const response = await DeleteCustomField(selectedCustomFieldId);

			if (response !== undefined) {
				if (callback) {
					callback();
				}
			}
		}
	};

	const handleListItemClick = (e) => {
		var selectedCustomFieldId = e.currentTarget.getAttribute('data-custom-field-list-id');
		setCustomFieldId(selectedCustomFieldId);
	};

	return (
		<div className="custom-fields-list-container">
			{/* <div className="text-end mb-3">
				<AppButton className="primary" children={<span>Add Custom Field</span>} onClick={addCustomField} />
			</div> */}
			<div className="table-responsive">
				<table className="table table-borderless">
					<thead className="table-secondary">
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Type</th>
							<th scope="col">Merge Field Name</th>
							<th scope="col">Created</th>
							<th scope="col" />
						</tr>
					</thead>
					<tbody>
						<Fragment>
							{fields &&
								fields.length > 0 &&
								fields.map((item) => (
									<tr
										key={item.id}
										className={`contact-item ${customFieldId && customFieldId === item.id
											? 'active'
											: ''}`}
									>
										<td>{item.name}</td>
										<td>{item.type}</td>
										<td>{item.mergeField}</td>
										<td>
											<Moment format="MM/DD/YYYY">{item.dateCreated}</Moment>
										</td>
										<td className="settings-action">
											{!item.isDefault && (
												<div className="btn-group dropstart">
													<button
														type="button"
														data-bs-toggle="dropdown"
														aria-expanded="false"
														className="btn btn-link p-0"
														data-custom-field-list-id={item.id}
														onClick={handleListItemClick}
													>
														<i className="fa-solid fa-gear mx-1" />
													</button>
													<ul className="dropdown-menu">
														<li>
															<button
																className="btn dropdown-item"
																onClick={editCustomField}
																data-custom-field-list-id={item.id}
															>
																<span>
																	<i className="fa-solid fa-pencil" />Edit
																</span>
															</button>
														</li>
														<li>
															<button
																className="btn dropdown-item"
																onClick={deleteCustomField}
																data-custom-field-list-id={item.id}
															>
																<span>
																	<i className="fa-solid fa-trash" />Delete
																</span>
															</button>
														</li>
													</ul>
												</div>
											)}
										</td>
									</tr>
								))}
						</Fragment>
					</tbody>
				</table>
			</div>

			{showCustomFieldDetailsDialog && (
				<CustomFieldFormDialog
					customFieldId={customFieldId}
					onClose={toggleCustomFieldDetailsDialog}
					callback={callback}
				/>
			)}
		</div>
	);
};

export default CustomFields;
