import AppButton from '../../appButton/AppButton';
import './InboxSidebarHeaderPlaceholder.css';

const InboxSidebarHeaderPlaceholder = () => {
	return (
		<div className="inbox-sidebar-header inbox-sidebar-header-placeholder">
			<div className="inbox-sidebar-header-text">
				<div className="placeholder-glow">
					<span className="placeholder placeholder-lg col-6" />
				</div>
				<div className="placeholder-glow">
					<span className="placeholder placeholder-sm col-6" />
				</div>
			</div>
			<div className="inbox-sidebar-header-button placeholder-glow col-3">
				<button
					type="button"
					disabled
					className="btn btn-primary placeholder col-12"
				/>
			</div>
		</div>
	);
};

export default InboxSidebarHeaderPlaceholder;
