import './BroadcastsSidebarHeaderPlaceholder.css';

const BroadcastsSidebarHeaderPlaceholder = () => {
	return (
		<div className="broadcasts-sidebar-header broadcasts-sidebar-header-placeholder">
			<div className="broadcasts-sidebar-header-text">
				<div className="placeholder-glow">
					<span className="placeholder placeholder-lg col-6" />
				</div>
				<div className="placeholder-glow">
					<span className="placeholder placeholder-sm col-6" />
				</div>
			</div>
			<div className="broadcasts-sidebar-header-button placeholder-glow col-3">
				<button type="button" disabled className="btn btn-primary placeholder col-12" />
			</div>
		</div>
	);
};

export default BroadcastsSidebarHeaderPlaceholder;
