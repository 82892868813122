import './WebFormsListItem.css';
import { Link, useLocation } from 'react-router-dom';
import WebFormCodeDialog from '../../webFormCodeDialog/WebFormCodeDialog';
import AppButton from '../../../../appButton/AppButton';
import { Fragment, useState, useEffect } from 'react';

const WebFormsListItem = ({ item }) => {
	const location = useLocation();
	const [ showWebFormCodeDialog, setShowWebFormCodeDialog ] = useState(false);
	const [ webFormId, setWebFormId ] = useState('');

	useEffect(
		() => {
			setWebFormId(window.location.pathname.replace('/tools/webforms', '').replaceAll('/', ''));
		},
		[ location ]
	);

	const toggleWebFormCodeDialog = () => {
		setShowWebFormCodeDialog((prev) => !prev);
	};

	return (
		<div className={`webform-item-container ${webFormId && webFormId === item.id ? 'active' : ''}`} id={item.id}>
			<Link to={`/tools/webforms/${item.id}`}>
				<div className="webform-item-wrapper">
					<div className="webform-name">
						<span>{item.name}</span>
					</div>
					<div className="webform-code">
						<AppButton
							className="link p-0"
							children={<span>Get code</span>}
							onClick={toggleWebFormCodeDialog}
						/>
					</div>
				</div>
			</Link>
			{showWebFormCodeDialog && <WebFormCodeDialog webForm={item} onClose={toggleWebFormCodeDialog} />}
		</div>
	);
};

export default WebFormsListItem;
