import { Fragment, useState, useEffect, useCallback } from 'react';
import Card from '../../../../../dragAndDrop/Card';
import { ImportGetMappingFields } from '../../../../../../../Services';
import AppButton from '../../../../../../appButton/AppButton';
import './WebFormFieldItem.css';

const WebFormFieldItem = ({ field, index, onChange, onMoveItem, onDeleteItem }) => {
	const [ mappingFields, setMappingFields ] = useState([]);
	const [ matchingField, setMatchingField ] = useState('');

	useEffect(() => {
		getMappingFields();
	}, []);

	const getMappingFields = async (query) => {
		let data = { query: query };
		let response = await ImportGetMappingFields(data);

		if (response !== undefined) {
			// //remove PhoneNumber as it's added by default
			// response = response.filter((x) => x.name !== 'PhoneNumber');

			setMappingFields(response);
		}
	};

	const handleChange = (e) => {
		let value = e.target.value;
		let text =
			e.target.type === 'select-one' ? (e.target.selectedOptions ? e.target.selectedOptions[0].text : '') : '';

		if (field) {
			if (e.target.type === 'checkbox') {
				field.required = e.target.checked;
			} else if (e.target.type === 'select-one') {
				field.name = value;
				field.label = text;
				field.type = e.target.selectedOptions
					? e.target.selectedOptions[0].attributes['data-type'].value
					: 'text';
				field.placeholder = 'Enter ' + text;
				field.cfId = e.target.selectedOptions
					? e.target.selectedOptions[0].attributes['data-cfid'].value
					: null;
			}
		}

		if (onChange) {
			onChange(e);
		}
	};

	const getMappingFieldCFId = (field) => {
		if (mappingFields) {
			let mappedItem = mappingFields.find((x) => x.name === field.name);

			if (mappedItem && mappedItem.cfId) {
				//update cfId in field
				field.cfId = mappedItem.cfId;

				return mappedItem.cfId;
			}
		}

		return '0';
	};

	const handleMoveItem = (dragIndex, hoverIndex) => {
		if (onMoveItem) {
			onMoveItem(dragIndex, hoverIndex);
		}
	};

	const handleDeleteItem = (index) => {
		if (onDeleteItem) {
			onDeleteItem(index);
		}
	};

	return (
		<Fragment>
			{(field.deleted === undefined || !field.deleted) && (
				<div className={`web-form-item-wrapper${field.name === 'PhoneNumber' ? ' fixed' : ''}`}>
					<Card
						index={index}
						id={field.id ? field.id : ''}
						text={
							<Fragment>
								<div className="dragger">
									<i className="fa-solid fa-grip-vertical" />
								</div>
								<div className="input-field">
									<select
										className="form-select mapping-field"
										onChange={handleChange}
										data-index={field.index}
										data-cfid={field.name === 'PhoneNumber' ? '' : getMappingFieldCFId(field)}
										value={field.name}
									>
										{mappingFields &&
										mappingFields.length > 0 && (
											<Fragment>
												<option value="">- Select -</option>
												{mappingFields.map((mappingField) => (
													<option
														value={mappingField.name}
														key={mappingField.index}
														data-type={mappingField.type}
														data-cfid={mappingField.cfId}
													>
														{mappingField.label}
													</option>
												))}
											</Fragment>
										)}
									</select>
								</div>
								<div className="input-required">
									<div className="form-check">
										<input
											className="form-check-input"
											type="checkbox"
											onChange={handleChange}
											defaultChecked={field.required}
											name={`webFormFieldRequired${field.index}`}
											id={`webFormFieldRequired${field.index}`}
										/>
										<label
											className="form-check-label"
											htmlFor={`webFormFieldRequired${field.index}`}
										>
											Required
										</label>
									</div>
								</div>
								{field.name !== 'PhoneNumber' && (
									<div className="delete-webform-field">
										<AppButton
											className="link p-0"
											children={<i className="fas fa-trash-alt" />}
											onClick={() => handleDeleteItem(field.index)}
										/>
									</div>
								)}
							</Fragment>
						}
						moveCard={handleMoveItem}
					/>
				</div>
			)}
		</Fragment>
	);
};

export default WebFormFieldItem;
