import './Tools.css';
import ScrollableContainer from '../../scrollableContainer/ScrollableContainer';
import ScrollableContainerHeader from '../../scrollableContainer/scrollableContainerHeader/ScrollableContainerHeader';
import ScrollableContainerContent from '../../scrollableContainer/scrollableContainerContent/ScrollableContainerContent';
import { useStateValue } from '../../../StateProvider';
import ManageBroadcastsIcon from '../../../img/add_message_icon.png';
import ManageKeywordsIcon from '../../../img/mobile_icon.png';
import ManageWebformsIcon from '../../../img/form_icon.png';
import TemplatesIcon from '../../../img/chat_icon.png';
import ManageListsIcon from '../../../img/contact_book_icon.png';
import MainAppContentHeader from '../mainAppContentArea/mainAppContentHeader/MainAppContentHeader';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';

const Tools = () => {
	const [ { user }, dispatch ] = useStateValue();

	return (
		<div className="app-tools-container panel">
			<ScrollableContainer className="tools-content-container">
				<ScrollableContainerHeader>
					<MainAppContentHeader title="Tools" />
				</ScrollableContainerHeader>
				<ScrollableContainerContent>
					<div className="tools-group row">
						<Link to="/tools/webforms" className="tools-group-item-link col-6 col-lg-4">
							<div className="tools-group-item">
								<div className="tools-group-item-icon">
									<img src={ManageWebformsIcon} />
								</div>
								<div className="tools-group-item-title">Webforms</div>
								<div className="tools-group-item-note">Collect new subscribers through webforms</div>
							</div>
						</Link>
						<Link to="/tools/keywords" className="tools-group-item-link col-6 col-lg-4">
							<div className="tools-group-item">
								<div className="tools-group-item-icon">
									<img src={ManageKeywordsIcon} />
								</div>
								<div className="tools-group-item-title">Keywords</div>
								<div className="tools-group-item-note">
									Automatically build your subscribers through custom Keywords
								</div>
							</div>
						</Link>
						{/* <Link to="/tools" className="tools-group-item-link col-6 col-lg-4">
							<div className="tools-group-item">
								<div className="tools-group-item-icon">
									<img src={TemplatesIcon} />
								</div>
								<div className="tools-group-item-title">Templates</div>
								<div className="tools-group-item-note">Manage your SMS templates for quick use</div>
							</div>
						</Link> */}
						<Link to="/tools/autoresponders" className="tools-group-item-link col-6 col-lg-4">
							<div className="tools-group-item">
								<div className="tools-group-item-icon">
									<img src={ManageBroadcastsIcon} />
								</div>
								<div className="tools-group-item-title">Drip Campaigns</div>
								<div className="tools-group-item-note">
									Create & manage automatic text replys when subscribers join your list
								</div>
							</div>
						</Link>
						<Link to="/tools/customfields" className="tools-group-item-link col-6 col-lg-4">
							<div className="tools-group-item">
								<div className="tools-group-item-icon">
									{/* <img src={ManageBroadcastsIcon} /> */}
									<i className="fa-solid fa-pen-to-square" />
								</div>
								<div className="tools-group-item-title">Custom Fields</div>
								<div className="tools-group-item-note">Create & manage custom fields</div>
							</div>
						</Link>
						<Link to="/tools/integration" className="tools-group-item-link col-6 col-lg-4">
							<div className="tools-group-item">
								<div className="tools-group-item-icon">
									{/* <img src={ManageBroadcastsIcon} /> */}
									<i className="fa-solid fa-bolt" />
								</div>
								<div className="tools-group-item-title">API Integration</div>
								<div className="tools-group-item-note">
									Integrate MindfulSMS with No-code automation across 5,000+ apps
								</div>
							</div>
						</Link>
					</div>
					<Footer />
				</ScrollableContainerContent>
			</ScrollableContainer>
		</div>
	);
};

export default Tools;
