import AutorespondersSidebar from './autorespondersSidebar/AutorespondersSidebar';
import AutorespondersContentArea from './autorespondersContentArea/AutorespondersContentArea';
import { Route, Routes } from 'react-router';
import './Autoresponders.css';

const Autoresponders = () => {
	return (
		<div className="app-autoresponders-container panel">
			<Routes>
				<Route path="/:id" element={<AutorespondersContentArea />} />
				<Route path="/add" element={<AutorespondersContentArea />} />
				<Route path="/*" element={<AutorespondersContentArea />} />
			</Routes>
		</div>
	);
};

export default Autoresponders;
