import './ContactsListHeaderPlaceholder.css';

const ContactsListHeaderPlaceholder = () => {
	return (
		<div className="contacts-content-header-container">
			<div className="form-header-title">
				<span className="placeholder placeholder-lg col-2" />
			</div>
			<div className="contacts-content-header-add-button">
				<button type="button" disabled className="btn btn-primary placeholder col-2" />
			</div>
			<div className="contacts-content-header-search">
				<span className="placeholder placeholder-lg col-12" />
			</div>
		</div>
	);
};

export default ContactsListHeaderPlaceholder;
